import React, { Component } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { boardData } from "../../services/GeneralServices";
import {
  boardGradeSubject,
  getCurriculum,
  addChapterWithToipc,
  updateChapter,
  getCount,
} from "../../services/CurriculumServices";
import AddChapter from "./AddChapter";
import EditChapter from "./EditChapter";
import { chunk, uniqBy, filter, groupBy } from "lodash";
import { toast } from "react-toastify";

class CurriculumList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      boardId: null,
      subjectId: "",
      boardList: [],
      curriculumList: [],

      boardName: "",
      gradeName: "",
      subjectName: "",
      showModalAddChapter: false,
      showModalEditChapter: false,
      errorMessage: "",
      initialValues: {
        chapterTitle: "",
        description: "",
        status: "Active",
        boardId: "",
        topics: [],
      },
      buttonEnable: true,
    };
  }
  componentDidMount() {
    this.getBoard();
  }

  getBoard = () => {
    boardData()
      .then((response) => {
        if (response !== undefined) {
          // console.log("Board Data", response);
          this.setState({
            boardList: response,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  boardGradeSubject = () => {
    boardGradeSubject(this.state.boardId)
      .then((gradeSubjectList) => {
        if (gradeSubjectList !== undefined) {
          let subjectList = [];
          let gradeList = [];
          gradeSubjectList.map((gradeSubject) => {
            gradeList[gradeSubject.grade.id] = {
              label: gradeSubject.grade.grade,
              value: gradeSubject.grade.id,
            };

            if (subjectList["g" + gradeSubject.grade.id] !== undefined)
              subjectList["g" + gradeSubject.grade.id].push({
                label: gradeSubject.subject.subject,
                value: gradeSubject.subject.id,
                boardGradeSubjectId: gradeSubject.id,
              });
            else
              subjectList["g" + gradeSubject.grade.id] = [
                {
                  label: gradeSubject.subject.subject,
                  value: gradeSubject.subject.id,
                  boardGradeSubjectId: gradeSubject.id,
                },
              ];
          });

          this.setState({
            gradeList: gradeList,
            subjectList: subjectList,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message, isloading: false });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };

  handelChangeBoard = (e) => {
    let boardId = e.target.value;
    const boardList = [...this.state.boardList];
    const foundObject = boardList.find(({ id }) => id == boardId);
    // console.log("foundObject", foundObject[0].boardCode);
    this.setState(
      {
        boardId,
        subjectId: "",
        buttonEnable: true,
        initialValues: { ...this.state.initialValues, boardId },
        boardName:
          foundObject && foundObject.boardCode
            ? foundObject.boardCode + " Board"
            : "",
      },
      () => this.boardGradeSubject(boardId)
    );
  };

  handleChangeGrade = (event) => {
    let subjectList = this.state.subjectList["g" + event.target.value];
    this.setState({
      selectedGrade: event.target.value,
      selectedGradeSubjectList: subjectList,
      subjectId: "",
      buttonEnable: true,
    });
    const gradeList = [...this.state.gradeList];
    // console.log("gradeList", gradeList);
    const gradeId = event.target.value;
    // console.log("gradeId", gradeId);
    const foundObject = gradeList.find((x) => (x && x.value) == gradeId);
    console.log("foundObject", foundObject);
    if (foundObject !== undefined) {
      this.setState({
        gradeName: foundObject.label,
      });
    }
  };

  handleChangeSubject = (e) => {
    const subjectId = e.target.value;
    const subjectList = [...this.state.selectedGradeSubjectList];
    const foundObject = subjectList.filter((x) => x.value == subjectId);
    // console.log("foundObject", foundObject[0].label);
    this.setState(
      {
        subjectId: e.target.value,
        subjectName: foundObject[0].label,
        initialValues: {
          chapterTitle: "",
          description: "",
          status: "Active",
          boardId: this.state.boardId,
          gradeId: this.state.selectedGrade,
          subjectId: subjectId,
          topics: [],
        },
        initialValuesUpdated: {
          boardId: this.state.boardId,
          gradeId: this.state.selectedGrade,
          subjectId: subjectId,
        },
        buttonEnable: false,
      },
      () => this.getCurriculum()
    );
  };

  getCurriculum = () => {
    // console.log("getCurriculum");
    this.setState({ isloading: true });
    const { boardId, selectedGrade, subjectId } = this.state;
    getCurriculum(boardId, selectedGrade, subjectId)
      .then((response) => {
        if (response !== undefined) {
          // console.log("Get Curriculum", response);
          {
            response.map((item, itemIndex) => {
              item.boardGradeSubjectChapterTopics.map((topicId, topicIndex) => {
                console.log("topicId", topicId.topic.id);
                response[itemIndex].boardGradeSubjectChapterTopics[
                  topicIndex
                ].topicData = [];
                getCount(topicId.topic.id)
                  .then((res) => {
                    if (res !== undefined) {
                      // console.log("topicId", res);
                      response[itemIndex].boardGradeSubjectChapterTopics[
                        topicIndex
                      ].topicData = res;
                      response[itemIndex].topicCount = res.length;
                    }
                  })
                  .catch((error) => {
                    console.log("error", error);
                  });
              });
            });
          }
          setTimeout(() => {
            this.setState({
              curriculumList: response,
              isloading: false,
            });
          }, 3000);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  handleModalShow = () => {
    this.setState({
      showModalAddChapter: true,
    });
  };

  // this function is used to show buffer data
  bufferFromBufferString = (data) => {
    let utf8decoder = new TextDecoder();
    let u16arr = new Uint8Array(data);
    let dataPrepare = utf8decoder.decode(u16arr);
    return dataPrepare;
  };
  handleEditModalShow = (list) => {
    this.setState({
      showModalEditChapter: true,
      initialValuesUpdated: list,
      initialValuesUpdated: {
        id: list.id,
        chapterTitle: list.chapterTitle,
        status: list.status,
        topics: list.boardGradeSubjectChapterTopics,
        boardId: this.state.boardId,
        subjectId: this.state.subjectId,
        gradeId: this.state.selectedGrade,
        description: this.bufferFromBufferString(list.description.data),
      },
    });
  };

  handleModalHide = () => {
    this.setState({
      showModalAddChapter: false,
    });
  };
  handleEditModalHide = () => {
    this.setState({
      showModalEditChapter: false,
    });
  };

  addChapter = (data) => {
    console.log("Add Chapter", data);
    // return;
    this.setState({ isloading: true });
    addChapterWithToipc(data)
      .then((response) => {
        if (response !== undefined) {
          console.log("Add Chapter response", response);
          this.setState(
            {
              isloading: false,
              showModalAddChapter: false,
            },
            () => this.getCurriculum()
          );
          toast.success(response.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            isloading: false,
          });
        } else {
          this.setState({
            errorMessage: error.message,
            isloading: false,
          });
        }
      });
  };

  updateChapter = (data, id, actions) => {
    console.log("Update Data", data);
    console.log("Update ID", id);
    // return;
    this.setState({ isloading: true });
    updateChapter(id, data)
      .then((response) => {
        if (response !== undefined) {
          console.log("response", response);
          this.setState({
            showModalEditChapter: false,
          });
          this.getCurriculum();
          toast.success("Chapter updated successfully", {
            autoClose: 3000,
            hideProgressBar: true,
          });
          actions.resetForm();
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            isloading: false,
          });
        } else {
          this.setState({
            errorMessage: error.message,
            isloading: false,
          });
        }
      });
  };

  mergeData = (array) => {
    var result = [];
    array.reduce(function (res, value) {
      // console.log("topic", value);
      if (!res[value.id]) {
        res[value.id] = {
          id: value.id,
          chapterTitle: value.chapterTitle,
          topics: [],
        };
        result.push(res[value.id]);
      }
      res[value.id].topics.push(value.boardGradeSubjectChapterTopics);
      return res;
    }, {});
    return result;
  };

  render() {
    const {
      boardList,
      gradeList,
      selectedGradeSubjectList,
      subjectId,
      curriculumList,
      boardName,
      subjectName,
      gradeName,
      errorMessage,
      initialValues,
      initialValuesUpdated,
      buttonEnable,
      showModalAddChapter,
      showModalEditChapter,
    } = this.state;

    const prepareData = this.mergeData(curriculumList);
    console.log("curriculumList", curriculumList);
    // console.log("prepareData", prepareData);
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row className="mb-4">
                        <Col md="4">
                          <div className="title">Chapter</div>
                        </Col>
                        <Col md="8">
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="addTopic">
                              <button
                                className="btn btn-primary-fill float-right mt-3"
                                onClick={this.handleModalShow}
                                disabled={buttonEnable}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                                &nbsp; Add Chapter
                              </button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <div className="filterDataQuestion">
                            <Row>
                              <Col md="3">
                                <div className="form-group form-gp">
                                  <select
                                    name="boardId"
                                    className="form-control"
                                    onChange={(e) => this.handelChangeBoard(e)}
                                  >
                                    <option value="">Select Board</option>
                                    {boardList.map((list) => {
                                      return (
                                        <option key={list.id} value={list.id}>
                                          {list.boardCode}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="form-group form-gp">
                                  <select
                                    name="gradeId"
                                    className="form-control"
                                    onChange={(e) => this.handleChangeGrade(e)}
                                  >
                                    <option value="">Select Grade</option>
                                    {gradeList !== undefined &&
                                      gradeList.map((option) => (
                                        <option value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="form-group form-gp">
                                  <select
                                    name="subjectId"
                                    className="form-control"
                                    value={subjectId}
                                    onChange={(e) =>
                                      this.handleChangeSubject(e)
                                    }
                                  >
                                    <option value="">Select Subject</option>
                                    {selectedGradeSubjectList !== undefined &&
                                      selectedGradeSubjectList.map((option) => (
                                        <option value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      <Row>
                        <Col md="12">
                          {this.state.isloading ? (
                            <div className="custom-loader">
                              <Spinner animation="border" />
                            </div>
                          ) : (
                              <>
                                {curriculumList.length > 0 ? (
                                  <div className="react-bootstrap-table">
                                    <div className="table table-bordered">
                                      <div className="div_head">
                                        <div className="chapter_thead">
                                          Chapter
                                      </div>
                                        <div className="topic_level_thead">
                                          Topic - Level
                                      </div>
                                        <div className="evaluations_thead">
                                          Evaluations
                                      </div>
                                        <div className="question_thead">
                                          Questions
                                      </div>
                                      </div>
                                      <div className="div_body">
                                        <div className="chapter_row">
                                          {curriculumList.map((list) => {
                                            return (
                                              <>
                                                <div
                                                  className="repeat_chapter_row row-hover pointer_class"
                                                  onClick={(e) =>
                                                    this.handleEditModalShow(list)
                                                  }
                                                >
                                                  <div className="chapter_title">
                                                    {list.chapterTitle}
                                                  </div>
                                                  {list.boardGradeSubjectChapterTopics.map(
                                                    (topic) => {
                                                      return (
                                                        <>
                                                          <div className="topic_row_data">
                                                            <div className="topic_data">
                                                              <div className="topic_title_level">
                                                                {
                                                                  topic.topic
                                                                    .topicTitle
                                                                }{" "}
                                                              -{" "}
                                                                {
                                                                  topic.topic
                                                                    .level
                                                                }
                                                              </div>
                                                              <div className="eve_data">
                                                                {topic.topicData
                                                                  .length > 0 ? (
                                                                    <>
                                                                      {topic.topicData.map(
                                                                        (eve) => {
                                                                          return (
                                                                            <div>
                                                                              <p>
                                                                                {
                                                                                  eve
                                                                                    .evaluationType
                                                                                    .evaluationType
                                                                                }
                                                                              </p>
                                                                            </div>
                                                                          );
                                                                        }
                                                                      )}
                                                                    </>
                                                                  ) : (
                                                                    <p>N/A</p>
                                                                  )}
                                                              </div>
                                                              <div className="eve_count">
                                                                {topic.topicData
                                                                  .length > 0 ? (
                                                                    <>
                                                                      {topic.topicData.map(
                                                                        (eve) => {
                                                                          return (
                                                                            <div>
                                                                              <p>
                                                                                {
                                                                                  eve.questionCount
                                                                                }
                                                                              </p>
                                                                            </div>
                                                                          );
                                                                        }
                                                                      )}
                                                                    </>
                                                                  ) : (
                                                                    <p>N/A</p>
                                                                  )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {showModalAddChapter ? (
                <AddChapter
                  showModalAddChapter={showModalAddChapter}
                  handleModalHide={this.handleModalHide}
                  onSubmit={this.addChapter}
                  errorMessage={errorMessage}
                  initialValues={initialValues}
                  boardName={boardName}
                  gradeName={gradeName}
                  subjectName={subjectName}
                />
              ) : null}

              {showModalEditChapter ? (
                <EditChapter
                  showModalEditChapter={showModalEditChapter}
                  handleModalHide={this.handleEditModalHide}
                  onSubmit={this.updateChapter}
                  errorMessage={errorMessage}
                  initialValues={initialValuesUpdated}
                  boardName={boardName}
                  gradeName={gradeName}
                  subjectName={subjectName}
                />
              ) : null}
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CurriculumList;
