import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import SunEditor, { buttonList } from "suneditor-react";
import image from "suneditor/src/plugins/dialog/link";
import list from "suneditor/src/plugins/submenu/list";
import { font, video } from "suneditor/src/plugins";

import plugins from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";

toast.configure();
export default class AddTopicLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      editorContent: "",
    };
  }
  closeModal = (resetForm) => {
    resetForm();
    this.props.handleModalHide();
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };
  handleEditorChange = (content) => {
    console.log(content);
    this.setState({
      editorContent: content,
    });
  };

  render() {
    console.log("this.props.initialValues", this.props.initialValues);
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={this.props.initialValues}
          onSubmit={(values, actions) => {
            console.log("Form Values", values);
            // values.topicTitle = values.topicTitle;
            // values.level = values.level === undefined ? 1 : "";
            // values.status = values.status === undefined ? "Active" : "";
            values.description = this.state.editorContent;
            // values.topicPreReqs = [];
            console.log("values add Topic Level", values);
            // return;
            this.props.onSubmit(values, actions);
          }}
          validationSchema={Yup.object().shape({
            topicDisplayTitle: Yup.string().required(
              "Please Enter Topic Display Title"
            ),
            videoLink: Yup.string().required("Please Enter Video Link"),
          })}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal isOpen={this.props.showModalAddTopic} size="lg">
                <ModalHeader>
                  <h4>Add Topic Level</h4>
                  <div className="modal_popup_close_icon">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => this.closeModal(resetForm)}
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  {this.props.errorMessage !== "" ? (
                    <p className="alert alert-danger">
                      {this.props.errorMessage}
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label>Topic Title</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          name="topicTitle"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.topicTitle}
                          className="form-control"
                          placeholder="Topic Title"
                          disabled
                        />
                        <label className="err-msg">
                          {errors.topicTitle &&
                            touched.topicTitle &&
                            errors.topicTitle}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label>Level</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          name="level"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.level}
                          className="form-control"
                          disabled
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label>Topic Display Title</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          name="topicDisplayTitle"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.topicDisplayTitle}
                          className="form-control"
                          placeholder="Topic Display Title"
                        />
                        <label className="err-msg">
                          {errors.topicDisplayTitle &&
                            touched.topicDisplayTitle &&
                            errors.topicDisplayTitle}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label>Video Link</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          name="videoLink"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.videoLink}
                          className="form-control"
                          placeholder="Video Link"
                        />
                        <label className="err-msg">
                          {errors.videoLink &&
                            touched.videoLink &&
                            errors.videoLink}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label>Description</label>
                      </Col>
                      <Col md="9">
                        <SunEditor
                          name="my-editor"
                          placeholder="Please type here..."
                          setOptions={{
                            plugins: [video, image, list],
                            height: 250,
                            buttonList: [
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript",
                              ],
                              ["image", "video", "list", "table"],
                              ["paragraphStyle", "blockquote"],
                              ["fontColor", "hiliteColor", "textStyle"],
                              "/", // Line break
                              ["outdent", "indent"],
                              ["align", "horizontalRule", "list", "lineHeight"],
                              ["font", "fontSize", "formatBlock"],
                              ["undo", "redo"],
                            ],
                          }}
                          onChange={this.handleEditorChange}
                        />

                        <textarea
                          name="description"
                          disabled
                          hidden
                          value={this.state.editorContent}
                        />
                        <label className="err-msg">
                          {errors.description &&
                            touched.description &&
                            errors.description}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label>Status</label>
                      </Col>
                      <Col md="9">
                        <select
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        >
                          <option value="Active" label="Active" />
                          <option value="Draft" label="Draft" />
                          <option value="Review" label="Review" />
                        </select>
                        <label className="err-msg">
                          {errors.status && touched.status && errors.status}
                        </label>
                      </Col>
                    </Row>
                    <input
                      type="hidden"
                      name="topicPreReqs[]"
                      className="form-control"
                    />
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => this.closeModal(resetForm)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="btn btn-primary-fill"
                    color="success"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </Modal>
            </form>
          )}
        </Formik>
      </>
    );
  }
}
