import Api from '../Api'

export const topicList=() => {
    return  Api.get('meta/topic/getalltopic').then(res=>res.data).catch(err=>console.log('error in topic list  data ',err))
}

export const topicListNoDesc=() => {
    return  Api.get('meta/topic/getalltopicnodesc').then(res=>res.data).catch(err=>console.log('error in topic list  data ',err))
}

export const addTopic=(topicData) => {
    return  Api.post('/meta/topic/addtopic',topicData).then(res=>res.data)
}

export const updateTopic = (topicId,topicData) => {
    return Api.put('/meta/topic/updatetopic/'+topicId, topicData)
 }

 export const searchTopic=(searchstring,Page=1,size=1000) => {
    return  Api.get('meta/topic/searchtopic/'+searchstring+'?page='+Page+'&size='+size).then(res=>res.data)
 }

 export const getTopicPreReq=(topicId) => {
    return  Api.get('meta/topic/gettopicprereq/' + topicId).then(res=>res.data).catch(err=>console.log('error in get topic prereq data ',err))
}

export const deleteTopicPreReq=(topicPreReqId) => {
    return  Api.delete('meta/topic/deletetopicprereq/' + topicPreReqId).then(res=>res.data).catch(err=>console.log('error in delete topic pre request data ',err))
}

export const getAllTopicWithoutLevel=(Page=1,size=1000) => {
    return  Api.get('/meta/topic/getalltopicwithoutlevel?page='+Page+'&size='+size).then(res=>res.data)
}

export const getAllLevelforTopic=(name) => {
    return  Api.get('meta/topic/getalllevelfortopic/' + name).then(res=>res.data).catch(err=>console.log('error in topic list  data ',err))
}

export const addTopicPreReq=(data) => {
    return  Api.post('/meta/topic/addtopicprereq',data).then(res=>res.data)
}

export const getAllTopicLevel=(topicName) => {
    return  Api.get('meta/topic/getalllevelfortopic/' + topicName).then(res=>res.data).catch(err=>console.log('error in get topic prereq data ',err))
}
