import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCheckSquare,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
export default class AssignmentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      isDisable: false,
    };
  }
  closeModal = () => {
    this.setState({ selectedIndex: 0, isDisable: false });
    this.props.hideAssessmentModal();
  };
  nextPage = () => {
    let nextPage = this.state.selectedIndex + 1;
    if (nextPage === this.props.questionList.length) {
      nextPage = this.state.selectedIndex;
      this.setState({ isDisable: true });
    } else {
      this.setState({ isDisable: false });
    }
    this.setState({ selectedIndex: nextPage });
  };
  previousPage = () => {
    let nextPage = this.state.selectedIndex - 1;
    this.setState({ selectedIndex: nextPage, isDisable: false });
  };
  // this function is used to show buffer data
  bufferFromBufferString = (data) => {
    let utf8decoder = new TextDecoder();
    let u16arr = new Uint8Array(data);
    let dataPrepare = utf8decoder.decode(u16arr);
    return dataPrepare;
  };

  //this function is for creating markup from discreption
  createMarkup = (data) => {
    return { __html: data };
  };
  render() {
    return (
      <>
        <Modal isOpen={this.props.showModalAssessment} size="lg">
          <ModalHeader>
            <span>{this.props.tittleModal}</span>
            <div className="modal_popup_close_icon">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => this.closeModal()}
              />
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
              {this.props.questionList[this.state.selectedIndex] !==
                undefined && (
                <dl>
                  <dt>Question:{this.state.selectedIndex + 1}</dt>
                  <dd>
                    <span
                      className="fixed-width-span"
                      dangerouslySetInnerHTML={this.createMarkup(
                        this.bufferFromBufferString(
                          this.props.questionList[this.state.selectedIndex][
                            "schoolSectionQuestion"
                          ].questionText.data
                        )
                      )}
                    ></span>
                  </dd>
                </dl>
              )}
            </div>
            <dl>
              <dt>Answer Options:</dt>
              {this.props.questionList[this.state.selectedIndex] !==
                undefined &&
                this.props.questionList[this.state.selectedIndex][
                  "schoolSectionQuestion"
                ]["schoolSectionAnswers"] !== undefined &&
                this.props.questionList[this.state.selectedIndex][
                  "schoolSectionQuestion"
                ]["schoolSectionAnswers"].map((ansOptions) => {
                  return (
                    <dd key={ansOptions.id}>
                      {ansOptions.isCorrectOption ? (
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          className="activeIcon"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSquare}
                          className="uncheck-square"
                        />
                      )}

                      <span className="ml-1">
                        <span
                          className="fixed-width-span"
                          dangerouslySetInnerHTML={this.createMarkup(
                            this.bufferFromBufferString(
                              ansOptions.optionText.data
                            )
                          )}
                        ></span>
                      </span>
                      <br></br>
                    </dd>
                  );
                })}
            </dl>
            <dl>
              <dt>Answer Explanation:</dt>
              {this.props.questionList[this.state.selectedIndex] !==
                undefined && (
                <dd>
                  <span
                    className="fixed-width-span"
                    dangerouslySetInnerHTML={this.createMarkup(
                      this.bufferFromBufferString(
                        this.props.questionList[this.state.selectedIndex][
                          "schoolSectionQuestion"
                        ].answerExplanation.data
                      )
                    )}
                  ></span>
                </dd>
              )}
            </dl>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn btn-primary-fill"
              type="button"
              onClick={this.previousPage}
              disabled={this.state.selectedIndex <= 0 ? true : null}
            >
              previous
            </Button>

            <Button
              type="submit"
              className="btn btn-primary-fill"
              onClick={this.nextPage}
              disabled={this.state.isDisable}
            >
              Next
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
