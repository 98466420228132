import Api from "../Api";

export const questionList = (Page, size) => {
  return Api.get("/meta/question/getall?page=" + Page + "&size=" + size)
    .then((res) => res.data)
    .catch((err) => console.log("error in Question list  data ", err));
};

export const QuestionType = () => {
  return Api.get("/meta/questiontype")
    .then((res) => res.data)
    .catch((err) => console.log("error in question type data ", err));
};

export const searchQuestion = (searchstring, Page , size) => {
  return Api.get(
    "meta/question/searchquestion/" +
      searchstring +
      "?page=" +
      Page +
      "&size=" +
      size
  ).then((res) => res.data);
};

export const addQuestion = (questionData) => {
  return Api.post("/meta/question/add", questionData).then((res) => res.data);
};

export const updateQuestions = (questionId, questionData) => {
  return Api.put("/meta/question/update/" + questionId, questionData);
};

export const addToTopic = (data) => {
  return Api.post("/meta/question/addtotopic", data).then((res) => res.data);
};

export const getByTopic = (id, Page = 1, size = 1000) => {
  return Api.get(
    "meta/question/getbytopic/" + id + "?page=" + Page + "&size=" + size
  ).then((res) => res.data);
};

export const addEvaluation = (data) => {
  return Api.post("/meta/evaluation/add", data).then((res) => res.data);
};
export const resetEvaluation = (data) => {
  return Api.post("/meta/evaluation/reset", data).then((res) => res.data);
};
