import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";

import SunEditor, { buttonList } from "suneditor-react";
import image from "suneditor/src/plugins/dialog/link";
import list from "suneditor/src/plugins/submenu/list";
import { font, video } from "suneditor/src/plugins";

import plugins from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";
import { topicList, searchTopic } from "../../services/TopicServices";
import trimObj from "../../components/common/TrimData";

toast.configure();
export default class AddChapter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topicList: [],
    };
  }

  componentDidMount() {
    this.getTopicList();
  }
  closeModal = (resetForm) => {
    resetForm();
    this.props.handleModalHide();
  };

  handleEditorChange = (content, setFieldValue) => {
    console.log(content);
    this.setState(
      {
        description: content,
      },
      () => {
        if (content === "<p><br></p>" || content === "<p></p>") {
          setFieldValue("description", "");
        } else {
          setFieldValue("description", content);
        }
      }
    );
  };

  getTopicList = () => {
    topicList()
      .then((responseTopicList) => {
        if (responseTopicList !== undefined) {
          console.log("responseTopicList", responseTopicList);
          this.setState({
            topicList: responseTopicList.rows,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ errorMessage: error.message, isloading: false });
      });
  };

  handleSearch = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      this.getTopicList();
    } else {
      const getValue = e.target.value;
      searchTopic(getValue)
        .then((response) => {
          if (response !== undefined) {
            console.log("Search response", response);
            this.setState({
              topicList: response.rows,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  handleOnChangeTopic = (e, setFieldValue, list, i) => {
    // setFieldValue(`topics.${i}.topicId`, list.id);
  };

  render() {
    const { boardName, gradeName, subjectName, initialValues } = this.props;
    const { topicList } = this.state;
    // console.log("initialValues", initialValues);
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            values.description = this.state.description;
            console.log(values);
            const { topics = [] } = values || {};
            const validTopics = topics.filter((item) => item && item.topicId);
            console.log("validTopics", validTopics);
            let newValues = { ...values, topics: validTopics };
            // this function used to trim string before and after
            newValues = trimObj(newValues);
            this.props.onSubmit(newValues, actions);
          }}
          validationSchema={Yup.object().shape({
            chapterTitle: Yup.string().required("Please Enter Topic Title"),
            description: Yup.string().required(
              "Please Enter Topic Display Title"
            ),
          })}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal isOpen={this.props.showModalAddChapter} size="lg">
                <ModalHeader>
                  <h4>Create a new Chapter</h4>
                  <div className="modal_popup_close_icon">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => this.closeModal(resetForm)}
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  {this.props.errorMessage !== "" ? (
                    <p className="alert alert-danger">
                      {this.props.errorMessage}
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label>Board</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          value={boardName}
                          className="form-control"
                          readOnly
                          disabled
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label>Grade</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          value={gradeName}
                          className="form-control"
                          readOnly
                          disabled
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label>Subject</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          value={subjectName}
                          className="form-control"
                          readOnly
                          disabled
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <input
                      type="hidden"
                      name="boardId"
                      value={values.boardId}
                      className="form-control"
                    />
                    <input
                      type="hidden"
                      name="gradeId"
                      value={values.gradeId}
                      className="form-control"
                    />
                    <input
                      type="hidden"
                      name="subjectId"
                      value={values.subjectId}
                      className="form-control"
                    />
                    <Row>
                      <Col md="3">
                        <label>Chapter Title</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          name="chapterTitle"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.chapterTitle}
                          className="form-control"
                          placeholder="Topic Title"
                        />
                        <label className="err-msg">
                          {errors.chapterTitle &&
                            touched.chapterTitle &&
                            errors.chapterTitle}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label>Description</label>
                      </Col>
                      <Col md="9">
                        <SunEditor
                          defaultValue={this.state.description}
                          name="description"
                          placeholder="Please type here..."
                          setOptions={{
                            plugins: [video, image, list],
                            height: 150,
                            buttonList: [
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript",
                              ],
                              ["image", "video", "list", "table"],
                              ["paragraphStyle", "blockquote"],
                              ["fontColor", "hiliteColor", "textStyle"],
                              "/", // Line break
                              ["outdent", "indent"],
                              ["align", "horizontalRule", "list", "lineHeight"],
                              ["font", "fontSize", "formatBlock"],
                              ["undo", "redo"],
                            ],
                          }}
                          onChange={(data) =>
                            this.handleEditorChange(data, setFieldValue)
                          }
                        />
                        <label className="err-msg">
                          {errors.description &&
                            touched.description &&
                            errors.description}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label>Status</label>
                      </Col>
                      <Col md="9">
                        <select
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        >
                          <option value="Active" label="Active" />
                          <option value="Draft" label="Draft" />
                          <option value="Review" label="Review" />
                        </select>
                        <label className="err-msg">
                          {errors.status && touched.status && errors.status}
                        </label>
                      </Col>
                    </Row>
                  </div>

                  <div className="clearfix topic_listing_assign_modal">
                    <div className="search_topic">
                      <div className="input-group">
                        <input
                          type="text"
                          name="topicSearch"
                          className="form-control"
                          placeholder="Search for topic here"
                          onChange={this.handleSearch}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary-fill btn btn-success">
                            <FontAwesomeIcon icon={faSearch} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="list_question">
                      <div className="react-bootstrap-table">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Topic Title</th>
                              <th>Level</th>
                              <th>Topic Display Title</th>
                              <th
                                style={{
                                  width: "70px",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          {/* <FieldArray name="topics"> */}
                          <tbody>
                            {topicList.map((list, i) => {
                              // console.log("First Ite", i);
                              return (
                                <tr key={list.id}>
                                  <td>{list.topicTitle}</td>
                                  <td>{list.level}</td>
                                  <td>{list.topicDisplayTitle}</td>
                                  <td>
                                    <div className="form-group form-gp">
                                      <div className="form-check">
                                        <input
                                          type="checkbox"
                                          name={`topics.${[i]}.topicId`}
                                          // onChange={handleChange}
                                          onChange={(e) => {
                                            this.handleOnChangeTopic(
                                              e,
                                              setFieldValue,
                                              list,
                                              i
                                            );
                                            setFieldValue(
                                              `topics.${i}.topicId`,
                                              list.id
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          className="form-check-input position-static checkbox-2x c9"
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          {/* </FieldArray> */}
                        </table>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => this.closeModal(resetForm)}
                  >
                    Close
                  </Button>
                  <Button
                    className="btn btn-primary-fill"
                    type="submit"
                    color="success"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </Modal>
            </form>
          )}
        </Formik>
      </>
    );
  }
}
