import Api from '../Api'
//Dashboard Screen
export const getDashboardList = (id) => {
   return Api.get('schoolsection/getteacherdashboard/' + id).then(res => res.data)
}
//Announcement Screen
export const getAnnouncementList = (id) => {
   return Api.get('announcement/getannouncementbyschoolsection/' + id + '?page=1&size=1000').then(res => res.data)
}
//Announcement Screen
export const addAnnouncement = (userData) => {
   return Api.post('announcement/addannouncement', userData).then(res => res.data)
}
//Message Screen
export const getMessagesForUser = (teacherId, sectionId) => {
   return Api.get('directmessage/getdirectmessagelistbyreceiver/' + teacherId + '/' + sectionId + '?page=1&size=1000').then(res => res.data)
}
//Message Screen
export const readMessagesByUser = (messageId) => {
   return Api.put('directmessage/readdirectmessagebyreceiver/' + messageId).then(res => res.data)
}
//Message Screen
export const sendMessageToStudent = (userData) => {
   return Api.post('directmessage/adddirectmessage', userData).then(res => res.data)
}
//Teacher Message ,Teacher Rewards Screen
export const getUsersBySection = (id) => {
   return Api.get('user/getbyschoolsection/' + id + '?page=1&size=1000').then(res => res.data)
}
//Curriuculum  Screen
export const getCurriuculumBySection = (id) => {
   return Api.get('schoolsection/getcurriculum/' + id + '?page=1&size=1000').then(res => res.data)
}
//Curriuculum  Screen
export const getChapterDetailsById = (id) => {
   return Api.get('schoolsection/getchapterdetails/' + id).then(res => res.data)
}
//Curriuculum  Screen
export const getTopicDetailsById = (id) => {
   return Api.get('schoolsection/gettopicdetails/' + id).then(res => res.data)
}
//Curriuculum  Screen
export const getEvaluationQuestionById = (id) => {
   return Api.get('ssevaluation/getevaluationquestions/' + id).then(res => res.data)
}
//Curriuculum  Screen
export const addDateAssessment = (evaluationData) => {
   return Api.post('ssevaluation/adddates', evaluationData).then(res => res.data)
}
//Rewards Screen
export const getRewardsList = (schoolsSectionId) => {
   return Api.get('grades/getschoolsectionrewards/' + schoolsSectionId).then(res => res.data)
}
//Grade Screen
export const getGradesList = (schoolsSectionId) => {
   return Api.get('grades/getschoolsectiongrades/' + schoolsSectionId).then(res => res.data)
}
//Grade Screen chart
export const getGradesSummary = (schoolsSectionId) => {
   return Api.get('grades/getschoolsectiongradessummary/' + schoolsSectionId).then(res => res.data)
}
//Grade Screen pdf
export const getGradesByTopic = (schoolsSectionId) => {
   return Api.get('grades/getschoolsectiongradesbytopic/' + schoolsSectionId).then(res => res.data)
}
//Rewards Details screen 
export const getLineChart = (schoolsSectionId, studentId) => {
   return Api.get('grades/getstudentgrades/' + schoolsSectionId + '/' + studentId).then(res => res.data)
}
//Rewards Details screen 
export const getBarChart = (schoolsSectionId, studentId, topicId) => {
   return Api.get('grades/getstudentgradesdetails/' + schoolsSectionId + '/' + studentId + '/' + topicId).then(res => res.data)
}
//Rewards Details screen 
export const getTopicList = (schoolsSectionId) => {
   return Api.get('schoolsection/getassignedtopiclist/' + schoolsSectionId).then(res => res.data)
}
//Rewards Screen pdf
export const getGradesTopicDetails = (schoolsSectionId, studentId) => {
   return Api.get('/grades/getstudentgradesbytopic/' + schoolsSectionId + '/' + studentId).then(res => res.data)
}