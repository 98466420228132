import React from 'react'
import Header from './Header'
import MainContainer from './MainContainer'
import PrivateRoutes from "../../routes/PrivateRoutes";

class Main extends React.Component{

	constructor(props){
	super(props);

		this.state ={
			toggleMenu : false
		}
		
	}
	toggleButton = () =>{
		//alert('hello');
		this.setState(prevState => ({toggleMenu : !prevState.toggleMenu}))
	}

	render(){
		return(

			<React.Fragment>
				<Header handletoggle={this.toggleButton}  {...this.props} />
		     	<section> 
					<PrivateRoutes handleClass={this.state.toggleMenu} />
					<MainContainer handleMainClass={this.state.toggleMenu} {...this.props}/>
				</section>
			</React.Fragment>

			)
	}
} 

export default Main