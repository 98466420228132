import Api from "../Api";

export const boardGradeSubject = (id) => {
  return Api.get("meta/boardgradesubject/getboardgradesubject/getbyboard/" + id)
    .then((res) => res.data)
    .catch((err) => console.log("error in board Grade Subject", err));
};

export const getCurriculum = (boardid, gradeid, subjectid) => {
  return Api.get(
    `/meta/chapter/getcurriculum/${boardid}/${gradeid}/${subjectid}`
  )
    .then((res) => res.data)
    .catch((err) => console.log("error in get curriculum", err));
};

export const getCount = (topicId) => {
  return Api.get(`/meta/topic/gettopicevaluations/${topicId}`)
    .then((res) => res.data)
    .catch((err) => console.log("error in get curriculum", err));
};

export const addChapterWithToipc = (data) => {
  return Api.post("/meta/chapter/addchapterwithtoipc", data)
    .then((res) => res.data)
    .catch((err) => console.log("error in add chapter with toipc", err));
};

export const updateChapter = (chapterId, chapterData) => {
  return Api.put(
    "meta/chapter/updatechapterandresettopics/" + chapterId,
    chapterData
  );
};
