import React, { Component } from "react";
import { toast } from "react-toastify";
import { Container, Row, Col, Spinner } from "reactstrap";
import {
  startEvaluation,
  getEvaluationQuestions,
  recordAnswer,
  completeEvaluation,
  continueEvaluation,
} from "../../services/StudentServices";
import QuizModalForm from "./QuizModalForm";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default class QuizStepWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionList: [],
      showQuestionList: false,
      questionIndex: 0,
      showQuestion: false,
      lastQuestion: false,
      calculation: 0,
      schoolSectionEvaluationId: "",
      schoolSectionTopicId: "",
      evaluationTypeId: "",
      evaluation: {},
      showQuizModal: false,
      isloading: false,
      schoolSectionStudentEvaluationId: "",
      initialValues: {},
      attemptNo: 1,
    };
  }
  componentDidMount() {
    let evaluation = {};
    let StartEvaluationValue = {};
    if (this.props.location.evaluation !== undefined) {
      localStorage.setItem(
        "quizEvaluation",
        JSON.stringify(this.props.location.evaluation)
      );
      evaluation = this.props.location.evaluation;
      if (
        this.props.location.evaluation.hasOwnProperty(
          "schoolSectionStudentEvaluations"
        )
      ) {
        StartEvaluationValue =
          this.props.location.evaluation.schoolSectionStudentEvaluations.find(
            (evalutionType) => evalutionType.evaluationTypeId == 3
          );
      }
      //this condition check if evaluation is started or not
      if (StartEvaluationValue !== undefined && StartEvaluationValue !== {}) {
        this.setState(
          {
            schoolSectionStudentEvaluationId: StartEvaluationValue.id,
            schoolSectionTopicId: StartEvaluationValue.schoolSectionTopicId,
            evaluationTypeId: StartEvaluationValue.evaluationTypeId,
            evaluation: evaluation,
          },
          () => {
            this.continueEvaluation();
          }
        );
      } else {
        this.setState(
          {
            schoolSectionEvaluationId: this.props.location.evaluation.id,
            schoolSectionTopicId:
              this.props.location.evaluation.schoolSectionTopicId,
            evaluationTypeId: this.props.location.evaluation.evaluationTypeId,
            evaluation: this.props.location.evaluation,
          },
          () => {
            this.startEvaluation();
          }
        );
      }
    } else {
      let quizEvaluation = localStorage.getItem("quizEvaluation");
      if (quizEvaluation) quizEvaluation = JSON.parse(quizEvaluation);
      if (quizEvaluation !== undefined) {
        if (quizEvaluation.hasOwnProperty("schoolSectionStudentEvaluations")) {
          StartEvaluationValue =
            quizEvaluation.schoolSectionStudentEvaluations.find(
              (evalutionType) => evalutionType.evaluationTypeId == 3
            );
        }
        //this condition check if evaluation is started or not
        if (StartEvaluationValue !== undefined && StartEvaluationValue !== {}) {
          this.setState(
            {
              schoolSectionStudentEvaluationId: StartEvaluationValue.id,
              schoolSectionTopicId: StartEvaluationValue.schoolSectionTopicId,
              evaluationTypeId: StartEvaluationValue.evaluationTypeId,
              evaluation: quizEvaluation,
            },
            () => {
              this.continueEvaluation();
            }
          );
        } else {
          this.setState(
            {
              schoolSectionEvaluationId: quizEvaluation.id,
              schoolSectionTopicId: quizEvaluation.schoolSectionTopicId,
              evaluationTypeId: quizEvaluation.evaluationTypeId,
              evaluation: quizEvaluation,
            },
            () => {
              this.startEvaluation();
            }
          );
        }
      }
    }
  }
//this function call on start evaluation
  startEvaluation = () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    const studentId = userData.id;
    this.setState({ isloading: true });
    let prepareData = {
      studentId: studentId,
      schoolSectionEvaluationId: this.state.schoolSectionEvaluationId,
      schoolSectionTopicId: this.state.schoolSectionTopicId,
      evaluationTypeId: this.state.evaluationTypeId,
      status: "STARTED",
    };
    startEvaluation(prepareData)
      .then((res) => {
        if (res !== undefined) {
          this.setState({
            schoolSectionStudentEvaluationId:
              res.schoolSectionStudentEvaluation.id,
            isloading: false,
          });
          this.getEvaluationQuestions(res.schoolSectionStudentEvaluation.id);
        } else {
          this.setState({ isloading: false });

          toast.error(res, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log("res ssEvaluation error", error);
      });
  };
  // this function is used to show buffer data
  bufferFromBufferString = (data) => {
    let utf8decoder = new TextDecoder();
    let u16arr = new Uint8Array(data);
    let dataPrepare = utf8decoder.decode(u16arr);
    return dataPrepare;
  };

  nextquestion = (data, actions) => {
    recordAnswer(data)
      .then((res) => {
        if (res !== undefined) {
        } else {
        }
      }).catch((error) => {
        console.log("res record Answer error", error);
      });

    // return;

    const getCount = this.state.questionList.length - 2;

    if (getCount === this.state.questionIndex) {
      this.setState({
        lastQuestion: true,
      });
    }
    const nextQuestion = this.state.questionIndex + 1;
    const calculate =
      Math.floor(100 / this.state.questionList.length) * nextQuestion;

    if (nextQuestion < this.state.questionList.length) {
      let questionList = [...this.state.questionList];
      let questionIndex = this.state.questionIndex + 1;

      let schoolSectionQuestionObject = questionList[questionIndex];
      let initialValues = {
        schoolSectionStudentEvaluationId:
          this.state.schoolSectionStudentEvaluationId,
        evaluationTypeId: this.state.evaluationTypeId,
        schoolSectionStudentEvaluationQuestionId:
          schoolSectionQuestionObject.id,
        questionTypeId: schoolSectionQuestionObject.schoolSectionQuestionTypeId,

        attemptNo: this.state.attemptNo,
        submittedAnswer: [
          {
            answerId: "",
            answerText: "",
          },
        ],
      };
       // for getting submitted answer from question list
      let submittedAnswerList =
        schoolSectionQuestionObject.schoolSectionStudentEvaluationAnswers.filter(
          (question) => question.submittedAnswer === true
        );
      let submittedAnswer = [];

      if (submittedAnswerList.length > 0) {
        submittedAnswerList.map((answer) => {
          submittedAnswer.push({
            answerId: answer.id,
            answerText: this.bufferFromBufferString(
              answer.submittedAnswerText.data
            ),
          });
        });
        initialValues["submittedAnswer"] = submittedAnswer;
      }
      this.setState({
        questionIndex: this.state.questionIndex + 1,
        calculate: calculate,
        initialValues: initialValues,
      });
     
    } else {
     

      let userData = JSON.parse(localStorage.getItem("user"));
      const studentId = userData.id;
      const prepareData = {
        studentId: studentId,
        schoolSectionStudentEvaluationId: data.schoolSectionStudentEvaluationId,
        schoolSectionTopicId: this.state.schoolSectionTopicId,
        evaluationTypeId: this.state.evaluationTypeId,
        status: "COMPLETED",
      };

      // Call the API /ssevaluation/completeevaluation
      completeEvaluation(prepareData)
        .then((res) => {
          if (res !== undefined) {
            toast.success(
              "Quiz is complete and the grades can be viewed in the grade center.",
              {
                autoClose: 3000,
                hideProgressBar: true,
              }
            );

            this.props.history.push("/app/subjects/std-topic-details");
          } else {
            console.log("res complete Evaluation else", res);
          }
        })
        .catch((error) => {
          console.log("res complete Evaluation error", error);
        });
    }
  };
// this function call onClick previous
  onPrevquestion = () => {
    const prevQuestion = this.state.questionIndex - 1;
    const calculate =
      Math.floor(100 / this.state.questionList.length) * prevQuestion;
    if (this.state.questionIndex > 0) {
      this.setState({
        questionIndex: this.state.questionIndex - 1,
        lastQuestion: false,
        calculate: calculate,
      });
      this.getEvaluationQuestions(this.state.schoolSectionStudentEvaluationId);
    } else {
      console.log("Disabled Prev button");
    }
  };
// this function provides all question list
  getEvaluationQuestions = (schoolSectionEvaluationId) => {
    this.setState({ isloading: true });
    getEvaluationQuestions(schoolSectionEvaluationId)
      .then((res) => {
        if (res !== undefined) {
          let questionIndex = this.state.questionIndex;
          let schoolSectionQuestionObject = res[questionIndex];
          let initialValues = {
            schoolSectionStudentEvaluationId:
              this.state.schoolSectionStudentEvaluationId,
            evaluationTypeId: this.state.evaluationTypeId,
            schoolSectionStudentEvaluationQuestionId:
              schoolSectionQuestionObject.id,
            questionTypeId:
              schoolSectionQuestionObject.schoolSectionQuestionTypeId,

            attemptNo: this.state.attemptNo,
            submittedAnswer: [
              {
                answerId: "",
                answerText: "",
              },
            ],
          };
           // for getting submitted answer from question list
          let submittedAnswerList =
            schoolSectionQuestionObject.schoolSectionStudentEvaluationAnswers.filter(
              (question) => question.submittedAnswer === true
            );

          let submittedAnswer = [];

          if (submittedAnswerList.length > 0) {
            submittedAnswerList.map((answer) => {
              submittedAnswer.push({
                answerId: answer.id,
                answerText: this.bufferFromBufferString(
                  answer.submittedAnswerText.data
                ),
              });
            });
            initialValues["submittedAnswer"] = submittedAnswer;
          }

          this.setState({
            questionList: res,
            showQuizModal: true,
            showQuestionList: true,
            isloading: false,
            initialValues: initialValues,
          });
        }
      })
      .catch((error) => {
        console.log("res Question error", error);
      });
  };
  // this function is used to close quiz modal
  hideQuizModal = () => {
    this.setState({ showQuizModal: false });
    this.props.history.push("/app/subjects/std-topic-details");
  };
  //this function is used to continue evaluation if student leave the evaluation then
  continueEvaluation = () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    const studentId = userData.id;
    this.setState({ isloading: true });
    let prepareData = {
      studentId: studentId,
      schoolSectionTopicId: this.state.schoolSectionTopicId,
      evaluationTypeId: this.state.evaluationTypeId,
      schoolSectionStudentEvaluationId:
        this.state.schoolSectionStudentEvaluationId,
    };

    continueEvaluation(prepareData)
      .then((response) => {
        if (response !== undefined) {
          let questionIndex = 0;
          if (response.totalQuestionCount === response.questionsAnswered) {
            questionIndex = response.questionsAnswered - 1;
          } else {
            questionIndex = response.nextQuestionIndex;
          }
          const calculate =
            Math.floor(100 / response.totalQuestionCount) * questionIndex;
          const getCount = questionIndex + 1;
          let lastQuestion = false;
          if (getCount === response.totalQuestionCount) {
            lastQuestion = true;
          }
          this.setState({
            isloading: false,
            totalQuestionCount: response.totalQuestionCount,
            questionsAnswered: response.questionsAnswered,
            questionIndex: questionIndex,
            calculate: calculate,
            lastQuestion: lastQuestion,
          });

          this.getEvaluationQuestions(
            this.state.schoolSectionStudentEvaluationId
          );
        } else {
          console.log("res ssEvaluation erroe", response);
          toast.error(response, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log("res ssEvaluation error", error);
      });
  };
  render() {
    const {
      questionList,
      questionIndex,
      showQuestion,
      lastQuestion,
      calculate,
      showQuestionList,
    } = this.state;

    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col lg="12" md="12">
              {this.state.isloading ? (
                <div className="custom-loader">
                  <Spinner animation="border" />
                </div>
              ) : (
                <div className="card-box1">
                  {showQuestionList ? (
                    <>
                      <div>
                        <div>
                          <>
                            <QuizModalForm
                              question={this.bufferFromBufferString(
                                questionList[questionIndex]
                                  .schoolSectionQuestionText.data
                              )}
                              schoolSectionQuestionTypeId={
                                questionList[questionIndex]
                                  .schoolSectionQuestionTypeId
                              }
                              schoolSectionQuestionObject={
                                questionList[questionIndex]
                              }
                              schoolSectionStudentEvaluationAnswers={
                                questionList[questionIndex]
                                  .schoolSectionStudentEvaluationAnswers
                              }
                              evaluation={this.state.evaluation}
                              questionIndex={questionIndex}
                              onNextquestion={this.nextquestion}
                              onPrevquestion={this.onPrevquestion}
                              lastQuestion={lastQuestion}
                              calculate={calculate}
                              showQuizModal={this.state.showQuizModal}
                              tittleModal="Quiz"
                              hideQuizModal={this.hideQuizModal}
                              schoolSectionStudentEvaluationId={
                                this.state.schoolSectionStudentEvaluationId
                              }
                              initialValues={this.state.initialValues}
                            />
                          </>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Col md="12">
                      <span className="no-data">
                        {" "}
                        No Questions are available here.
                      </span>
                    </Col>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
