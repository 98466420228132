import Api from '../Api'

 
 export const uploadfile=(data)=>{
     return Api.post('upload',data).then(res=>console.log(res.data)).catch(err=>console.log(err))
 }

 export const boardData=() => {
    return  Api.get('meta/board').then(res=>res.data).catch(err=>console.log('error in board data ',err))
 }
 export const subjectData=() => {
    return  Api.get('meta/subject').then(res=>res.data).catch(err=>console.log('error in subject data ',err))
 }
 export const questionType=() => {
    return  Api.get('meta/questiontype').then(res=>res.data).catch(err=>console.log('error in questiontype data ',err))
 }
 

 export const getAllRoles=() => {
   return  Api.get('/meta/role').then(res=>res.data).catch(err=>console.log('error in get all roles data ',err))
}