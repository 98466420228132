import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Spinner,
  Container,
} from "reactstrap";
import { getStudentGradeSummary } from "../../services/StudentServices";
import { Link } from "react-router-dom";
export default class GradeCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      isLoading: false,
      gradesList: [],
    };
  }
  componentDidMount() {
    this.getStudentSummary();
  }
  getStudentSummary = () => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getStudentGradeSummary(userData.id)
      .then((responseGradeList) => {
        if (responseGradeList !== undefined) {
          this.setState({ isloading: false, gradesList: responseGradeList });
        }
      })
      .catch((error) => {
        console.log("error in grade center", error);
      });
  };
  render() {
    return (
      <React.Fragment>
        <Container>
          <div className="card-header">
            <Row>
              <Col md="12">
                <div className="title">
                  <h4>Over all Grades</h4>
                </div>
              </Col>
            </Row>
          </div>
          <CardBody>
            <div>
              {this.state.isloading ? (
                <div className="custom-loader">
                  <Spinner animation="border" />
                </div>
              ) : (
                <Row>
                  {this.state.gradesList !== undefined &&
                    this.state.gradesList.length > 0 &&
                    this.state.gradesList.map((grade, index) => {
                      return (
                        <Col md="6" key={index}>
                          <Link
                            className="view-title"
                            to={{
                              pathname: `/app/grade/std-grade-details/${grade.schoolSectionId}/${grade.studentId}`,
                              studentDetails: {
                                schoolSectionId: grade.schoolSectionId,
                                studentId: grade.studentId,
                              },
                            }}
                          >
                            <Card
                              style={{ height: "10rem" }}
                              className="pointer_class clickable-card"
                            >
                              <CardBody>
                                <CardTitle className="text-center">
                                  <h4 className="card-title">
                                    {grade.schoolSection.sectionName +
                                      " " +
                                      grade.schoolSection.boardGradeSubject
                                        .grade.grade +
                                      " " +
                                      grade.schoolSection.boardGradeSubject
                                        .subject.subject}
                                  </h4>
                                  <h5>
                                    {"Average Score " + Math.round(grade.AverageScore*100) + "%"}
                                  </h5>
                                </CardTitle>
                                <CardText className="text-center"></CardText>
                              </CardBody>
                            </Card>
                          </Link>
                        </Col>
                      );
                    })}
                </Row>
              )}
            </div>
          </CardBody>
        </Container>
      </React.Fragment>
    );
  }
}
