import React, { Component } from "react";
import {
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { addUserRole } from "../../../services/UserServices";
import { toast } from "react-toastify";
import {
  addSectionClientUser,
  getUserSchoolSections,
} from "../../../services/ClientUserServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faBan } from "@fortawesome/free-solid-svg-icons";
import trimObj from "../../../components/common/TrimData";
toast.configure();

export default class ClientUserRoleSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleId: "",
      userRoles: props.userRoles,
      errorMessage: "",
      isShowRoleModal: false,
      clientUserId: props.clientUserId,
      roleId: "",
      UserSections: [],
      showSectionModal: false,
      registrationCode: "",
      isDisable: false,
    };
  }
  componentDidMount() {
    this.setState({ userRoles: this.props.userRoles });
    this.getUserSections(this.state.clientUserId);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.clientUserId !== this.props.clientUserId) {
      this.getUserSections(this.props.clientUserId);
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (state.clientUserId !== props.clientUserId) {
      let allState = { ...state };
      allState.userRoles = props.userRoles;
      allState.clientUserId = props.clientUserId;
      return {
        userRoles: props.userRoles,
        clientUserId: props.clientUserId,
        allState: allState,
      };
    }
  }
  addAdditionRole = () => {
    let data = { userId: this.state.clientUserId, roleId: this.state.roleId };
 
    this.setState({ isDisable: true });
    addUserRole(data)
      .then((responseClientUserData) => {
        if (responseClientUserData !== undefined) {
          this.setState({
            userRoles: responseClientUserData.userRoles,
            isShowRoleModal: false,
            isDisable: false,
          });
          toast.success("Role Added Successfully", {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            formLoading: false,
            isDisable: false,
          });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 5000);
      });
  };
  showRoleModal = () => {
    this.setState({ isShowRoleModal: true });
  };
  closeRoleModal = () => {
    this.setState({ isShowRoleModal: false });
  };
  handChangeRole = (event) => {
    this.setState({ roleId: event.target.value });
  };
  getUserSections = (id) => {
    this.setState({ isloading: true });
    getUserSchoolSections(id)
      .then((responseClientUserData) => {
        if (
          responseClientUserData !== undefined &&
          responseClientUserData.length > 0 &&
          responseClientUserData[0]["userRoles"] !== undefined &&
          responseClientUserData[0]["userRoles"][0][
            "userRolesSchoolSections"
          ] !== undefined
        ) {
          let userSectionList = [];
          responseClientUserData[0]["userRoles"][0][
            "userRolesSchoolSections"
          ].map((userSection) => {
            let data = {};
            data["subject"] =
              userSection.schoolSection.boardGradeSubject.subject.subject;
            data["grade"] =
              userSection.schoolSection.boardGradeSubject.grade.grade;
            data["sectionName"] = userSection.schoolSection.sectionName;

            userSectionList.push(data);
          });
          this.setState({ UserSections: userSectionList });
        } else {
          this.setState({ UserSections: [] });
        }
      })
      .catch((error) => {
        console.log("error in client user section", error);
        if (error !== undefined) {
          this.setState({ isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  addUserSection = () => {
    let index = this.state.userRoles.findIndex(
      (role) => role.roleId == 3 || role.roleId == 4
    );

    let data = {
      userId: this.state.clientUserId,
      registrationCode: this.state.registrationCode,
      roleId: this.state.userRoles[index]["roleId"],
    };
    this.setState({ isDisable: true });
    data = trimObj(data);
    addSectionClientUser(data)
      .then((responseClientUserData) => {
        if (responseClientUserData !== undefined) {
          this.setState({
            showSectionModal: false,
            isDisable: false,
            registrationCode: "",
          });
          toast.success("Section Added Successfully", {
            autoClose: 3000,
            hideProgressBar: true,
          });
          this.getUserSections(responseClientUserData.id);
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            formLoading: false,
            isDisable: false,
          });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 5000);
      });
  };
  handChangeRegistration = (event) => {
    this.setState({ registrationCode: event.target.value });
  };
  showModalSection = () => {
    this.setState({ showSectionModal: true, isDisable: false });
  };
  closeModalSection = () => {
    this.setState({ showSectionModal: false, isDisable: false });
  };
  render() {
    const Roles = {
      1: "Client Admin",
      2: "School Admin",
      3: "Teacher",
      4: "Student",
    };
    const additionRoles = this.props.userRoles.some((role) => role.roleId == 3)
      ? [{ label: "School Admin", value: 2 }]
      : [{ label: "Teacher", value: 3 }];
    return (
      <>
        <div className="form-group form-gp">
          <Row>
            <Col md="3">Roles</Col>
            <Col md="6">
              <Table>
                <thead>
                  <tr>
                    <th>Role</th>
                    <th>Default Role</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.userRoles !== undefined &&
                    this.state.userRoles.map((role, index) => {
                      return (
                        <tr key={index}>
                          <td> {Roles[role.roleId]}</td>
                          <td>
                            {" "}
                            {role.defaultRole ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="activeIcon"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faBan}
                                className="inActiveIcon"
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
            {this.state.userRoles.some(
              (role) => role.roleId == 3 || role.roleId == 2
            ) &&
              this.state.userRoles.length < 2 && (
                <Col md="2">
                  <button
                    className="btn btn-primary-fill"
                    onClick={this.showRoleModal}
                  >
                    Add Role
                  </button>
                </Col>
              )}
          </Row>
        </div>
        <div className="form-group form-gp">
          <Row>
            <Col md="3">
              <label>Section</label>
            </Col>
            <Col md="6">
              <Table>
                <thead>
                  <tr>
                    <th>Grade</th>
                    <th>Subject</th>
                    <th>Sections</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.UserSections !== undefined &&
                    this.state.UserSections.map((userSection, index) => {
                      return (
                        <tr key={index}>
                          <td>{userSection.grade}</td>
                          <td>{userSection.subject}</td>
                          <td>{userSection.sectionName}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
            {this.state.userRoles.some(
              (role) => role.roleId == 3 || role.roleId == 4
            ) && (
              <Col md="2">
                <button
                  className="btn btn-primary-fill"
                  onClick={this.showModalSection}
                >
                  Add Section
                </button>
              </Col>
            )}
          </Row>
        </div>
        <Modal isOpen={this.state.isShowRoleModal} size="md">
          <ModalHeader>Add Role</ModalHeader>
          <ModalBody>
            <div className="form-group form-gp">
              {this.state.errorMessage !== "" ? (
                <p className="alert alert-danger">{this.state.errorMessage}</p>
              ) : (
                ""
              )}
              <Row>
                <Col md="4">
                  <label> userName</label>
                </Col>
                <Col md="8">
                  <label> {this.props.userName}</label>
                </Col>
              </Row>
            </div>
            <div className="form-group form-gp">
              <Row>
                <Col md="4">Role</Col>
                <Col md="8">
                  <select
                    name="roleId"
                    className="form-control"
                    onChange={this.handChangeRole}
                    value={this.state.roleId}
                  >
                    <option value="">Select Role</option>
                    {additionRoles !== undefined &&
                      additionRoles.map((option) => (
                        <option value={option.value} key={option.value}>
                          {option.label}
                        </option>
                      ))}
                  </select>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-secondary mr-2"
              onClick={this.closeRoleModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary-fill"
              onClick={this.addAdditionRole}
              disabled={this.state.isDisable}
            >
              Add
            </button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.showSectionModal} size="md">
          <ModalHeader>Add Section</ModalHeader>
          <ModalBody>
            <div className="form-group form-gp">
              {this.state.errorMessage !== "" ? (
                <p className="alert alert-danger">{this.state.errorMessage}</p>
              ) : (
                ""
              )}
            </div>
            <div className="form-group form-gp">
              <Row>
                <Col md="4">
                  <label>Registration Code</label>
                </Col>
                <Col md="8">
                  <input
                    type="text"
                    name="registrationCode"
                    autoComplete="off"
                    value={this.state.registrationCode}
                    className="form-control"
                    placeholder="Registration Code"
                    onChange={this.handChangeRegistration}
                  />
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-secondary mr-2"
              onClick={this.closeModalSection}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary-fill"
              onClick={this.addUserSection}
              disabled={this.state.isDisable}
            >
              Add{" "}
            </button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
