import axios from 'axios'
const BASE_URL = "https://api.ourmeetonline.in";


const Api = axios.create({
    baseURL: BASE_URL
});



Api.interceptors.request.use(function (config) {
    var token = localStorage.getItem('token')
    if (token !== undefined && token !== null) {
        config.headers.common['Authorization'] = `Bearer ${token}`;
        config.headers.common['Content-Type'] = 'application/json';
    }

    return config;
});

Api.interceptors.response.use((response) => {
    return response;
}, function (error) {
    if (error.response && error.response.status === 401) {
        console.log('unauthorized, logging out ...');
        localStorage.removeItem('token')
        localStorage.clear();
        window.location.href='/';
    }
    return Promise.reject(error.response);

});


export default Api
