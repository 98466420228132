import React, { Component } from "react";
import { Progress } from "reactstrap";
import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import { Row, Col, Card, CardBody } from "reactstrap";
import * as Yup from "yup";
export default class PracticeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { errorMessage: "" };
    this.radioRef = React.createRef();
    this.radioRef2 = React.createRef();
    this.checkboxRef = React.createRef();
  }

  // this function is used to show buffer data
  bufferFromBufferString = (data) => {
    let utf8decoder = new TextDecoder();
    let u16arr = new Uint8Array(data);
    let dataPrepare = utf8decoder.decode(u16arr);
    return dataPrepare;
  };
  createMarkup = (data) => {
    return { __html: data };
  };

  handleValueChange = (id, text, setFieldValue, typeId, values) => {
    if (typeId == "1" || typeId == "2" || typeId == "4" || typeId == "5") {
      let submittedAnswer = [
        {
          answerId: id,
          answerText: text,
        },
      ];
      setFieldValue("submittedAnswer", submittedAnswer);
    }
    if (typeId == "3") {
      console.log("values old", values.submittedAnswer);
      let answer = [
        ...values.submittedAnswer.filter((item) => item.answerId != ""),
      ];
      const value = {
        answerId: id,
        answerText: text,
      };
      const index = answer.findIndex((answerId) => answerId.answerId === id);
      if (index > -1) {
        answer = answer.filter((item) => item.answerId != id);
      } else {
        answer.push(value);
      }
      console.log("submittedAnswer End", answer);
      setFieldValue("submittedAnswer", answer);
    }
  };
  createMarkup = (data) => {
    return { __html: data };
  };

  render() {
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row className="mt-3">
                        <Col className="progressBarQuestion">
                          <Progress
                            color="success"
                            value={this.props.calculate}
                          >
                            {this.props.calculate} %
                          </Progress>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      <div>
                        <Formik
                          enableReinitialize
                          initialValues={this.props.initialValues}
                          onSubmit={async (values, actions) => {
                            if (
                              values.submittedAnswer.length <= 0 ||
                              values.submittedAnswer[0].answerId === "" ||
                              values.submittedAnswer[0].answerText === ""
                            ) {
                              this.setState({
                                errorMessage: "Please answer the question",
                              });
                              setTimeout(() => {
                                this.setState({ errorMessage: "" });
                              }, 3000);
                              //
                            } else {
                              this.props.onNextquestion(values, actions);
                            }
                          }}
                        >
                          {(props) => {
                            const {
                              values,
                              errors,
                              handleChange,
                              handleSubmit,
                              handleBlur,
                              touched,
                              setFieldValue,
                              setValues,
                            } = props;
                            return (
                              <Form onSubmit={handleSubmit}>
                                {this.state.errorMessage !== "" ? (
                                  <p className="alert alert-danger">
                                    {this.state.errorMessage}
                                  </p>
                                ) : (
                                  ""
                                )}
                                {this.props.inCorrectAnswerMessage && (
                                  <p className="alert alert-danger">
                                    Incorrect Answer Please try again
                                  </p>
                                )}

                                <div className="title questionTitle">
                                  <h3>Question:</h3>
                                  <h4>
                                    <span
                                      dangerouslySetInnerHTML={this.createMarkup(
                                        this.props.question
                                      )}
                                    ></span>
                                  </h4>
                                </div>
                                <div className="optionsQuestion">
                                  <h3>Options:</h3>
                                  <FieldArray
                                    name="submittedAnswer"
                                    render={({ insert, remove, push }) => (
                                      <>
                                        {this.props
                                          .schoolSectionQuestionTypeId == 1 ? (
                                          <div>
                                            {this.props
                                              .schoolSectionStudentEvaluationAnswers !==
                                              undefined &&
                                              this.props.schoolSectionStudentEvaluationAnswers.map(
                                                (option, index) => {
                                                  return (
                                                    <div className="row mb-3 justify-content-center align-items-center">
                                                      <div className="col-md-12">
                                                        <input
                                                          type="radio"
                                                          name={`submittedAnswer[0].answerId`}
                                                          value={option.id}
                                                          checked={
                                                            values
                                                              .submittedAnswer[0]
                                                              .answerId ===
                                                            option.id
                                                          }
                                                          onChange={() =>
                                                            this.handleValueChange(
                                                              option.id,
                                                              this.bufferFromBufferString(
                                                                option
                                                                  .optionText
                                                                  .data
                                                              ),
                                                              setFieldValue,
                                                              "1"
                                                            )
                                                          }
                                                          onBlur={handleBlur}
                                                          className="form-control CheckRadio radioSmall"
                                                        />
                                                        <input
                                                          type="hidden"
                                                          name={`submittedAnswer[0].answerText`}
                                                          value={this.bufferFromBufferString(
                                                            option.optionText
                                                              .data
                                                          )}
                                                          onChange={
                                                            handleChange
                                                          }
                                                          onBlur={handleBlur}
                                                        />
                                                        <label className="ml-2">
                                                          <span
                                                            dangerouslySetInnerHTML={this.createMarkup(
                                                              this.bufferFromBufferString(
                                                                option
                                                                  .optionText
                                                                  .data
                                                              )
                                                            )}
                                                          ></span>
                                                        </label>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>
                                        ) : null}
                                        {this.props
                                          .schoolSectionQuestionTypeId == 2 && (
                                          <div
                                            role="group"
                                            aria-labelledby="checkbox-group"
                                          >
                                            {this.props
                                              .schoolSectionStudentEvaluationAnswers !==
                                              undefined &&
                                              this.props.schoolSectionStudentEvaluationAnswers.map(
                                                (option, index) => {
                                                  return (
                                                    <div className="row mb-3 justify-content-center align-items-center">
                                                      <div className="col-md-12">
                                                        <input
                                                          type="radio"
                                                          name={`submittedAnswer[0].answerId`}
                                                          value={option.id}
                                                          checked={
                                                            values
                                                              .submittedAnswer[0]
                                                              .answerId ===
                                                            option.id
                                                          }
                                                          onChange={() =>
                                                            this.handleValueChange(
                                                              option.id,
                                                              this.bufferFromBufferString(
                                                                option
                                                                  .optionText
                                                                  .data
                                                              ),
                                                              setFieldValue,
                                                              "2"
                                                            )
                                                          }
                                                          onBlur={handleBlur}
                                                          className="form-control CheckRadio radioSmall"
                                                        />
                                                        <input
                                                          type="hidden"
                                                          name={`submittedAnswer[0].answerText`}
                                                          value={this.bufferFromBufferString(
                                                            option.optionText
                                                              .data
                                                          )}
                                                        />
                                                        <label className="ml-2">
                                                          {this.bufferFromBufferString(
                                                            option.optionText
                                                              .data
                                                          )}
                                                        </label>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>
                                        )}
                                        {this.props
                                          .schoolSectionQuestionTypeId == 3 && (
                                          <div
                                            role="group"
                                            aria-labelledby="checkbox-group"
                                          >
                                            {this.props
                                              .schoolSectionStudentEvaluationAnswers !==
                                              undefined &&
                                              this.props.schoolSectionStudentEvaluationAnswers.map(
                                                (option, index) => {
                                                  return (
                                                    <div className="row mb-3 justify-content-center align-items-center">
                                                      <div className="col-md-12">
                                                        <input
                                                          ref={this.checkboxRef}
                                                          type="checkbox"
                                                          name={`submittedAnswer.${index}.answerId`}
                                                          value={option.id}
                                                          checked={values.submittedAnswer.some(
                                                            (ans) =>
                                                              ans.answerId ===
                                                              option.id
                                                          )}
                                                          onChange={() =>
                                                            this.handleValueChange(
                                                              option.id,
                                                              this.bufferFromBufferString(
                                                                option
                                                                  .optionText
                                                                  .data
                                                              ),
                                                              setFieldValue,
                                                              "3",
                                                              values
                                                            )
                                                          }
                                                          onBlur={handleBlur}
                                                          className="form-control checkboxSmall"
                                                        />
                                                        <input
                                                          type="hidden"
                                                          name={`submittedAnswer.${index}.answerText`}
                                                          value={this.bufferFromBufferString(
                                                            option.optionText
                                                              .data
                                                          )}
                                                        />
                                                        <label className="ml-2">
                                                          {this.bufferFromBufferString(
                                                            option.optionText
                                                              .data
                                                          )}
                                                        </label>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>
                                        )}
                                        {this.props
                                          .schoolSectionQuestionTypeId == 4 && (
                                          <div
                                            role="group"
                                            aria-labelledby="checkbox-group"
                                          >
                                            {this.props
                                              .schoolSectionStudentEvaluationAnswers !==
                                              undefined &&
                                              this.props.schoolSectionStudentEvaluationAnswers.map(
                                                (option, index) => {
                                                  return (
                                                    <div>
                                                      <input
                                                        type="text"
                                                        name={`submittedAnswer.${index}.answerId`}
                                                        onChange={(e) =>
                                                          this.handleValueChange(
                                                            option.id,
                                                            e.target.value,
                                                            setFieldValue,
                                                            "4"
                                                          )
                                                        }
                                                        value={
                                                          values
                                                            .submittedAnswer[
                                                            index
                                                          ].answerText
                                                        }
                                                        onBlur={handleBlur}
                                                      />
                                                      <input
                                                        type="hidden"
                                                        name={`submittedAnswer.${index}.answerText`}
                                                        value={this.bufferFromBufferString(
                                                          option.optionText.data
                                                        )}
                                                      />
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>
                                        )}
                                        {this.props
                                          .schoolSectionQuestionTypeId == 5 && (
                                          <div
                                            role="group"
                                            aria-labelledby="checkbox-group"
                                          >
                                            {this.props
                                              .schoolSectionStudentEvaluationAnswers !==
                                              undefined &&
                                              this.props.schoolSectionStudentEvaluationAnswers.map(
                                                (option, index) => {
                                                  return (
                                                    <div>
                                                      <input
                                                        type="text"
                                                        name={`submittedAnswer.${index}.answerId`}
                                                        onChange={(e) =>
                                                          this.handleValueChange(
                                                            option.id,
                                                            e.target.value,
                                                            setFieldValue,
                                                            "5"
                                                          )
                                                        }
                                                        value={
                                                          values
                                                            .submittedAnswer[
                                                            index
                                                          ].answerText
                                                        }
                                                        onBlur={handleBlur}
                                                      />
                                                      <input
                                                        type="hidden"
                                                        name={`submittedAnswer.${index}.answerText`}
                                                        value={this.bufferFromBufferString(
                                                          option.optionText.data
                                                        )}
                                                      />
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>
                                        )}
                                      </>
                                    )}
                                  ></FieldArray>
                                </div>
                                {this.props.showAnswer && (
                                  <div className="title questionTitle">
                                    <h3>Answer Explanation:</h3>
                                    <h4>
                                      <span
                                        dangerouslySetInnerHTML={this.createMarkup(
                                          this.bufferFromBufferString(
                                            this.props
                                              .schoolSectionAnswerExplanation
                                              .data
                                          )
                                        )}
                                      ></span>
                                    </h4>
                                  </div>
                                )}

                                <div className="questionFormFooter">
                                  <button
                                    type="button"
                                    className="btn btn-primary-fill"
                                    color="success"
                                    onClick={this.props.onPrevquestion}
                                  >
                                    Previous
                                  </button>
                                  <button
                                    className="btn btn-primary-fill ml-3"
                                    type="submit"
                                    color="success"
                                  >
                                    {this.props.lastQuestion
                                      ? "Submit"
                                      : "Next"}
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}
