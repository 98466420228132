import React, { Component } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { getSchoolList } from "../../../services/SchoolServices";
import { getClientList } from "../../../services/ClientServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllSchoolSectionList,
  getBoardGradeSubject,
  addSchoolSection,
  getSchoolSectionList,
  updateSchoolSection,
} from "../../../services/SchoolSectionServices";
import AddEditSchoolSection from "./AddEditSchoolSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
toast.configure();
class schoolSectionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolList: [],
      errorMessage: "",
      isloading: false,
      clientOptions: [],
      selectedClientValue: "",
      showModalSchoolSection: false,
      clientName: "",
      boardList: [],
      boardValue: "",
      initialValues: {
        boardGradeSubjectId: "",
        schoolId: "",
        sectionName: "",
        isActive: false,
        gradeId: "",
      },
      titleAddEdit: "Add",
      schoolsOptions: [],
      selectedSchoolValue: "",
      schoolSectionList: [],
      schoolName: "",
      boardId: "",
      gradeList: [],
      subjectList: [],
      schoolId: "",
      allSchoolSections: [],
      ClientSchoolSections: [],
      selectedGrade: "",
      selectedGradeSubjectList: [],
      boardGradeSubjectId: "",
    };
  }
  componentDidMount() {
    this.getClientList();
    let boardList = [];
    let boardData = JSON.parse(localStorage.getItem("boardData"));
    if (boardData !== null) {
      boardData.map((board) => {
        boardList.push({ label: board.boardCode, value: board.id });
      });
      this.setState({ boardList: boardList, boardValue: boardList[0].id });
    }
  }
  getClientList = () => {
    this.setState({ isloading: true });
    getClientList()
      .then((responseClientList) => {
        if (responseClientList !== undefined) {
          let clientOptions = [{ label: "Select Client", value: "" }];
          responseClientList.rows.map((client) => {
            clientOptions.push({ label: client.clientName, value: client.id });
          });
          if (clientOptions !== []) {
            this.setState({
              clientName: clientOptions[0].label,
              clientOptions: clientOptions,
            });
            this.getSchoolSections();
          } else {
            this.setState({ isloading: false });
          }
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message, isloading: false });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };
  // this fucntion fetch school sections and school list
  getSchoolSections = () => {
    getAllSchoolSectionList()
      .then((responseSchoolSections) => {
        if (responseSchoolSections !== undefined) {
          this.setState({
            schoolSectionList: responseSchoolSections.rows,
            isloading: false,
            allSchoolSections: responseSchoolSections.rows,
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.message,
          isloading: false,
          schoolSectionList: [],
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };
  // this function is used to show add school section modal
  handleModalShow = () => {
    let initialValues = { ...this.state.initialValues };
    initialValues.schoolId = this.state.selectedSchoolValue;
    initialValues.boardGradeSubjectId = this.state.subjectList["g" + 1];
    console.log("inital value", this.state.initialValues);
    this.setState({
      isloading: true,
      showModalSchoolSection: !this.state.showModalSchoolSection,
      initialValues: initialValues,
    });
    let schoolData = this.state.schoolList.find(
      (school) => school.id == this.state.selectedSchoolValue
    );
    let boardId = "";
    if (schoolData !== undefined) {
      boardId = schoolData.boardId;
    }
    // get all grade and grade subjects
    getBoardGradeSubject(boardId)
      .then((gradeSubjectList) => {
        if (gradeSubjectList !== undefined) {
          let subjectList = [];
          let gradeList = [];
          gradeSubjectList.map((gradeSubject) => {
            gradeList[gradeSubject.grade.id] = {
              label: gradeSubject.grade.grade,
              value: gradeSubject.grade.id,
            };

            if (subjectList["g" + gradeSubject.grade.id] !== undefined)
              subjectList["g" + gradeSubject.grade.id].push({
                label: gradeSubject.subject.subject,
                value: gradeSubject.subject.id,
                boardGradeSubjectId: gradeSubject.id,
              });
            else
              subjectList["g" + gradeSubject.grade.id] = [
                {
                  label: gradeSubject.subject.subject,
                  value: gradeSubject.subject.id,
                  boardGradeSubjectId: gradeSubject.id,
                },
              ];
          });

          this.setState({
            gradeList: gradeList,
            subjectList: subjectList,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message, isloading: false });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };

  // this function is used to close school section Modal
  handleModalHide = () => {
    this.setState({
      showModalSchoolSection: !this.state.showModalSchoolSection,
      initialValues: {
        boardGradeSubjectId: "",
        schoolId: "",
        sectionName: "",
        isActive: false,
      },
      titleAddEdit: "Add",
      errorMessage: "",
    });
  };

  // this function is for geting school list from client id
  getSchoolList = (clientId) => {
    this.setState({ isloading: true });
    getSchoolList(clientId)
      .then((responseSchoolList) => {
        if (responseSchoolList !== undefined) {
          let schoolsOptions = [{ label: "Select School", value: "" }];

          responseSchoolList.rows.map((school) => {
            schoolsOptions.push({ label: school.schoolName, value: school.id });
          });
          let schoolName = schoolsOptions[0].label;
          let schoolId = schoolsOptions[0].value;
          let boardId =
            responseSchoolList.rows.length > 0
              ? responseSchoolList.rows[0].boardId
              : "";
          this.setState({
            schoolsOptions: schoolsOptions,
            schoolName: schoolName,
            boardId: boardId,
            schoolId: schoolId,
            isloading: false,
            schoolList: responseSchoolList.rows,
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false, schoolsOptions: [] });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };
  filterSectionBySchool = (event) => {
    let filterSchoolSection = [];
    let allSchoolSections =
      this.state.selectedClientValue !== ""
        ? this.state.ClientSchoolSections
        : this.state.allSchoolSections;
    if (event.target.value !== "") {
      filterSchoolSection = allSchoolSections.filter(
        (schoolSection) => schoolSection.schoolId == event.target.value
      );
    } else {
      filterSchoolSection = [...allSchoolSections];
    }

    this.setState({
      schoolSectionList: filterSchoolSection,
      selectedSchoolValue: event.target.value,
      schoolName: event.target.selectedOptions[0].text,
    });
  };
  filterSectionByClient = (event) => {
    let filterSchoolSection = [];
    if (event.target.value !== "") {
      filterSchoolSection = this.state.allSchoolSections.filter(
        (schoolSection) => schoolSection.school.clientId == event.target.value
      );
    } else {
      filterSchoolSection = [...this.state.allSchoolSections];
    }
    this.setState({
      schoolSectionList: filterSchoolSection,
      ClientSchoolSections: filterSchoolSection,
      selectedClientValue: event.target.value,
      clientName: event.target.selectedOptions[0].text,
      selectedSchoolValue: "",
    });
    this.getSchoolList(event.target.value);
  };
  filterSectionByClientUpdate = (clientId) => {
    let filterSchoolSection = [];
    if (clientId !== "") {
      filterSchoolSection = this.state.allSchoolSections.filter(
        (schoolSection) => schoolSection.school.clientId == clientId
      );
    } else {
      filterSchoolSection = [...this.state.allSchoolSections];
    }
    // this.setState({schoolSectionList:filterSchoolSection,ClientSchoolSections:filterSchoolSection,selectedClientValue:clientId,clientName:event.target.selectedOptions[0].text,selectedSchoolValue:''})
    // this.getSchoolList(clientId)
  };
  // this function used in grade change select
  handleChangeGrade = (event) => {
    let subjectList = this.state.subjectList["g" + event.target.value];
    this.setState({
      selectedGrade: event.target.value,
      selectedGradeSubjectList: subjectList,
    });
  };
  addSchoolSectionSubmit = (schoolSectionData, actions) => {
    this.setState({ isloading: true });
    addSchoolSection(schoolSectionData)
      .then((responseSchoolSectionData) => {
        if (responseSchoolSectionData !== undefined) {
          this.setState({ isloading: false, showModalSchoolSection: false });
          toast.success(responseSchoolSectionData.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          return getSchoolSectionList(this.state.selectedSchoolValue);
        }
      })
      .then((responseSchoolSections) => {
        if (responseSchoolSections !== undefined) {
          this.setState({
            schoolSectionList: responseSchoolSections.rows,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("");
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        }
      });
  };

  updateSchoolSection = (sectionData, actions) => {
    console.log(sectionData, "sectionData");
    this.setState({ isloading: true });
    //schoolId:sectionData.schoolId
    let data = {
      isActive: sectionData.isActive,
      sectionName: sectionData.sectionName,
    };
    updateSchoolSection(sectionData.id, data)
      .then((responseSection) => {
        if (responseSection !== undefined) {
          let allSectionList = [...this.state.schoolSectionList];
          var foundIndex = allSectionList.findIndex(
            (section) => section.id == responseSection.data.id
          );
          //find and replace with updated sectionss
          if (foundIndex >= 0) {
            allSectionList[foundIndex] = responseSection.data;
          }
          this.setState({
            isloading: false,
            showModalSchoolSection: false,
            initialValues: {
              boardGradeSubjectId: "",
              schoolId: "",
              sectionName: "",
              isActive: false,
              gradeId: "",
            },
            schoolSectionList: allSectionList,
          });
          toast.success("Section detail updated successfully", {
            autoClose: 3000,
            hideProgressBar: true,
          });
          actions.resetForm();
          this.filterSectionByClientUpdate();
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };
  showModalUPdateSection = (rowData) => {
    let gradeList = [];
    let subjectList = [];
    rowData.gradeId = rowData.boardGradeSubject.grade.id;
    gradeList[rowData.boardGradeSubject.grade.id] = {
      label: rowData.boardGradeSubject.grade.grade,
      value: rowData.boardGradeSubject.grade.id,
    };
    subjectList = [
      {
        label: rowData.boardGradeSubject.subject.subject,
        value: rowData.boardGradeSubject.subject.id,
        boardGradeSubjectId: rowData.boardGradeSubjectId,
      },
    ];
    this.setState({
      initialValues: rowData,
      showModalSchoolSection: true,
      titleAddEdit: "Update",
      gradeList: gradeList,
      selectedGrade: rowData.boardGradeSubject.grade.id,
      selectedGradeSubjectList: subjectList,
      clientName: rowData.school.client.clientName,
      schoolName: rowData.school.schoolName,
    });
  };
  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.showModalUPdateSection(row);
      },
    };
    const columns = [
      {
        dataField: "id",
        text: "School Section ID",
      },
      {
        dataField: "boardGradeSubject.grade.grade",
        text: "Grade",
      },
      {
        dataField: "boardGradeSubject.subject.subject",
        text: "Subject",
      },
      {
        dataField: "sectionName",
        text: "Section Name",
      },
      {
        dataField: "teacherRegistrationCode",
        text: "Teacher Registration Code",
      },
      {
        dataField: "studentRegistrationCode",
        text: "Student Registration Code",
      },
    ];
    return (
      <div className="container-fluid">
        <Row>
          <Col md={12}>
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row className="mb-4">
                        <Col md="4">
                          <div className="title">School Sections</div>
                        </Col>
                        <Col md="8">
                          <button
                            className="btn btn-primary-fill float-right mt-3"
                            onClick={this.handleModalShow}
                            disabled={
                              this.state.selectedClientValue !== "" &&
                              this.state.selectedSchoolValue !== ""
                                ? false
                                : true
                            }
                          >
                            {" "}
                            <FontAwesomeIcon icon={faPlus} /> &nbsp;Add School
                            Section
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3">
                          <select
                            className="form-control"
                            onChange={this.filterSectionByClient}
                          >
                            {this.state.clientOptions !== undefined &&
                              this.state.clientOptions.map((option) => (
                                <option value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        </Col>
                        <Col md="3">
                          <select
                            className="form-control"
                            onChange={this.filterSectionBySchool}
                          >
                            {this.state.schoolsOptions !== undefined &&
                              this.state.schoolsOptions.map((option) => (
                                <option value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      <div>
                        <div>
                          {this.state.isloading ? (
                            <div className="custom-loader">
                              <Spinner animation="border" />
                            </div>
                          ) : (
                            <BootstrapTable
                              keyField="id"
                              key={`clientListTable`}
                              data={this.state.schoolSectionList}
                              columns={columns}
                              pagination={paginationFactory({
                                sizePerPage: 10,
                                showTotal: true,
                                hideSizePerPage: true,
                              })}
                              rowClasses="row-hover pointer_class"
                              rowEvents={rowEvents}
                            />
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <AddEditSchoolSection
                showModalSchoolSection={this.state.showModalSchoolSection}
                handleModalHide={this.handleModalHide}
                onSubmit={
                  this.state.titleAddEdit === "Update"
                    ? this.updateSchoolSection
                    : this.addSchoolSectionSubmit
                }
                errorMessage={this.state.errorMessage}
                handleChangeGrade={this.handleChangeGrade}
                initialValues={this.state.initialValues}
                titleAddEdit={this.state.titleAddEdit}
                clientName={this.state.clientName}
                gradeList={this.state.gradeList}
                subjectList={this.state.selectedGradeSubjectList}
                schoolName={this.state.schoolName}
              />
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}

export default schoolSectionList;
