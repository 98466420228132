import React, { Component } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClientUserRoleSection from "./ClientUserRoleSection";
import trimObj from "../../../components/common/TrimData";
toast.configure();
export default class AddEditClientUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleId: "",
      userRoles: props.userRoles,
      userName: props.userName,
      errorMessage: "",
      isShowRoleModal: false,
      clientUserId: props.clientUserId,
    };
  }
  static getDerivedStateFromProps(props, state) {
    return { userRoles: props.userRoles, clientUserId: props.clientUserId };
  }
  handleChangeClient = (e) => {
    this.setState({ initialValues: [] });
    this.props.handleChangeClient(e);
  };
  render() {
    const addValidation = Yup.object().shape({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      primaryEmail: Yup.string()
        .email("Please enter a valid email in the format of example@domain.com")
        .required("Please Enter Email"),
      username: Yup.string().required("Please Enter UserName"),
      password: Yup.string()
        .required("Please Enter Password")
        .min(6, "Please enter at least 6 characters"),
    });
    const updateValidation = Yup.object().shape({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      primaryEmail: Yup.string()
        .email("Please enter a valid email in the format of example@domain.com")
        .required("Please Enter Email"),
      username: Yup.string().required("Please Enter UserName"),
      password: Yup.string().min(6, "Please enter at least 6 characters"),
    });
    return (
      <div className="container-fluid">
        <Row>
          <Col md={12}>
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  {this.props.formLoading ? (
                    <div className="custom-loader">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <Card className="card-box">
                      <div className="card-header">
                        {this.props.titleAddEdit === "Update" ? (
                          <Row>
                            <Col md="12">
                              <div className="title">
                                <h4> Update Client User</h4>
                              </div>
                            </Col>
                            <Col md="3" className="mt-3">
                              <label className="form-label">
                                Select Client
                              </label>
                              <select
                                className="form-control"
                                onChange={(e) => this.handleChangeClient(e)}
                              >
                                {this.props.clientOptions !== undefined &&
                                  this.props.clientOptions.map((option) => (
                                    <option
                                      value={option.value}
                                      key={option.value}
                                      selected={
                                        option.value ==
                                        this.props.initialValues.clientId
                                      }
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                              </select>
                            </Col>
                            <Col md="3" className="mt-3">
                              <label className="form-label">
                                Select School
                              </label>
                              <select
                                className="form-control"
                                onChange={this.props.handleChangeSchool}
                                disabled={
                                  this.props.schoolsOptions.length > 1
                                    ? false
                                    : true
                                }
                              >
                                {this.props.schoolsOptions !== undefined &&
                                  this.props.schoolsOptions.map((option) => (
                                    <option
                                      value={option.value}
                                      key={option.value}
                                      selected={
                                        option.value ==
                                        this.props.initialValues.schoolId
                                      }
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                              </select>
                            </Col>
                            <Col md="3" className="mt-3">
                              <label className="form-label">
                                Select Username
                              </label>
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  this.props.handleChangeUsername(e)
                                }
                                disabled={
                                  this.props.userNameOptions.length > 1
                                    ? false
                                    : true
                                }
                              >
                                {this.props.userNameOptions !== undefined &&
                                  this.props.userNameOptions.map((option) => (
                                    <option
                                      value={option.value}
                                      key={option.value}
                                      selected={
                                        option.value === this.props.clientUserId
                                      }
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                              </select>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col md="3">
                              <div className="title">
                                {this.props.showClientAdmin ? (
                                  <h4>Add Client Admin </h4>
                                ) : (
                                  <h4>Add Client User </h4>
                                )}
                              </div>
                            </Col>
                          </Row>
                        )}
                      </div>
                      {(this.props.schoolsOptions.length > 0 &&
                        this.props.userNameOptions.length >= 1) ||
                      this.props.titleAddEdit === "Add" ? (
                        <CardBody>
                          <div>
                            <Formik
                              enableReinitialize
                              initialValues={this.props.initialValues}
                              onSubmit={(values, actions) => {
                                if (values.middleName === "") {
                                  delete values["middleName"];
                                }
                                if (values.roleId == 1) {
                                  delete values["schoolId"];
                                }
								values = trimObj(values);
                                this.props.onSubmit(values, actions);
                              }}
                              validationSchema={
                                this.props.titleAddEdit === "Update"
                                  ? updateValidation
                                  : addValidation
                              }
                            >
                              {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                              }) => (
                                <form onSubmit={handleSubmit}>
                                  {this.props.errorMessage !== "" ? (
                                    <p className="alert alert-danger">
                                      {this.props.errorMessage}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {this.props.clientName &&
                                    this.props.titleAddEdit === "Add" && (
                                      <div className="form-group form-gp">
                                        <Row>
                                          <Col md="3">
                                            <label>Client Name</label>
                                          </Col>
                                          <Col md="6">
                                            <label>
                                              {this.props.clientName}
                                            </label>
                                          </Col>
                                        </Row>
                                      </div>
                                    )}
                                  {this.props.schoolName &&
                                    this.props.titleAddEdit === "Add" &&
                                    !this.props.showClientAdmin && (
                                      <div className="form-group form-gp">
                                        <Row>
                                          <Col md="3">
                                            <label>School Name</label>
                                          </Col>
                                          <Col md="6">
                                            <label>
                                              {this.props.schoolName}
                                            </label>
                                          </Col>
                                        </Row>
                                      </div>
                                    )}
                                  <div className="form-group form-gp">
                                    <Row>
                                      <Col md="3">
                                        <label> First Name</label>
                                      </Col>
                                      <Col md="6">
                                        <input
                                          type="text"
                                          name="firstName"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.firstName}
                                          className="form-control"
                                          placeholder="First Name"
                                        />
                                        <label className="err-msg">
                                          {errors.firstName &&
                                            touched.firstName &&
                                            errors.firstName}
                                        </label>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="form-group form-gp">
                                    <Row>
                                      <Col md="3">
                                        <label> Middle Name</label>
                                      </Col>
                                      <Col md="6">
                                        <input
                                          type="text"
                                          name="middleName"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={
                                            values.middleName !== null
                                              ? values.middleName
                                              : ""
                                          }
                                          className="form-control"
                                          placeholder="Middle Name"
                                        />
                                        <label className="err-msg">
                                          {errors.middleName &&
                                            touched.middleName &&
                                            errors.middleName}
                                        </label>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="form-group form-gp">
                                    <Row>
                                      <Col md="3">
                                        <label> Last Name</label>
                                      </Col>
                                      <Col md="6">
                                        <input
                                          type="text"
                                          name="lastName"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.lastName}
                                          className="form-control"
                                          placeholder="Last Name"
                                        />
                                        <label className="err-msg">
                                          {errors.lastName &&
                                            touched.lastName &&
                                            errors.lastName}
                                        </label>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="form-group form-gp">
                                    <Row>
                                      <Col md="3">
                                        <label> Email</label>
                                      </Col>
                                      <Col md="6">
                                        <input
                                          type="email"
                                          name="primaryEmail"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.primaryEmail}
                                          className="form-control"
                                          placeholder="Email"
                                        />
                                        <label className="err-msg">
                                          {errors.primaryEmail &&
                                            touched.primaryEmail &&
                                            errors.primaryEmail}
                                        </label>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="form-group form-gp">
                                    <Row>
                                      <Col md="3">
                                        <label> Username</label>
                                      </Col>
                                      <Col md="6">
                                        <input
                                          type="text"
                                          name="username"
                                          autoComplete="off"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.username}
                                          className="form-control"
                                          placeholder="User Name"
                                        />
                                        <label className="err-msg">
                                          {errors.username &&
                                            touched.username &&
                                            errors.username}
                                        </label>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="form-group form-gp">
                                    <Row>
                                      <Col md="3">
                                        <label> Password</label>
                                      </Col>
                                      <Col md="6">
                                        <input
                                          autoComplete="off"
                                          type="password"
                                          name="password"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.password}
                                          className="form-control"
                                          placeholder="password"
                                        />
                                        <label className="err-msg">
                                          {errors.password &&
                                            touched.password &&
                                            errors.password}
                                        </label>
                                      </Col>
                                    </Row>
                                  </div>

                                  {this.props.titleAddEdit === "Add" && (
                                    <div>
                                      <div className="form-group form-gp">
                                        <Row>
                                          <Col md="3">
                                            <label> Role</label>
                                          </Col>
                                          <Col md="6">
                                            <select
                                              name="roleId"
                                              className="form-control"
                                              onChange={handleChange}
                                              value={values.roleId}
                                              disabled={
                                                this.props.showClientAdmin
                                              }
                                            >
                                              <option value="">
                                                Select Role
                                              </option>
                                              {this.props.roles !== undefined &&
                                                this.props.roles.map(
                                                  (option) => (
                                                    <option
                                                      value={option.value}
                                                      key={option.value}
                                                    >
                                                      {option.label}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                            <label className="err-msg">
                                              {errors.roleId &&
                                                touched.roleId &&
                                                errors.roleId}
                                            </label>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  )}

                                  <div className="form-group form-gp">
                                    <Row>
                                      <Col md="3">
                                        <label> Active</label>
                                      </Col>
                                      <Col md="6">
                                        <div className="form-check">
                                          <input
                                            type="checkbox"
                                            name="isActive"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.isActive}
                                            defaultChecked={values.isActive}
                                            className="form-check-input position-static checkbox-2x c9"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="form-group form-gp">
                                    <Row>
                                      <Col md="3" className="offset-md-3">
                                        <button
                                          className="btn btn-secondary mr-2"
                                          onClick={this.props.hideUserForm}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          type="submit"
                                          className="btn btn-primary-fill"
                                        >
                                          {this.props.titleAddEdit}
                                        </button>
                                      </Col>
                                    </Row>
                                  </div>
                                </form>
                              )}
                            </Formik>
                          </div>
                          {this.props.titleAddEdit === "Update" && (
                            <div>
                              <ClientUserRoleSection
                                userRoles={this.state.userRoles}
                                isShowRoleModal={this.props.isShowRoleModal}
                                userName={this.props.userName}
                                titleAddEdit={this.props.titleAddEdit}
                                closeRoleModal={this.props.closeRoleModal}
                                showRoleModal={this.props.showRoleModal}
                                clientUserId={this.state.clientUserId}
                              />
                            </div>
                          )}
                        </CardBody>
                      ) : (
                        <span className="no-data"> No username available</span>
                      )}
                    </Card>
                  )}
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}
