import Api from '../Api'

export const getSchoolList=(id,page=1,size=1000) => {
    return  Api.get('school/getSchool/getByClient/'+id+'?page='+page+'&size='+size).then(res=>res.data)
 }
 
 export const schoolSectionList=(id) => {
    return  Api.get('user/getbyschoolsection/'+id).then(res=>res.data)
 }
 export const addSchool=(schoolData) => {
   return  Api.post('school/addschool',schoolData).then(res=>res.data)
}
export const updateSchool = (schoolId,schoolDetails) => {
   return Api.put('school/updateschool/'+schoolId, schoolDetails).then(res=>res.data)
}
