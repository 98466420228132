import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import trimObj from "../../components/common/TrimData";
toast.configure();
export default class AddEditOmoUser extends Component {
  closeModal = (resetForm) => {
    resetForm();
    this.props.handleModalHide();
  };
  render() {
    let addValidation = Yup.object().shape({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      primaryEmail: Yup.string()
        .email("Please enter a valid email in the format of example@domain.com")
        .required("Please Enter Email"),
      username: Yup.string().required("Please Enter UserName"),
      password: Yup.string()
        .required("Please Enter Password")
        .min(6, "Please enter at least 6 characters"),
      roleId: Yup.string().required("Please Select Role"),
    });
    let updateValidation = Yup.object().shape({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      primaryEmail: Yup.string()
        .email("Please enter a valid email in the format of example@domain.com")
        .required("Please Enter Email"),
      username: Yup.string().required("Please Enter UserName"),
      password: Yup.string().min(6, "Please enter at least 6 characters"),
    });

    return (
      <>
        <Formik
          enableReinitialize
          initialValues={this.props.initialValues}
          onSubmit={(values, actions) => {
            // values.middleName=values.middleName	!==''?values.middleName:null
            if (values.middleName === "") {
              delete values["middleName"];
            }
            values = trimObj(values);
            this.props.onSubmit(values, actions);
          }}
          validationSchema={
            this.props.titleAddEdit === "Update"
              ? updateValidation
              : addValidation
          }
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal isOpen={this.props.showModalOmoUser} size="md">
                <ModalHeader>
                  <span>{this.props.titleAddEdit} OMO User</span>
                  <div className="modal_popup_close_icon">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => this.closeModal(resetForm)}
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  {this.props.errorMessage !== "" ? (
                    <p className="alert alert-danger">
                      {this.props.errorMessage}
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="4">
                        <label> First Name</label>
                      </Col>
                      <Col md="8">
                        <input
                          type="text"
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          className="form-control"
                          placeholder="First Name"
                        />
                        <label className="err-msg">
                          {errors.firstName &&
                            touched.firstName &&
                            errors.firstName}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="4">
                        <label> Middle Name</label>
                      </Col>
                      <Col md="8">
                        <input
                          type="text"
                          name="middleName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={
                            values.middleName !== null ? values.middleName : ""
                          }
                          className="form-control"
                          placeholder="Middle Name"
                        />
                        <label className="err-msg">
                          {errors.middleName &&
                            touched.middleName &&
                            errors.middleName}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="4">
                        <label> Last Name</label>
                      </Col>
                      <Col md="8">
                        <input
                          type="text"
                          name="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                          className="form-control"
                          placeholder="Last Name"
                        />
                        <label className="err-msg">
                          {errors.lastName &&
                            touched.lastName &&
                            errors.lastName}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="4">
                        <label> Email</label>
                      </Col>
                      <Col md="8">
                        <input
                          type="email"
                          name="primaryEmail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.primaryEmail}
                          className="form-control"
                          placeholder="Email"
                        />
                        <label className="err-msg">
                          {errors.primaryEmail &&
                            touched.primaryEmail &&
                            errors.primaryEmail}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="4">
                        <label> Username</label>
                      </Col>
                      <Col md="8">
                        <input
                          type="text"
                          name="username"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.username}
                          className="form-control"
                          placeholder="User Name"
                        />
                        <label className="err-msg">
                          {errors.username &&
                            touched.username &&
                            errors.username}
                        </label>
                      </Col>
                    </Row>
                  </div>

                  <div className="form-group form-gp">
                    <Row>
                      <Col md="4">
                        <label> Password</label>
                      </Col>
                      <Col md="8">
                        <input
                          autoComplete="off"
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          className="form-control"
                          placeholder="password"
                        />
                        <label className="err-msg">
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="4">
                        <label> Role</label>
                      </Col>
                      <Col md="8">
                        <select
                          name="roleId"
                          className="form-control"
                          onChange={handleChange}
                          value={values.roleId}
                          disabled={
                            this.props.titleAddEdit === "Update" ? true : null
                          }
                        >
                          <option value="">Select Role</option>
                          {this.props.roles !== undefined &&
                            this.props.roles.map((option) => (
                              <option value={option.value} key={option.value}>
                                {option.label}
                              </option>
                            ))}
                        </select>
                        <label className="err-msg">
                          {errors.roleId && touched.roleId && errors.roleId}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="4">
                        <label> Active</label>
                      </Col>
                      <Col md="2">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="isActive"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isActive}
                            defaultChecked={values.isActive}
                            className="form-check-input position-static checkbox-2x c9"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => this.closeModal(resetForm)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="btn btn-primary-fill"
                    onClick={handleSubmit}
                  >
                    {this.props.titleAddEdit}
                  </Button>
                </ModalFooter>
              </Modal>
            </form>
          )}
        </Formik>
      </>
    );
  }
}
