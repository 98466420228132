import React, { Component } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  getSchoolList,
  addSchool,
  updateSchool,
} from "../../services/SchoolServices";
import { getClientList } from "../../services/ClientServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddEditSchool from "./AddEditSchool";
import { boardData } from "../../services/GeneralServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
toast.configure();
class SchoolList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolList: [],
      errorMessage: "",
      isloading: false,
      clientList: [],
      clientOptions: [],
      selectedClientValue: "",
      showModalSchool: false,
      clientName: "",
      boardList: [],
      boardValue: "",
      initialValues: {
        clientId: "",
        boardId: "",
        schoolName: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        city: "",
        state: "",
        pin: "",
        isActive: false,
      },
      titleAddEdit: "Add",
    };
  }
  componentDidMount() {
    this.getClientList();
  }
  getClientList = () => {
    this.setState({ isloading: true });
    getClientList()
      .then((responseClientList) => {
        if (responseClientList !== undefined) {
          let options = [];
          responseClientList.rows.map((client) => {
            options.push({ label: client.clientName, value: client.id });
          });
          if (options !== []) {
            this.setState({
              selectedClientValue: options[0].value,
              clientName: options[0].label,
              clientOptions: options,
            });
            return getSchoolList(options[0].value, 1);
          } else {
            this.setState({ isloading: false });
          }
        }
      })
      .then((responseSchoolList) => {
        if (responseSchoolList !== undefined) {
          this.setState({
            schoolList: responseSchoolList.rows,
            isloading: false,
          });
          return boardData();
        }
      })
      .then((boardData) => {
        if (boardData !== undefined) {
          let boardList = [];
          boardData.map((board) => {
            boardList.push({ label: board.boardCode, value: board.id });
          });
          this.setState({ boardList: boardList, boardValue: boardList[0].id });
        }
      })
      .catch((error) => {
        if (error !== undefined) {
          this.setState({ errorMessage: error.message, isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  handleChangeClient = (SelectedOption) => {
    this.setState({
      isloading: true,
      selectedClientValue: SelectedOption.target.value,
      clientName: SelectedOption.target.selectedOptions[0].text,
    });
    getSchoolList(SelectedOption.target.value, 1)
      .then((responseSchoolList) => {
        if (responseSchoolList !== undefined) {
          this.setState({
            schoolList: responseSchoolList.rows,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message, isloading: false });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };
  addressFormatter = (cell, row) => {
    return (
      <span>
        {row.addressLine1 !== null && row.addressLine1}
        <br></br>
        {row.addressLine2 !== null && row.addressLine2}
        <br></br>
        {row.addressLine3 !== null && row.addressLine3}
      </span>
    );
  };
  handleModalShow = () => {
    let boardList = [...this.state.boardList];
    let boardId = boardList !== [] ? boardList[0].value : "";
    this.setState({
      showModalSchool: !this.state.showModalSchool,
      initialValues: {
        clientId: "",
        boardId: boardId,
        schoolName: "",
        addressLine1: "",
        addressLine2: null,
        addressLine3: null,
        city: "",
        state: "",
        pin: "",
        isActive: false,
      },
    });
  };
  handleModalHide = () => {
    this.setState({
      showModalSchool: !this.state.showModalSchool,
      initialValues: {
        clientId: "",
        boardId: "",
        schoolName: "",
        addressLine1: "",
        addressLine2: null,
        addressLine3: null,
        city: "",
        state: "",
        pin: "",
        isActive: false,
      },
      titleAddEdit: "Add",
      errorMessage: "",
    });
  };
  handChangeBoard = (SelectedOption) => {
    this.setState({ boardValue: SelectedOption.value });
  };

  addSchool = (schoolData) => {
    this.setState({ isloading: true });
    addSchool(schoolData)
      .then((responseSchoolData) => {
        if (responseSchoolData !== undefined) {
          this.getSchoolList();
          this.setState({ isloading: false, showModalSchool: false });
          toast.success(responseSchoolData.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        }
      });
  };
  showModalUPdateSchool = (rowData) => {
    this.setState({
      initialValues: rowData,
      showModalSchool: true,
      titleAddEdit: "Update",
    });
  };
  updateSchool = (schoolData, actions) => {
    this.setState({ isloading: true });
    updateSchool(schoolData.id, schoolData)
      .then((responseSchoolData) => {
        if (responseSchoolData !== undefined) {
          this.getSchoolList();
          this.setState({
            isloading: false,
            showModalSchool: false,
            initialValues: {
              clientId: "",
              boardId: "",
              schoolName: "",
              addressLine1: "",
              addressLine2: null,
              addressLine3: null,
              city: "",
              state: "",
              pin: "",
              isActive: false,
            },
          });
          toast.success("School detail updated successfully", {
            autoClose: 3000,
            hideProgressBar: true,
          });
          actions.resetForm();
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };
  getSchoolList = () => {
    this.setState({ isloading: true });
    getSchoolList(this.state.selectedClientValue)
      .then((responseSchoolList) => {
        if (responseSchoolList !== undefined) {
          this.setState({
            schoolList: responseSchoolList.rows,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        } else {
          this.setState({ errorMessage: error.message, isloading: false });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };
  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.showModalUPdateSchool(row);
      },
    };
    const columns = [
      {
        dataField: "id",
        text: "School ID",
      },
      {
        dataField: "schoolName",
        text: "School Name",
      },
      {
        dataField: "addressLine1",
        text: "Address",
        formatter: this.addressFormatter,
      },
      {
        dataField: "city",
        text: "City",
      },
      {
        dataField: "state",
        text: "State",
      },
      {
        dataField: "pin",
        text: "Pin",
      },
    ];
    return (
      <div className="container-fluid">
        <Row>
          <Col md={12}>
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row className="mb-4">
                        <Col md="4">
                          <div className="title">School List</div>
                        </Col>
                        <Col md="8">
                          <button
                            className="btn btn-primary-fill float-right mt-3"
                            onClick={this.handleModalShow}
                          >
                            {" "}
                            <FontAwesomeIcon icon={faPlus} /> &nbsp;Add School
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <select
                            className="form-control"
                            onChange={this.handleChangeClient}
                          >
                            {this.state.clientOptions !== undefined &&
                              this.state.clientOptions.map((option) => (
                                <option value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      <div>
                        <div>
                          {this.state.isloading ? (
                            <div className="custom-loader">
                              <Spinner animation="border" />
                            </div>
                          ) : (
                            <BootstrapTable
                              keyField="id"
                              key={`clientListTable`}
                              data={this.state.schoolList}
                              columns={columns}
                              pagination={paginationFactory({
                                sizePerPage: 10,
                                showTotal: true,
                                hideSizePerPage: true,
                              })}
                              rowClasses="row-hover pointer_class"
                              rowEvents={rowEvents}
                            />
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <AddEditSchool
                showModalSchool={this.state.showModalSchool}
                handleModalHide={this.handleModalHide}
                onSubmit={
                  this.state.titleAddEdit === "Update"
                    ? this.updateSchool
                    : this.addSchool
                }
                errorMessage={this.state.errorMessage}
                initialValues={this.state.initialValues}
                titleAddEdit={this.state.titleAddEdit}
                clientName={this.state.clientName}
                boardList={this.state.boardList}
                clientId={this.state.selectedClientValue}
              />
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SchoolList;
