import React, { Component } from "react";
import { Row, Col, Card, Spinner, CardBody } from "reactstrap";
import { getPracticeList } from "../../services/StudentServices";
import { Link } from "react-router-dom";
export default class PracticeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      practiceList: [],
      errorMessage: "",
      isloading: false,
      showAlert: false,
    };
  }
  componentDidMount() {
    if (this.props.location.evaluation !== undefined) {
      this.getPracticeList(this.props.location.evaluation);
    } else {
      this.props.history.push("/app/subjects/std-topic-details");
    }
  }
  getPracticeList = (evaluation) => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getPracticeList(userData.id, evaluation.schoolSectionTopicId)
      .then((responsePracticeList) => {
        if (responsePracticeList !== undefined) {
          this.setState({
            practiceList: responsePracticeList,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error in practice list ", error);
        this.setState({ showAlert: true });
        alert(
          "You must complete the assessment before you can begin the practice"
        );
        this.props.history.push("/app/subjects/std-topic-details");
      });
  };

  render() {
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row>
                        <Col md="6">
                          <div className="title">
                            <h4>Practice list</h4>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      {this.state.isloading ? (
                        <div className="custom-loader">
                          <Spinner animation="border" />
                        </div>
                      ) : (
                        <div>
                          <div>
                            {this.state.practiceList.map((practice) => {
                              return (
                                <div key={practice.id}>
                                  <br></br>
                                  {practice.status === "COMPLETED" ? (
                                    <span>
                                      {
                                        practice.schoolSectionTopic
                                          .topicDisplayTitle
                                      }
                                    </span>
                                  ) : (
                                    <Link
                                      className="view-title"
                                      to={{
                                        pathname: "practice",
                                        practice: practice,
                                      }}
                                    >
                                      {
                                        practice.schoolSectionTopic
                                          .topicDisplayTitle
                                      }
                                    </Link>
                                  )}

                                  <br></br>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}
