import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Spinner,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  getUserSchoolSections,
  addSectionClientUser,
} from "../services/UserServices";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import trimObj from "../components/common/TrimData";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
export default class MyClasses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSectionsList: [],
      errorMessage: "",
      isloading: false,
      showSectionModal: false,
      registrationCode: "",
    };
  }
  componentDidMount() {
    this.getUserSectionList();
  }
  getUserSectionList = () => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getUserSchoolSections(userData.id)
      .then((responseUserSections) => {
        if (
          responseUserSections !== undefined &&
          responseUserSections.length > 0 &&
          responseUserSections[0]["userRoles"] !== undefined &&
          responseUserSections[0]["userRoles"][0]["userRolesSchoolSections"] !==
            undefined
        ) {
          let userSectionList = [];
          responseUserSections[0]["userRoles"][0][
            "userRolesSchoolSections"
          ].map((userSection) => {
            let data = {};
            data["subject"] =
              userSection.schoolSection.boardGradeSubject.subject.subject;
            data["grade"] =
              userSection.schoolSection.boardGradeSubject.grade.grade;
            data["sectionName"] = userSection.schoolSection.sectionName;
            data["schoolSectionId"] = userSection.schoolSectionId;
            data["teacherRegistrationCode"] =
              userSection.schoolSection.teacherRegistrationCode;
            data["studentRegistrationCode"] =
              userSection.schoolSection.studentRegistrationCode;

            userSectionList.push(data);
          });
          this.setState({
            isloading: false,
            userSectionsList: userSectionList,
          });
        } else {
          this.setState({ isloading: false, userSectionsList: [] });
        }
      })
      .catch((error) => {
        console.log("error in section my class list", error);
        if (error !== undefined) {
          this.setState({ errorMessage: error.message, isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  handleSectionModal = () => {
    this.setState({ showSectionModal: !this.state.showSectionModal });
  };
  addUserSection = () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let index = userData.userRoles.findIndex(
      (role) => role.roleId == 3 || role.roleId == 4
    );
    let data = {
      userId: userData.id,
      registrationCode: this.state.registrationCode,
      roleId: userData.userRoles[index]["roleId"],
    };
    this.setState({ isDisable: true });
    // this function used to trim string before and after
    data = trimObj(data);
    addSectionClientUser(data)
      .then((responseClientUserData) => {
        if (responseClientUserData !== undefined) {
          this.setState({
            showSectionModal: false,
            isDisable: false,
            registrationCode: "",
          });
          toast.success("Section Added Successfully", {
            autoClose: 3000,
            hideProgressBar: true,
          });
          this.getUserSections(responseClientUserData.id);
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            formLoading: false,
            isDisable: false,
          });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 5000);
      });
  };
  handChangeRegistration = (event) => {
    this.setState({ registrationCode: event.target.value });
  };
  render() {
    const columns = [
      {
        dataField: "schoolSectionId",
        text: "School Section ID",
      },
      {
        dataField: "grade",
        text: "Grade",
      },
      {
        dataField: "sectionName",
        text: "Section Name",
      },
      {
        dataField: "teacherRegistrationCode",
        text: "Teacher Registration Code",
      },
      {
        dataField: "studentRegistrationCode",
        text: "Student Registration Code",
      },
    ];
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row>
                        <Col md="6">
                          <div className="title">
                            <h4>My Class Sections</h4>
                          </div>
                        </Col>
                        <Col md="6">
                          <button
                            className="btn btn-primary-fill float-right mt-3"
                            onClick={this.handleSectionModal}
                          >
                            <FontAwesomeIcon icon={faPlus} /> &nbsp;Add Section{" "}
                          </button>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      <div>
                        {this.state.isloading ? (
                          <div className="custom-loader">
                            <Spinner animation="border" />
                          </div>
                        ) : (
                          <div>
                            <BootstrapTable
                              keyField="id"
                              key={`clientListTable`}
                              data={this.state.userSectionsList}
                              columns={columns}
                              pagination={paginationFactory({
                                sizePerPage: 10,
                                showTotal: true,
                                hideSizePerPage: true,
                              })}
                              rowClasses="row-hover pointer_class"
                            />
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
        <Modal isOpen={this.state.showSectionModal} size="md">
          <ModalHeader>Add Section</ModalHeader>
          <ModalBody>
            <div className="form-group form-gp">
              {this.state.errorMessage !== "" ? (
                <p className="alert alert-danger">{this.state.errorMessage}</p>
              ) : (
                ""
              )}
            </div>
            <div className="form-group form-gp">
              <Row>
                <Col md="4">
                  <label>Registration Code</label>
                </Col>
                <Col md="8">
                  <input
                    type="text"
                    name="registrationCode"
                    autoComplete="off"
                    value={this.state.registrationCode}
                    className="form-control"
                    placeholder="Registration Code"
                    onChange={this.handChangeRegistration}
                  />
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-secondary mr-2"
              onClick={this.handleSectionModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary-fill"
              onClick={this.addUserSection}
              disabled={this.state.isDisable}
            >
              Add{" "}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
