import React, { Component } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import { chunk, uniqBy, filter, find } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSync } from "@fortawesome/free-solid-svg-icons";
import {
//  questionList,
  QuestionType,
  addQuestion,
  addToTopic,
  getByTopic,
  addEvaluation,
  resetEvaluation,
} from "../../services/QuestionServices";
import { topicListNoDesc, getAllLevelforTopic } from "../../services/TopicServices";
import AddQuestion from "./AddQuestion";
import AssignTopicQuestion from "./AssignTopicQuestion";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

class TopicQuestionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      QuestionList: [],
      assignQuestionList: [],
      isloading: false,
      showModalAddQuestion: false,
      showModalEditQuestion: false,
      errorMessage: "",
      questionType: [],
      initialValues: {
        questionText: "",
        answerExplanation: "",
        isActive: false,
        questionTypeId: "",
        answers: [
          { optionText: "", isCorrectOption: false },
          { optionText: "", isCorrectOption: false },
        ],
      },
      modalAssignTopic: false,
      initialValuesAssignTopic: {
        topicId: "",
        questionId: "",
      },
      topicList: [],
      topicLevel: [],
      topicId: "",
      topicTitle: "",
      assignLevel: "",
      enableLevel: true,
      buttonsDisabled: true,
    };
  }
  componentDidMount() {
    this.getTopicList();
    this.questionType();
    this.setState({
      isloading: false,
    });
   // this.getQuestionList();
  }

  questionType = () => {
    this.setState({ isloading: true });
    QuestionType()
      .then((response) => {
        if (response !== undefined) {
          // console.log("question Type", response);
          this.setState({
            questionType: response,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ isloading: false });
      });
  };

  // this function is used to show buffer data
  bufferFromBufferString = (data) => {
    let utf8decoder = new TextDecoder();
    let u16arr = new Uint8Array(data);
    let dataPrepare = utf8decoder.decode(u16arr);
    return dataPrepare;
  };

  showModalAddQuestion = (e) => {
    e.preventDefault();
    this.setState({
      showModalAddQuestion: true,
    });
  };

  handleModalHide = () => {
    this.setState({
      showModalAddQuestion: false,
    });
  };

  addQuestion = (data) => {
    // console.log("Add Question Data", data);
    this.setState({ isloading: true });
    addQuestion(data)
      .then((response) => {
        if (response !== undefined) {
          // console.log("response", response);
          this.setState({
            isloading: false,
            showModalAddQuestion: false,
            errorMessage: "",
          });
          toast.success("Question added successfully.", {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            isloading: false,
          });
        } else {
          this.setState({
            errorMessage: error.message,
            isloading: false,
          });
        }
      });
  };

  getTopicList = () => {
    topicListNoDesc()
      .then((response) => {
        if (response !== undefined) {
          const uniqRows = uniqBy([...response.rows], "topicTitle");
          this.setState({
            topicList: uniqRows,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

/*  getQuestionList = () => {
    this.setState({ isloading: true });
    questionList()
      .then((response) => {
        if (response !== undefined) {
          console.log("QuestionList", response.rows);
          this.setState({
            isloading: false,
            assignQuestionList: response.rows,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ isloading: false });
      });
  };
*/

  handelChangeTopic = (e) => {
   // console.log("Topic ID", e.target.value);
    const getValue = e.target.value;
    if (getValue !== "") {
      const topicList = [...this.state.topicList];
      const foundObject = topicList.filter((x) => x.id == getValue);
      this.getTopicLevel(foundObject[0].topicTitle);
      this.setState({
        buttonsDisabled: true,
        topicTitle: foundObject[0].topicTitle,
      });
    } else {
      this.setState({
        isloading: false,
        QuestionList: [],
        topicId: "",
        buttonsDisabled: true,
        enableLevel: true,
      });
    }
  };

  handelChangeLevel = (e) => {
    const getValue = e.target.value;
    // console.log("getValue", getValue);
    if (getValue !== "") {
      getByTopic(getValue)
        .then((response) => {
          if (response !== undefined) {
            // console.log("Question Topic List", response);
            const topicLevel = [...this.state.topicLevel];
            const foundObject = topicLevel.filter((x) => x.id == getValue);
            // console.log("foundObject", foundObject);
            this.setState({
              QuestionList: response.rows,
              isloading: false,
              buttonsDisabled: false,
              topicId: getValue,
              assignLevel: foundObject[0].level,
              // assignQuestionList: response.rows,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      this.setState({
        buttonsDisabled: true,
        QuestionList: [],
      });
    }
  };

  questionListLevel = () => {
    const topicId = this.state.topicId;
    getByTopic(topicId)
      .then((response) => {
        if (response !== undefined) {
          // console.log("foundObject", foundObject);
          this.setState({
            QuestionList: response.rows,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  getTopicLevel = (topicName) => {
    getAllLevelforTopic(topicName)
      .then((response) => {
        if (response !== undefined) {
          //console.log("Response getAllLevelforTopic", response);
          this.setState({
            topicLevel: response.rows,
            enableLevel: false,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ isloading: false });
      });
  };

  handelCreateEvaluations = () => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure you want to create evaluations?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.createEvaluations();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  createEvaluations = () => {
    // console.log("createEvaluations");
    const data = {
      topicId: this.state.topicId,
    };
    // console.log("Prepare Data ", data);
    addEvaluation(data)
      .then((response) => {
        if (response !== undefined) {
          //console.log("Response Add Evaluation ", response);
          toast.success(response.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ isloading: false });
      });
  };

  handelResetEvaluations = () => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure you want to Reset evaluations?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.resetEvaluations();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  resetEvaluations = () => {
    //console.log("createEvaluations");
    const data = {
      topicId: this.state.topicId,
    };
    //console.log("Prepare Data ", data);
    resetEvaluation(data)
      .then((response) => {
        if (response !== undefined) {
          //console.log("Response Reset Evaluation ", response);
          toast.success(response.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ isloading: false });
      });
  };

  showModalAssignTopic = () => {
    this.setState({
      modalAssignTopic: true,
      initialValuesAssignTopic: {
        topicId: this.state.topicId,
      },
    });
  };

  hideModalAssignTopic = () => {
    this.setState({
      modalAssignTopic: false,
      errorMessage: "",
    });
  };

  assignTopic = (data) => {
    this.setState({ isloading: true });
    addToTopic(data)
      .then((response) => {
        if (response !== undefined) {
          // console.log("addToTopic response", response);
          this.setState({
            isloading: false,
            errorMessage: "",
            modalAssignTopic: false,
          });
          toast.success(response.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          this.questionListLevel();
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            isloading: false,
          });
        } else {
          this.setState({
            errorMessage: error.message,
            isloading: false,
          });
        }
      });
  };

  render() {
    const columns = [
      {
        dataField: "questionText",
        text: "Question Text",
        formatter: (cell, row) => {
          return React.createElement("div", {
            dangerouslySetInnerHTML: { __html: this.bufferFromBufferString(cell.data) },
          });
        },
      },
      {
        dataField: "questionType.questionType",
        text: "Question Type",
      },
      {
        dataField: "answerExplanation",
        text: "Answer Explanation",
        formatter: (cell, row) => {
          return React.createElement("div", {
            dangerouslySetInnerHTML: { __html: this.bufferFromBufferString(cell.data) },
          });
        },
      },
      {
        dataField: "evaluations",
        text: "Evaluation Type",
        formatter: (cell, row) => {
          // console.log("evaluationType", cell, row);
          return (
            <>
              {cell.map((data, index) => {
                // console.log("map inner", data.evaluationType.evaluationType);
                return <span>{data.evaluationType.evaluationType}</span>;
              })}
            </>
          );
        },
      },
    ];
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row className="mb-4">
                        <Col md="2">
                          <div className="title">Topic Question</div>
                        </Col>
                        <Col md="10">
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="addQuestion">
                              <button
                                className="btn btn-primary-fill float-right mt-3"
                                disabled={this.state.buttonsDisabled}
                                onClick={this.handelResetEvaluations}
                              >
                                <FontAwesomeIcon icon={faSync} />
                                &nbsp; Reset Evaluations
                              </button>
                              <button
                                className="btn btn-primary-fill float-right mt-3 mr-2"
                                disabled={this.state.buttonsDisabled}
                                onClick={this.handelCreateEvaluations}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                                &nbsp; Create Evaluations
                              </button>
                              <button
                                className="btn btn-primary-fill float-right mt-3 mr-2"
                                disabled={this.state.buttonsDisabled}
                                onClick={this.showModalAssignTopic}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                                &nbsp; Assign Question to Topic
                              </button>
                              <button
                                className="btn btn-primary-fill float-right mt-3 mr-2"
                                onClick={this.showModalAddQuestion}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                                &nbsp; Add Question
                              </button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <div className="filterDataQuestion">
                            <Row>
                              <Col md="3">
                                <div className="form-group form-gp">
                                  <select
                                    name="topicId"
                                    className="form-control"
                                    onChange={(e) => this.handelChangeTopic(e)}
                                  >
                                    <option value="">Select Topic</option>
                                    {this.state.topicList.map((type) => {
                                      return (
                                        <option key={type.id} value={type.id}>
                                          {type.topicTitle}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="form-group form-gp">
                                  <select
                                    name="levelId"
                                    className="form-control"
                                    onChange={(e) => this.handelChangeLevel(e)}
                                    disabled={this.state.enableLevel}
                                  >
                                    <option value="">Select Level</option>
                                    {this.state.topicLevel.map((level) => {
                                      // console.log(level);
                                      return (
                                        <option key={level.id} value={level.id}>
                                          {"Level " + level.level}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="customCardBodyTable">
                      {this.state.isloading ? (
                        <div className="custom-loader">
                          <Spinner animation="border" />
                        </div>
                      ) : (
                          <BootstrapTable
                            keyField="id"
                            key={`topicQuestionListTable`}
                            data={this.state.QuestionList}
                            noDataIndication={() => <div>No Data Available</div>}
                            columns={columns}
                            pagination={paginationFactory({
                              sizePerPage: 10,
                              showTotal: true,
                              hideSizePerPage: true,
                            })}
                            rowClasses="row-hover"
                          />
                        )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {this.state.showModalAddQuestion ? (
                <AddQuestion
                  showModalAddQuestion={this.state.showModalAddQuestion}
                  handleModalHide={this.handleModalHide}
                  onSubmit={this.addQuestion}
                  errorMessage={this.state.errorMessage}
                  initialValues={this.state.initialValues}
                  questionType={this.state.questionType}
                />
              ) : null}

              {this.state.modalAssignTopic ? (
                <AssignTopicQuestion
                  modalAssignTopic={this.state.modalAssignTopic}
                  handleModalHide={this.hideModalAssignTopic}
                  onSubmit={this.assignTopic}
                  errorMessage={this.state.errorMessage}
                  initialValues={this.state.initialValuesAssignTopic}
                  topicName={this.state.topicTitle}
                  assignLevel={this.state.assignLevel}
                  questionList={this.state.assignQuestionList}
                />
              ) : null}
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}

export default TopicQuestionList;
