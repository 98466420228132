import React, { Component } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import {
  getGradesList,
  getGradesSummary,
  getGradesByTopic,
} from "../services/TeacherServices";
import { getUserSchoolSections } from "../services/UserServices";
import { Doughnut } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import html2canvas from "html2canvas";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
// import autoTable from 'jspdf-autotable'
import jsPDF from "jspdf";
import "jspdf-autotable";
import { format } from "date-fns";

export default class TeacherGrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardList: [],
      errorMessage: "",
      isloading: false,
      userSectionsList: [],
      selectedSection: "",
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Rainfall",
            backgroundColor: [
              "rgba(4, 72, 9, 1)",
              "rgba(250, 203, 48, 1)",
              "rgba(247, 2, 46, 1)",
              ,
            ],
            borderColor: "rgba(0,0,0,1)",
            borderWidth: 2,
            data: [],
          },
        ],
      },
      studentList: [],
      isPdfDisable:false,
    };
  }
  componentDidMount() {
    this.getUserSectionList();
  }


  getUserSectionList = () => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getUserSchoolSections(userData.id)
      .then((responseUserSections) => {
        if (
          responseUserSections !== undefined &&
          responseUserSections.length > 0 &&
          responseUserSections[0]["userRoles"] !== undefined &&
          responseUserSections[0]["userRoles"][0]["userRolesSchoolSections"] !==
            undefined
        ) {
          var userSectionList = [];
          responseUserSections[0]["userRoles"][0][
            "userRolesSchoolSections"
          ].map((userSection) => {
            let data = {};
            data["label"] =
              userSection.schoolSection.boardGradeSubject.grade.grade +
              " Grade " +
              userSection.schoolSection.boardGradeSubject.subject.subject +
              " " +
              userSection.schoolSection.sectionName;
            data["value"] = userSection.schoolSectionId;
            userSectionList.push(data);
          });
          this.setState({ userSectionsList: userSectionList });
          if (userSectionList.length > 0) {
            this.setState({ selectedSection: userSectionList[0].value });
            return getGradesList(userSectionList[0].value);
          }
        } else {
          this.setState({ isloading: false, userSectionsList: [] });
        }
      })
      .then((response) => {
        if (response !== undefined) {
          this.setState({ studentList: response, isloading: false });
          if (this.state.userSectionsList.length > 0) {
            return getGradesSummary(this.state.userSectionsList[0].value);
          }
        }
      })
      .then((responseSummary) => {
        if (responseSummary !== undefined) {
          let labels = [];

          let chartValues = [];
          responseSummary.map((data) => {
            labels.push(Object.keys(data)[0]);
            chartValues.push(Object.values(data)[0]);
          });

          let chartData = { ...this.state.chartData };
          chartData["labels"] = labels;
          chartData["datasets"][0]["data"] = chartValues;
          console.log("chartData", chartData);

          this.setState({ labels: labels, chartData: chartData });
        }
      })
      .catch((error) => {
        console.log("error in section announcement list", error);
        if (error !== undefined) {
          this.setState({ isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  getStudentListBySection = (sectionId) => {
    this.setState({ isloading: true });
    getGradesList(sectionId)
      .then((response) => {
        if (response !== undefined) {
          this.setState({ studentList: response, isloading: false });
          return getGradesSummary(sectionId);
        }
      })
      .then((responseSummary) => {
        if (responseSummary !== undefined) {
          let labels = [];

          let chartValues = [];
          responseSummary.map((data) => {
            labels.push(Object.keys(data)[0]);
            chartValues.push(Object.values(data)[0]);
          });

          let chartData = { ...this.state.chartData };
          chartData["labels"] = labels;
          chartData["datasets"][0]["data"] = chartValues;
          console.log("chartData", chartData);

          this.setState({ labels: labels, chartData: chartData });
        }
      })
      .catch((error) => {
        console.log("error in student list", error);
        if (error !== undefined) {
          this.setState({ errorMessage: error.message, isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  handleChangeSection = (event) => {
    this.setState({ selectedSection: event.target.value });
    this.getStudentListBySection(event.target.value);
  };

  downloadPdf = (e) => {
    window.scrollTo(0, 0)
    this.setState({isPdfDisable:true})
    getGradesByTopic(this.state.selectedSection)
      .then((responseQuizList) => {
        if (responseQuizList !== undefined) {
          this.genratePdf(responseQuizList);
        }
      })
      .catch((error) => {
        console.log("error in DownLoad Pdf", error);
      });
  };
  nameFormatter = (cell, rows) => {
    return <span>{cell.firstName + " " + cell.lastName}</span>;
  };
  genratePdf = (responseQuizList) => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let input = window.document.getElementsByClassName("doughnut-chart")[0];

    html2canvas(input).then((canvas) => {
      const img = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", "letter");
      const tableRows = [];
      const tableColumn = ["Topic Name", "Student Name", "Quiz Score", "Date"];
      const studentRows = [];
      const studentColumn = ["Student Name", "Quiz Average"];
      let firstRow = userData.school.schoolName;
      let SectionName = this.state.userSectionsList.find(
        (section) => section.value == this.state.selectedSection
      );
      let teacherName = userData.firstName + " " + userData.lastName;
      pdf.text(20, 20, firstRow);
      let dateRow = "Date Created: " + format(new Date(), "MM-dd-yyyy");
      pdf.text(20, 40, "Class Report");
      pdf.text(20, 60, teacherName);
      SectionName !== undefined && pdf.text(20, 80, SectionName.label);
      pdf.text(20, 100, dateRow);
      pdf.addImage(img, "png", 40, 120,);
      this.state.studentList.forEach((student) => {
        const StudentRow = [
          student.user.firstName + " " + student.user.lastName,
          student.AverageScore,
        ];
        // push each student's info into a row
        studentRows.push(StudentRow);
      });

      responseQuizList.forEach((topic) => {
        const rowData = [
          topic.schoolSectionTopicDisplayTitle,
          topic.user.firstName + " " + topic.user.lastName,
          topic.totalScore,
          // called date-fns to format the date on the ticket
          format(new Date(topic.updatedAt), "MM-dd-yyyy"),
        ];
        // push each student's info into a row
        tableRows.push(rowData);
      });
      // pdf.addPage()
      pdf.text(10, 420, "Class Quiz Average.");
      pdf.autoTable(studentColumn, studentRows, { startY: 440 });
      pdf.text(10, pdf.lastAutoTable.finalY + 30, "Class Details.");
      pdf.autoTable(tableColumn, tableRows, {
        startY: pdf.lastAutoTable.finalY + 50,
      });
      let pdfName = "Grades_" + format(new Date(), "_MM_dd_yyyy_HH:mm:ss");
      pdf.save(pdfName);
      this.setState({isPdfDisable:false})
    });
  };
  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        let toDetails = {
          pathname: `/app/grade/grade-details/${row.schoolSectionId}/${row.studentId}`,
          studentDetails: {
            schoolSectionId: row.schoolSectionId,
            studentId: row.studentId,
          },
        };
        this.props.history.push(toDetails);
      },
    };
    const columns = [
      {
        dataField: "user",
        text: "Student Name",
        sort: true,
        formatter: this.nameFormatter,
        headerStyle: {
          width: "3s%",
        },
      },
      {
        dataField: "AverageScore",
        text: "Quiz Average",
        sort: true,
        // formatter: this.assignmentFormatter,
        align: "right",
      },
    ];
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box ">
                    <div className="card-header">
                      <Row>
                        <Col md="6">
                          <div className="title">
                            <h4>Grades</h4>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      <div>
                        {this.state.isloading ? (
                          <div className="custom-loader">
                            <Spinner animation="border" />
                          </div>
                        ) : (
                          <div>
                            <Row className="mb-5">
                              <Col md="3">
                                <label> Select Section</label>
                              </Col>
                              <Col md="5">
                                <select
                                  className="form-control"
                                  onChange={this.handleChangeSection}
                                  value={this.state.selectedSection}
                                >
                                  {this.state.userSectionsList !== undefined &&
                                    this.state.userSectionsList.map(
                                      (option) => (
                                        <option
                                          value={option.value}
                                          key={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      )
                                    )}
                                </select>
                              </Col>
                              <Col md="4">
                                <button
                                  className="btn btn-primary-fill float-right "
                                  onClick={(e) => this.downloadPdf(e)}
                                  disabled={this.state.isPdfDisable}
                                >
                                  <FontAwesomeIcon icon={faFileDownload} />{" "}
                                  &nbsp;{this.state.isPdfDisable?'downloading...':'Download PDF Report'}
                                </button>
                              </Col>
                            </Row>
                            <div>
                              <Row className="mt-2">
                                <Col md="5">
                                  <div className="title">
                                    <h5 className=" title-border">
                                      Student View
                                    </h5>
                                  </div>
                                  <div className="curriculum-table">
                                    {this.state.isloading ? (
                                      <div className="custom-loader">
                                        <Spinner animation="border" />
                                      </div>
                                    ) : (
                                      <div>
                                        <BootstrapTable
                                          keyField="id"
                                          key={`studentListTable`}
                                          data={this.state.studentList}
                                          columns={columns}
                                          pagination={paginationFactory({
                                            sizePerPage: 200,
                                            showTotal: true,
                                            hideSizePerPage: true,
                                          })}
                                          rowClasses="row-hover pointer_class"
                                          classes="custom-table-border"
                                          rowEvents={rowEvents}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </Col>
                                <Col md="7" className="doughnut-chart">
                                  <Doughnut
                                    data={this.state.chartData}
									
                                    options={{
                                      title: {
                                        display: true,

                                        fontSize: 20,
                                      },
                                      legend: {
                                        display: true,
                                        position: "right",
                                      },
									  maintainAspectRatio: false
                                    }}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}
