import React, { Component } from "react";
import { Row, Col, Card, Spinner } from "reactstrap";
import Logo from "../assets/images/logo.png";
import { Formik } from "formik";
import { getAuthRedirectUrl } from "../utils/General";
import * as Yup from "yup";
import { login } from "../services/AuthServices";
class Login extends Component {
  state = {
    username: '',
    password: '',
    formErrors: { username: '', password: '' },
    usernameValid: false,
    passwordValid: false,
    formValid: false,
    loggedIn: '',
    error: '',
    isloading: false
  }
  onSubmit = (user) => {
    this.setState({ isloading: true })
    login(user).then(result => {
      if (result !== undefined && result.token !== undefined) {
        localStorage.setItem('token', result.token);
        localStorage.setItem('user', JSON.stringify(result));
        let roleIds = [];
        let defaultRole = [];
        result.userRoles.map(user => {
          roleIds.push(user.roleId);
          if (user.defaultRole === true)
            defaultRole.push(user.roleId);
        })
        localStorage.setItem('defaultRole', JSON.stringify(defaultRole));
        localStorage.setItem('roleIds', JSON.stringify(roleIds));
        this.setState({ isloading: false })
        this.props.history.push(getAuthRedirectUrl());
      }
      else {
        this.setState({ error: result.message, isloading: false })
      }

    }).catch(error => {
      console.log('error', error)
      if (error !== undefined) {
        this.setState({ error: error.data.message, isloading: false })

      }

    });
  }

  componentDidMount() {
    if (localStorage.getItem('token') !== null) {
      this.props.history.push(getAuthRedirectUrl());
    }
  };

  componentDidMount() {
    if (localStorage.getItem("token") !== null) {
      this.props.history.push(getAuthRedirectUrl());
    }
  }

  render() {
    return (
      <section>
        <div className="container">
          <Row>
            <Col lg="5" md="7" className="mx-auto mt-5">
              <Card className="card-box p-4">
                <div className="card-header text-center">
                  <img
                    src={Logo}
                    className="img-fluid"
                    width="75%"
                    alt="logo"
                  />
                </div>
                <div className="card-body">
                  {this.state.isloading ? (
                    <div className="custom-loader">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                      <Formik
                        initialValues={{
                          username: "",
                          password: "",
                        }}
                        onSubmit={(values) => {
                          this.onSubmit(values);
                        }}
                        validationSchema={Yup.object().shape({
                          username: Yup.string().required("Required"),
                          password: Yup.string().required(
                            "No password provided."
                          ),
                        })}
                      >
                        {(props) => {
                          const {
                            errors,
                            handleChange,
                            handleSubmit,
                            handleBlur,
                            touched,
                          } = props;
                          return (
                            <form onSubmit={handleSubmit}>
                              {this.state.error !== "" ? (
                                <p className="alert alert-danger">
                                  {this.state.error}
                                </p>
                              ) : (
                                  ""
                                )}
                              <div className={`form-group`}>
                                <label>Username</label>
                                <input
                                  type="text"
                                  name="username"
                                  onBlur={handleBlur}
                                  className={
                                    errors.username && touched.username
                                      ? "form-control error"
                                      : "form-control"
                                  }
                                  placeholder=""
                                  onChange={handleChange}
                                />
                                {errors.username && touched.username && (
                                  <div className="input-feedback">
                                    {errors.username}
                                  </div>
                                )}
                              </div>
                              <div className={`form-group`}>
                                <label>Password</label>
                                <input
                                  type="password"
                                  name="password"
                                  onBlur={handleBlur}
                                  className={
                                    errors.username && touched.username
                                      ? "form-control error"
                                      : "form-control"
                                  }
                                  placeholder=""
                                  onChange={handleChange}
                                  autoComplete="on"
                                />
                                {errors.password && touched.password && (
                                  <div className="input-feedback">
                                    {errors.password}
                                  </div>
                                )}
                              </div>
                              <button
                                type="submit"
                                className="btn btn-primary-fill d-block text-center mt-5 w-100"
                              >
                                Login
                            </button>
                            </form>
                          );
                        }}
                      </Formik>
                    )}
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}

export default Login;
