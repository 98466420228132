import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Spinner } from 'reactstrap'
import { getStudentId } from "../../services/StudentServices";

class StudentDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            studentId: [],
            errorMessage: "",
            isLoading: false
        }
    }
    componentDidMount() {
        this.getStudId();
    }
    getStudId = () => {
        this.setState({ isLoading: true });
        let getStudId = JSON.parse(localStorage.getItem('user'))
        getStudentId(getStudId.id)
            .then((responseStudId) => {
               
                if (responseStudId !== undefined) {
                    //console.log("responseStudId", responseStudId);
                    this.setState({
                        studentId: responseStudId,
                        isloading: false,
                    });
                }
            })
            .catch((error) => {
                console.log("error in dashboard student", error);

            });
    }
    handleClick = (path) => {
        let lowerPath = path.toLowerCase()

        if (lowerPath === 'direct-message') {
            this.props.history.push('ask-your-teacher')
        }
        else {
            this.props.history.push('subjects')
        }

    }
    render() {
        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <Col lg="12" md="12">
                            <Card className="card-box">
                                <div className="card-header">
                                    <Row>
                                        <Col md='12'>
                                            <div className="title"><h4>Dashboard</h4></div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody>
                                    <div>
                                        {this.state.isloading
                                            ?
                                            <div className="custom-loader"><Spinner animation="border" />
                                            </div>
                                            :
                                            <Row>
                                                {this.state.studentId !== undefined && this.state.studentId.length > 0
                                                    &&
                                                    this.state.studentId.map((StudDasboardItem, index) => {
                                                   
                                                        return (
                                                            <Col md='3' key={index}>
                                                                <Card style={{ height: '10rem' }} onClick={() => this.handleClick(Object.keys(StudDasboardItem)[0].replace(' ', '-'))} className='pointer_class clickable-card card-box'>
                                                                    <CardBody>
                                                                        <CardTitle className='text-center'>
                                                                            <h4 className="card-title">{Object.keys(StudDasboardItem)[0]}</h4>
                                                                            {/* <h2> <FontAwesomeIcon icon={faComments} /> </h2>	 */}
                                                                        </CardTitle>
                                                                        <CardText className='text-center'>
                                                                            {StudDasboardItem[Object.keys(StudDasboardItem)[0]]}
                                                                        </CardText>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        )
                                                    })
                                                }

                                            </Row>
                                        }
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default StudentDashboard;