import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Spinner,
} from "reactstrap";
import {
  getUsersBySection,
  sendMessageToStudent,
  getLineChart,
  getTopicList,
  getBarChart,
  getGradesTopicDetails,
} from "../services/TeacherServices";
import { getUserSchoolSections } from "../services/UserServices";
import { Bar, Line } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import SendMessage from "./SendMessage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { renderToString } from "react-dom/server";
import StudentEvaluationDetails from "../student/grade/StudentEvaluationDetails";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { getEvaluationQuestions } from "../services/StudentServices";
import Moment from "react-moment";
import { format } from "date-fns";
toast.configure();
export default class TeacherGradeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardList: [],
      errorMessage: "",
      isloading: false,
      userSectionsList: [],
      selectedSection: "",
      topicList: [],
      chapterList: [{ label: "Select Student", value: "" }],
      userSectionsList: [],
      barChartData: {
        labels: [
          "Assessment",
          "Practice 1",
          "Practice 2",
          "Practice 3",
          "Practice 4",
          "Practice 5",
          "Quiz",
        ],
        datasets: [
          {
            label: "Score",
            backgroundColor: "rgba(75,192,192,1)",
            borderColor: "rgba(0,0,0,1)",
            borderWidth: 2,
            data: [65, 59, 80, 81, 56, 23, 53],
          },
        ],
      },
      lineChartData: {
        labels: [],
        datasets: [
          {
            label: "# of Quiz",
            data: [],
            fill: false,
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgba(255, 99, 132, 0.2)",
          },
        ],
      },
      initialValues: {
        senderId: "",
        messageSubject: "",
        message: "",
        receiverId: "",
        schoolSectionId: "",
      },
      allStudentsList: [],
      showModalMessage: false,
      isDisable: false,
      selectedSection: "",
      selectedStudent: "",
      selectedTopic: "",
      showBarGraph: false,
      isPdfDisable:false,
      showModalEvaluation:false,
      questionList:[],
      topicData: [],
      tittleModal:''
    };
  }
  componentDidMount() {
    let studentDetails = {};
    if (this.props.location.studentDetails !== undefined) {
      localStorage.setItem(
        "studentDetails",
        JSON.stringify(this.props.location.studentDetails)
      );
      studentDetails = this.props.location.studentDetails;
      this.setState(
        {
          selectedSection: studentDetails.schoolSectionId,
          selectedStudent: studentDetails.studentId,
        },
        () => this.getUserSectionList()
      );
    } else {
      studentDetails = localStorage.getItem("studentDetails");
      if (studentDetails) studentDetails = JSON.parse(studentDetails);
      this.setState(
        {
          selectedSection: studentDetails.schoolSectionId,
          selectedStudent: studentDetails.studentId,
        },
        () => this.getUserSectionList()
      );
    }
  
  }

  getUserSectionList = () => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getUserSchoolSections(userData.id)
      .then((responseUserSections) => {
        if (
          responseUserSections !== undefined &&
          responseUserSections.length > 0 &&
          responseUserSections[0]["userRoles"] !== undefined &&
          responseUserSections[0]["userRoles"][0]["userRolesSchoolSections"] !==
            undefined
        ) {
          var userSectionList = [];
          responseUserSections[0]["userRoles"][0][
            "userRolesSchoolSections"
          ].map((userSection) => {
            let data = {};
            data["label"] =
              userSection.schoolSection.boardGradeSubject.grade.grade +
              " Grade " +
              userSection.schoolSection.boardGradeSubject.subject.subject +
              " " +
              userSection.schoolSection.sectionName;
            data["value"] = userSection.schoolSectionId;
            userSectionList.push(data);
          });
          this.setState({
            userSectionsList: userSectionList,
           
          });
          return getUsersBySection(this.state.selectedSection);
        } else {
          this.setState({ isloading: false, userSectionsList: [] });
        }
      })
      .then((response) => {
        let allStudentsOptions = [];
        response.rows
          .filter((user) => user.userRoles.some((role) => role.roleId == 4))
          .map((student) => {
            allStudentsOptions.push({
              label: student.firstName + " " + student.lastName,
              value: student.id,
            });
          });

        this.setState({
          allStudentsList: allStudentsOptions,
        });
        return getLineChart(
          this.state.selectedSection,
          this.state.selectedStudent
        );
      })
      .then((responseChart) => {
        if (responseChart !== undefined) {
          let labels = [];
          let lineData = [];
          responseChart.map((data) => {
            labels.push(data.schoolSectionTopicDisplayTitle);
            lineData.push(data.percentScore);
          });

          let lineChartData = { ...this.state.lineChartData };
          lineChartData["labels"] = labels;
          lineChartData["datasets"][0]["data"] = lineData;
          this.setState({ lineChartData: lineChartData, isloading: false, });
          return getTopicList(this.state.selectedSection);
        }
      })
      .then((responseTopic) => {
        let topicList = [{ label: "All Topic", value: "" }];
        if (responseTopic !== undefined) {
          responseTopic.map((topic) => {
            topicList.push({ label: topic.topicDisplayTitle, value: topic.id });
          });
          this.setState({ topicList: topicList });
          this.getTopicData(
            this.state.selectedSection,
            this.state.selectedStudent
          );
        }
      })
      .catch((error) => {
        console.log("error in section list", error);
        this.setState({ topicList: [], isloading: false });
      });
  };
  handleChangeSection = (event) => {
    this.setState({
      selectedSection: event.target.value,
      showBarGraph: "",
      selectedTopic: "",
    });
    this.getUserSectionList();
  };
  downloadPdf = (e) => {
    window.scrollTo(0, 0)
    this.setState({isPdfDisable:true})
    getGradesTopicDetails(
      this.state.selectedSection,
      this.state.selectedStudent
    )
      .then((responseData) => {
        if (responseData !== undefined) {
          this.genratePdf(responseData);
        }
      })
      .catch((error) => {
        console.log("error in DownLoad Pdf", error);
      });
  };
  handleModalShow = () => {
    let initialValues = { ...this.state.initialValues };
    let userData = JSON.parse(localStorage.getItem("user"));
    initialValues.senderId = userData.id;
    initialValues.receiverId = this.state.selectedStudent;
    initialValues.schoolSectionId = this.state.selectedSection;

    getUsersBySection(this.state.selectedSection).then((response) => {
      let allStudentsOptions = [];
      response.rows
        .filter((user) => user.userRoles.some((role) => role.roleId == 4))
        .map((student) => {
          allStudentsOptions.push({
            label: student.firstName + " " + student.lastName,
            value: student.id,
          });
        });

      this.setState({ allStudentsList: allStudentsOptions, isloading: false });
    });

    this.setState({
      initialValues: initialValues,
      showModalMessage: true,
      previousMessageList: [],
    });
  };
  handleModalHide = () => {
    this.setState({
      initialValues: {
        senderId: "",
        messageSubject: "",
        message: "",
        receiverId: "",
      },
      showModalMessage: false,
    });
  };
  sendMessage = (messageData, actions) => {
    this.setState({ isloading: true });
    sendMessageToStudent(messageData)
      .then((responseMessage) => {
        if (responseMessage !== undefined) {
          console.log("responseMessage", responseMessage);
          this.setState({ isloading: false, showModalMessage: false });
          toast.success(responseMessage.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });

          actions.resetForm();
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };
  handleChangeStudent = (event) => {
    this.setState({
      selectedStudent: event.target.value,
      showBarGraph: "",
      selectedTopic: "",
    },()=>{
      this.getTopicData()
    });
    this.getLineChartOnChange(event.target.value);
  };
  handleChangeTopic = (event) => {
    this.setState({ selectedTopic: event.target.value });
    if (event.target.value !== "") {
      this.getTopicChart(event.target.value);
    } else {
      this.setState({ showBarGraph: false });
    }
  };
  getTopicChart = (topicId) => {
    getBarChart(this.state.selectedSection, this.state.selectedStudent, topicId)
      .then((responseTopicChart) => {
        if (responseTopicChart !== undefined) {
          let labels = [];
          let barData = [];
          responseTopicChart.map((data) => {
            labels.push(data.evaluationType);
            barData.push(data.percentScore);
          });

          let barChartData = { ...this.state.barChartData };
          barChartData["labels"] = labels;
          barChartData["datasets"][0]["data"] = barData;
          this.setState({ barChartData: barChartData, showBarGraph: true });
        }
      })
      .catch((error) => {
        console.log("error in topic chart", error);
      });
  };
  genratePdf = (responseEvalutationList) => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let input = window.document.getElementsByClassName("div2PDF")[0];
    html2canvas(input).then((canvas) => {
      const img = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", "letter");
     
      const tableRows = [];
      const tableColumn = ["Topic Name", "Evaluation Type", "Score", "Date"];
      const SectionName = this.state.userSectionsList.find(
        (section) => section.value == this.state.selectedSection
      );
      const studentName = this.state.allStudentsList.find(
        (student) => student.value == this.state.selectedStudent
      );
      let schoolNameRow = userData.school.schoolName;
      pdf.text(20, 20, schoolNameRow);
      pdf.text(20, 40, "Student Report");
      studentName && pdf.text(20, 60, studentName.label);
      SectionName && pdf.text(20, 80, SectionName.label);

      let dateRow = "Date Created: " + format(new Date(), "MM-dd-yyyy");
      pdf.text(20, 100, dateRow);
      pdf.text(20, 140, "Student Quiz Score Summary");

      pdf.addImage(img, "png", 10, 150,533, 316);

      responseEvalutationList.forEach((evaluation) => {
        const rowData = [
          evaluation.schoolSectionTopicDisplayTitle,
          evaluation.evaluationType,
          evaluation.totalScore,

          // called date-fns to format the date on the ticket
          format(new Date(evaluation.updatedAt), "MM-dd-yyyy"),
        ];
        // push each student's info into a row
        tableRows.push(rowData);
      });
      // pdf.addPage()
      pdf.text(10, 490, "Topic Details.");
      pdf.autoTable(tableColumn, tableRows, { startY: 510 });
      let pdfName = "rewards" + format(new Date(), "_MM_dd_yyyy_HH:mm:ss");
      pdf.save(pdfName);
      this.setState({isPdfDisable:false})
    });
  };
  getLineChartOnChange = (studnetId) => {
    getLineChart(this.state.selectedSection, studnetId).then(
      (responseChart) => {
        if (responseChart !== undefined) {
          let labels = [];
          let lineData = [];
          responseChart.map((data) => {
            labels.push(data.schoolSectionTopicDisplayTitle);
            lineData.push(data.percentScore);
          });

          let lineChartData = { ...this.state.lineChartData };
          lineChartData["labels"] = labels;
          lineChartData["datasets"][0]["data"] = lineData;
          this.setState({ lineChartData: lineChartData });
        }
      }
    );
  };
  hideEvaluationModal = () => {
    this.setState({
      showModalEvaluation: !this.state.showModalEvaluation,
      tittleModal: "Quiz",
    });
  };
  getTopicData = (sectionId, StudentId) => {
    getGradesTopicDetails(
      this.state.selectedSection,
      this.state.selectedStudent
    )
      .then((responseData) => {
        if (responseData !== undefined) {
          this.setState({ topicData: responseData });
        }
      })
      .catch((error) => {
        console.log("error in DownLoad Pdf", error);
      });
  };
  evaluationFormatter = (cell, rows) => {

    return (
      <div key={rows.id}>
        <span
          className="view-title pointer_class"
          onClick={() =>
            this.getEvaluationQuestions(
              rows.schoolSectionStudentEvaluationId,
              rows
            )
          }
        >
          {cell}
        </span>
      </div>
    );
  };
  dateFormatter = (cell, rows) => {
    return (
      <div key={rows.id}>
        <Moment format="MM/DD/YYYY ">{cell}</Moment>
      </div>
    );
  };
  // this funcition is used for questions list
  getEvaluationQuestions = (schoolSectionEvaluationId, rowData) => {
    this.setState({ isloading: true });
    getEvaluationQuestions(schoolSectionEvaluationId)
      .then((res) => {
        if (res !== undefined) {
          let section = this.state.userSectionsList.find(
            (section) => section.value == this.state.selectedSection
          );
          let tittleModal =
            section["label"] +
            "-" +
            rowData["schoolSectionTopicDisplayTitle"] +
            "-" +
            rowData.evaluationType +
            " ";
          this.setState({
            questionList: res,
            showQuestionList: true,
            showModalEvaluation: true,
            isloading: false,
            tittleModal: tittleModal,
          });
        }
      })
      .catch((error) => {
        console.log("res Question error", error);
      });
  };

  render() {
    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };
    const columns = [
      {
        dataField: "schoolSectionTopicDisplayTitle",
        text: "Topic Name",
        // formatter: this.assignmentFormatter,
        align: "left",
        headerAlign: "left",
        sort: true,
      },
      {
        dataField: "evaluationType",
        text: "Evaluation Type",

        formatter: this.nameFormatter,
        headerStyle: {
          width: "3s%",
        },
        headerAlign: "left",
        formatter: this.evaluationFormatter,
      },
      {
        dataField: "totalScore",
        text: "Score",

        align: "right",
        headerAlign: "center",
      },
      {
        dataField: "updatedAt",
        text: "Date",
        formatter: this.dateFormatter,
        align: "center",
        headerAlign: "center",
      },
    ];


    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box ">
                    <div className="card-header">
                      <Row>
                        <Col md="6">
                          <div className="title">
                            <h4>Grades</h4>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md="3">
                          <select
                            className="form-control"
                            onChange={this.handleChangeSection}
                            value={this.state.selectedSection}
                          >
                            {this.state.userSectionsList !== undefined &&
                              this.state.userSectionsList.map((option) => (
                                <option value={option.value} key={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        </Col>
                        <Col md="3">
                          <select
                            className="form-control"
                            onChange={this.handleChangeStudent}
                            value={this.state.selectedStudent}
                          >
                            {this.state.allStudentsList !== undefined &&
                              this.state.allStudentsList.map((option) => (
                                <option value={option.value} key={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        </Col>
                        <Col md="3">
                          <select
                            className="form-control"
                            onChange={this.handleChangeTopic}
                            value={this.state.selectedTopic}
                          >
                            {this.state.topicList !== undefined &&
                              this.state.topicList.map((option) => (
                                <option value={option.value} key={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        </Col>
                        <Col md="3">
                          <button
                            className="btn btn-primary-fill float-right"
                            onClick={this.handleModalShow}
                          >
                            <FontAwesomeIcon icon={faPlus} /> &nbsp;Send Message{" "}
                          </button>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      <div>
                        {this.state.isloading ? (
                          <div className="custom-loader">
                            <Spinner animation="border" />
                          </div>
                        ) : (
                          <div>
                            <div>
                              <Row className="mb-2">
                                <Col md="8" className="div2PDF">
                                  <Line
                                    data={this.state.lineChartData}
                                    options={options}
                                  />
                                </Col>
                                <Col md="4">
                                <button
                                  className="btn btn-primary-fill float-right mt-3"
                                  to="report-download"
                                  onClick={(e) => this.downloadPdf(e)}
                                  disabled={this.state.isPdfDisable}
                                >
                                  <FontAwesomeIcon icon={faFileDownload} />{" "}
                                  &nbsp;{this.state.isPdfDisable?'downloading...':'Download PDF Report'}
                                </button>
                              </Col>
                              </Row>
                              <Row>
                                {this.state.showBarGraph && (
                                  <div>
                                  <Col md="8">
                                    <Bar
                                      data={this.state.barChartData}
                                      height={300}
                                      options={{
                                        title: {
                                          display: true,
                                          text: "Quiz",
                                          fontSize: 20,
                                        },
                                        legend: {
                                          display: true,
                                          position: "right",
                                        },
                                        maintainAspectRatio: false,
                                      }}
                                    />
                                  </Col>
                                  <div>
                                      <BootstrapTable
                                        keyField="id"
                                        key={`studentListTable`}
                                        data={this.state.topicData}
                                        columns={columns}
                                        pagination={paginationFactory({
                                          sizePerPage: 200,
                                          showTotal: true,
                                          hideSizePerPage: true,
                                        })}
                                        rowClasses="row-hover "
                                        classes="custom-table-border mt-3"
                                        // rowEvents={rowEvents}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Row>
                            </div>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
            <StudentEvaluationDetails
              showModalEvaluation={this.state.showModalEvaluation}
              hideEvaluationModal={this.hideEvaluationModal}
              tittleModal={this.state.tittleModal}
              questionList={this.state.questionList}
            />
            <SendMessage
              showModalMessage={this.state.showModalMessage}
              initialValues={this.state.initialValues}
              handleModalHide={this.handleModalHide}
              errorMessage={this.state.errorMessage}
              allStudentsList={this.state.allStudentsList}
              isDisable={this.state.isDisable}
              onSubmit={this.sendMessage}
              previousMessageList={this.state.previousMessageList}
              readMessage={this.readMessage}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
