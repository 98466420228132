import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,

} from "reactstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  getClientList,
  addClient,
  updateClient,
} from "../../services/ClientServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddEditClient from "./AddEditClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

toast.configure();
class ClientList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientList: [],
      errorMessage: "",
      isloading: false,
      showModalClient: false,
      initialValues: {
        clientName: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        city: "",
        state: "",
        pin: "",
      },
      titleAddEdit: "Add",
    };
  }
  componentDidMount() {
    this.getClientList();
  }
  getClientList = () => {
    this.setState({ isloading: true });
    getClientList()
      .then((responseClientList) => {
        if (responseClientList !== undefined) {
          this.setState({
            clientList: responseClientList.rows,
            isloading: false,
            totalSize: responseClientList.count,
          });
        }
      })
      .catch((error) => {
        console.log("error in client list", error);
        if (error !== undefined) {
          this.setState({ errorMessage: error.message, isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  addressFormatter = (cell, row) => {
    return (
      <span>
        {row.addressLine1 !== null && row.addressLine1}
        <br></br>
        {row.addressLine2 !== null && row.addressLine2}
        <br></br>
        {row.addressLine3 !== null && row.addressLine3}
      </span>
    );
  };
  handleModalShow = () => {
    this.setState({
      showModalClient: !this.state.showModalClient,
      errorMessage: "",
    });
  };
  handleModalHide = () => {
    this.setState({
      showModalClient: !this.state.showModalClient,
      errorMessage: "",
      initialValues: {
        clientName: "",
        addressLine1: "",
        addressLine2: null,
        addressLine3: null,
        city: "",
        state: "",
        pin: "",
      },
      titleAddEdit: "Add",
      errorMessage: "",
    });
  };
  addClient = (ClientData, actions) => {
    this.setState({ isloading: true });
    addClient(ClientData)
      .then((responseClientData) => {
        if (responseClientData !== undefined) {
          this.getClientList();
          this.setState({ isloading: false, showModalClient: false });
          toast.success(responseClientData.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });

          actions.resetForm();
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };
  showModalUPdateClient = (rowData) => {
    this.setState({
      initialValues: rowData,
      showModalClient: true,
      titleAddEdit: "Update",
    });
  };
  updateClient = (ClientData, actions) => {
    this.setState({ isloading: true });
    updateClient(ClientData.id, ClientData)
      .then((responseClientData) => {
        if (responseClientData !== undefined) {
          this.getClientList();
          this.setState({
            isloading: false,
            showModalClient: false,
            initialValues: {
              clientName: "",
              addressLine1: "",
              addressLine2: null,
              addressLine3: null,
              city: "",
              state: "",
              pin: "",
            },
          });
          toast.success("Client detail updated successfully", {
            autoClose: 3000,
            hideProgressBar: true,
          });

          actions.resetForm();
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };

  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.showModalUPdateClient(row);
      },
    };
    const columns = [
      {
        dataField: "id",
        text: "Client ID",
        sort: true,
      },
      {
        dataField: "clientName",
        text: "Client Name",
      },
      {
        dataField: "addressLine1",
        text: "Address",
        formatter: this.addressFormatter,
      },
      {
        dataField: "city",
        text: "City",
      },
      {
        dataField: "state",
        text: "State",
      },
      {
        dataField: "pin",
        text: "Pin",
      },
    ];

    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row>
                        <Col md="6">
                          <div className="title">
                           Clients
                          </div>
                        </Col>
                        <Col md="6">
                          <button
                            className="btn btn-primary-fill float-right mt-3"
                            onClick={this.handleModalShow}
                          >
                            <FontAwesomeIcon icon={faPlus} /> &nbsp;Add Client{" "}
                          </button>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      <div>
                        <div>
                          {this.state.isloading ? (
                            <div className="custom-loader">
                              <Spinner animation="border" />
                            </div>
                          ) : (
                              <BootstrapTable
                                keyField="id"
                                key={`clientListTable`}
                                data={this.state.clientList}
                                columns={columns}
                                pagination={paginationFactory({
                                  sizePerPage: 10,
                                  showTotal: true,
                                  hideSizePerPage: true,
                                })}
                                rowEvents={rowEvents}
                                rowClasses="row-hover pointer_class"
                              />
                            )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <AddEditClient
                showModalClient={this.state.showModalClient}
                handleModalHide={this.handleModalHide}
                onSubmit={
                  this.state.titleAddEdit === "Update"
                    ? this.updateClient
                    : this.addClient
                }
                errorMessage={this.state.errorMessage}
                initialValues={this.state.initialValues}
                titleAddEdit={this.state.titleAddEdit}
              />
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ClientList;
