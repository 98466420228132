import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { getRewardsList } from "../services/TeacherServices";
import { getUserSchoolSections } from "../services/UserServices";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
export default class TeacherRewards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardList: [],
      errorMessage: "",
      isloading: false,
      userSectionsList: [],
      selectedSection: "",
      studentDetails: [
        { name: "Sumit Jain", rank: "1", reward: 70 },
        { name: "Ashutosh Pandya", rank: "2", reward: 50 },
        { name: "Vikram Jain", rank: "3", reward: 40 },
      ],
      data: {
        labels: ["Value-1", "Value-2", "Value-3"],
        datasets: [
          {
            label: "Rainfall",
            backgroundColor: [
              "red",
              "rgba(4, 72, 9, 1)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
            ],
            borderColor: "rgba(0,0,0,1)",
            borderWidth: 2,
            data: [40, 80, 30],
          },
        ],
      },
      studentList: [],
    };
  }
  componentDidMount() {
    this.getUserSectionList();
  }
  getUserSectionList = () => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getUserSchoolSections(userData.id)
      .then((responseUserSections) => {
        if (
          responseUserSections !== undefined &&
          responseUserSections.length > 0 &&
          responseUserSections[0]["userRoles"] !== undefined &&
          responseUserSections[0]["userRoles"][0]["userRolesSchoolSections"] !==
            undefined
        ) {
          var userSectionList = [];
          responseUserSections[0]["userRoles"][0][
            "userRolesSchoolSections"
          ].map((userSection) => {
            let data = {};
            data["label"] =
              userSection.schoolSection.boardGradeSubject.grade.grade +
              " Grade " +
              userSection.schoolSection.boardGradeSubject.subject.subject +
              " " +
              userSection.schoolSection.sectionName;
            data["value"] = userSection.schoolSectionId;
            userSectionList.push(data);
          });
          this.setState({ userSectionsList: userSectionList });
          if (userSectionList.length > 0) {
            this.setState({ selectedSection: userSectionList[0].value });
            return getRewardsList(userSectionList[0].value);
          }
        } else {
          this.setState({ isloading: false, userSectionsList: [] });
        }
      })
      .then((response) => {
        if (response !== undefined) {
          this.setState({ studentList: response, isloading: false });
        }
      })
      .catch((error) => {
        console.log("error in section announcement list", error);
        if (error !== undefined) {
          this.setState({ isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  getStudentListBySection = (sectionId) => {
    this.setState({ isloading: true });
    getRewardsList(sectionId)
      .then((response) => {
        if (response !== undefined) {
          this.setState({ studentList: response, isloading: false });
        }
      })
      .catch((error) => {
        console.log("error in student list", error);
        if (error !== undefined) {
          this.setState({ errorMessage: error.message, isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  handleChangeSection = (event) => {
    this.setState({ selectedSection: event.target.value });
    this.getStudentListBySection(event.target.value);
  };
  div2PDF = (e) => {
    /////////////////////////////
    // Hide/show button if you need
    /////////////////////////////

    const but = e.target;
    but.style.display = "none";
    let input = window.document.getElementsByClassName("div2PDF")[0];

    html2canvas(input).then((canvas) => {
      const img = canvas.toDataURL("image/png");
      const pdf = new jsPDF("l", "pt");

      console.log(" input.offsetLeft", input.offsetLeft);
      console.log("   input.offsetTop", input.offsetTop);
      console.log(" input.clientWidth,", input.clientWidth);
      console.log(" input.clientWidth,", input.clientHeight);
      pdf.addImage(img, "png", input.offsetLeft, 10, 600, 400);
      pdf.save("chart.pdf");
      but.style.display = "block";
    });
  };

  nameFormatter = (cell, rows) => {
    return <span>{cell.firstName + " " + cell.lastName}</span>;
  };
  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        let toDetails = {
          pathname: `/app/grade/grade-details/${row.schoolSectionId}/${row.studentId}`,
          studentDetails: {
            schoolSectionId: row.schoolSectionId,
            studentId: row.studentId,
          },
        };
        this.props.history.push(toDetails);
      },
    };
    const columns = [
      {
        dataField: "rank",
        text: "Rank",
        // formatter: this.assignmentFormatter,
        align: "right",
        headerAlign: "center",
        sort: true,
      },
      {
        dataField: "user",
        text: "Student Name",
        sort: true,
        formatter: this.nameFormatter,
        headerStyle: {
          width: "3s%",
        },
        headerAlign: "center",
      },
      {
        dataField: "totalPoints",
        text: "Rewards",
        // formatter: this.assignmentFormatter,
        align: "right",
        headerAlign: "center",
        sort: true,
      },
    ];
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box ">
                    <div className="card-header">
                      <Row>
                        <Col md="6">
                          <div className="title">
                            <h4>Rewards</h4>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      <div>
                        {this.state.isloading ? (
                          <div className="custom-loader">
                            <Spinner animation="border" />
                          </div>
                        ) : (
                          <div>
                            <Row className="mb-5">
                              <Col md="3">
                                <label> Select Section</label>
                              </Col>
                              <Col md="5">
                                <select
                                  className="form-control"
                                  onChange={this.handleChangeSection}
                                  value={this.state.selectedSection}
                                >
                                  {this.state.userSectionsList !== undefined &&
                                    this.state.userSectionsList.map(
                                      (option) => (
                                        <option
                                          value={option.value}
                                          key={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      )
                                    )}
                                </select>
                              </Col>
                            </Row>
                            <Col md="12" className="div-center">
                              {this.state.isloading ? (
                                <div className="custom-loader">
                                  <Spinner animation="border" />
                                </div>
                              ) : (
                                <div>
                                  <BootstrapTable
                                    keyField="id"
                                    key={`studentListTable`}
                                    data={this.state.studentList}
                                    columns={columns}
                                    pagination={paginationFactory({
                                      sizePerPage: 200,
                                      showTotal: true,
                                      hideSizePerPage: true,
                                    })}
                                    rowClasses="row-hover "
                                    classes="custom-table-border"
                                    rowEvents={rowEvents}
                                  />
                                </div>
                              )}
                            </Col>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}
