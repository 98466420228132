import React, { Component } from 'react'
import logo from '../../assets/images/logo.png'
import user from '../../assets/images/user.png'
import { Link } from "react-router-dom";
import SimpleLineIcon from 'react-simple-line-icons'
import { getAuthRedirectUrl } from '../../utils/General'

class Header extends Component {
	handleLogout = () => {
		localStorage.clear()
		window.location.href = '/'
	}
	handleRoleChange = (role) => {
		localStorage.setItem('defaultRole',  JSON.stringify([role]));
		this.props.history.push(getAuthRedirectUrl());
	}
	handleMyProfile=()=>{
		this.props.history.push('/my-profile')
	}
	render() {
		let roles=JSON.parse(localStorage.getItem('roleIds'));
		let userData=JSON.parse(localStorage.getItem('user'))
		return (
			<header className="header-layout">
				<div className="logo-sec"><i className="icon-menu icon" id="barIcon" onClick={() => this.props.handletoggle()}></i><img src={logo} alt='' /></div>
				{/*<div className="header-search">*/}
				{/*<div className="form-group mb-0">*/}
				{/*<i className="icon-magnifier icons"></i>*/}
				{/*<input type="search" name="" placeholder="Search in app..." />*/}
				{/*</div>*/}
				{/*</div> */}
				<div className="header-right">
					<div className="header-icons dropdown">
						<span className="profile-btn dropdown-toggle" id="profile" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<img src={user} className="mr-1" alt='' />
							<span style={{ cursor: 'pointer' }}>{userData !== undefined && userData !== null&& userData.firstName+" "+userData.lastName}</span>
						</span>
						<ul className="dropdown-menu" aria-labelledby="profile">
							{/*<span className="dropdown-item" style={{ cursor: 'pointer' }} href="#">{t('MyProfile')}</span>
							<div className="dropdown-divider"></div>*/}
							<li>
								<Link className="dropdown-item" to='my-profile'> My Profile</Link>
								{userData !== undefined && userData !== null&&userData.userRoles.some(user=>user.roleId===3)
									&&
									<div>
										<Link className="dropdown-item" to='my-classes'> My Classes</Link>
										<Link className="dropdown-item" to='reset-password'> Reset Password</Link>
									</div>
								}	
								{userData !== undefined && userData !== null&&userData.userRoles.some(user=>user.roleId===4)
									&&
									<div>
										<Link className="dropdown-item" to='my-sections'> My Sections</Link>
									</div>
								}	
							</li>		
							<li><Link className="dropdown-item" to='#' onClick={this.handleLogout}> Logout</Link></li>
						</ul>
					</div>
				</div>
			</header>
		)
	}
}
export default Header;
