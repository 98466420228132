import React, { Component } from "react";
import { Row, Col, Card, Spinner, CardBody } from "reactstrap";
import { getUserDetails, updateUser } from "../services/UserServices";
import { getParentRegCode } from "../services/StudentServices";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import trimObj from "../components/common/TrimData";
toast.configure();
export default class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DashboardList: [],
      errorMessage: "",
      registrationCode: "",
      isloading: false,
      initialValues: {
        firstName: "",
        middleName: "",
        lastName: "",
        primaryEmail: "",
        username: "",
        isActive: false,
        password: "",
      },
    };
  }
  componentDidMount() {
    this.getUserProfileDetails();
    let roles = JSON.parse(localStorage.getItem("roleIds"));
    if (4 == roles[0]) {
      this.getRegCode();
    }
  }

  getUserProfileDetails = () => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getUserDetails(userData.id)
      .then((userDetails) => {
        if (userDetails !== undefined) {
          console.log("userDetails", userDetails);
          this.setState({ isloading: false, initialValues: userDetails });
        }
      })
      .catch((error) => {
        console.log("error in user profile", error);
        if (error !== undefined) {
          this.setState({ isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  getRegCode = () => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getParentRegCode(userData.id)
      .then((resCode) => {
        console.log(resCode);
        var registrationCode = [];
        if (resCode !== undefined) {
          //alert('hello')
          let datacode = resCode.parentRegistrationCode;
          console.log("resRegCode", datacode);
          registrationCode.push(datacode);
        }
        this.setState({ isloading: false, registrationCode: registrationCode });
      })
      .catch((error) => {
        console.log("error in refrence code", error);
        if (error !== undefined) {
          this.setState({ isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  updateUserDetails = (userData, actions) => {
    this.setState({ isloading: true });
    updateUser(userData.id, userData)
      .then((responseUserData) => {
        if (responseUserData !== undefined) {
          localStorage.setItem("user", JSON.stringify(responseUserData));
          this.setState({ initialValues: responseUserData, isloading: false });
          toast.success("User details updated successfully", {
            autoClose: 3000,
            hideProgressBar: true,
          });
          // window.location.reload();
          this.props.history.push("");
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };
  hanleCancel = () => {
    this.props.history.push("");
  };
  render() {
    const validationSchema = Yup.object().shape({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      primaryEmail: Yup.string().required("Please Enter Email"),
      username: Yup.string().required("Please Enter UserName"),
    });
    const parentCode = this.state.registrationCode;
    let roles = JSON.parse(localStorage.getItem("roleIds"));
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row>
                        <Col md="6">
                          <div className="title">
                            <h4>My Profile</h4>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      <div>
                        {this.state.isloading ? (
                          <div className="custom-loader">
                            <Spinner animation="border" />
                          </div>
                        ) : (
                          <div>
                            <Formik
                              enableReinitialize
                              initialValues={this.state.initialValues}
                              onSubmit={(values, actions) => {
                                values.middleName =
                                  values.middleName !== ""
                                    ? values.middleName
                                    : null;
                                if (values.password === "") {
                                  delete values["password"];
                                }
								   // this function used to trim string before and after
								   values = trimObj(values);
                                this.updateUserDetails(values, actions);
                              }}
                              validationSchema={validationSchema}
                            >
                              {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                resetForm,
                                /* and other goodies */
                              }) => (
                                <form onSubmit={handleSubmit}>
                                  {this.state.errorMessage !== "" ? (
                                    <p className="alert alert-danger">
                                      {this.state.errorMessage}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  <div className="form-group form-gp">
                                    <Row>
                                      <Col md="3">
                                        <label> First Name</label>
                                      </Col>
                                      <Col md="6">
                                        <input
                                          type="text"
                                          name="firstName"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.firstName}
                                          className="form-control"
                                          placeholder="First Name"
                                        />
                                        <label className="err-msg">
                                          {errors.firstName &&
                                            touched.firstName &&
                                            errors.firstName}
                                        </label>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="form-group form-gp">
                                    <Row>
                                      <Col md="3">
                                        <label> Middle Name</label>
                                      </Col>
                                      <Col md="6">
                                        <input
                                          type="text"
                                          name="middleName"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={
                                            values.middleName !== null
                                              ? values.middleName
                                              : ""
                                          }
                                          className="form-control"
                                          placeholder="Middle Name"
                                        />
                                        <label className="err-msg">
                                          {errors.middleName &&
                                            touched.middleName &&
                                            errors.middleName}
                                        </label>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="form-group form-gp">
                                    <Row>
                                      <Col md="3">
                                        <label> Last Name</label>
                                      </Col>
                                      <Col md="6">
                                        <input
                                          type="text"
                                          name="lastName"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.lastName}
                                          className="form-control"
                                          placeholder="Last Name"
                                        />
                                        <label className="err-msg">
                                          {errors.lastName &&
                                            touched.lastName &&
                                            errors.lastName}
                                        </label>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="form-group form-gp">
                                    <Row>
                                      <Col md="3">
                                        <label> Email</label>
                                      </Col>
                                      <Col md="6">
                                        <input
                                          type="email"
                                          name="primaryEmail"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.primaryEmail}
                                          className="form-control"
                                          placeholder="Email"
                                        />
                                        <label className="err-msg">
                                          {errors.primaryEmail &&
                                            touched.primaryEmail &&
                                            errors.primaryEmail}
                                        </label>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="form-group form-gp">
                                    <Row>
                                      <Col md="3">
                                        <label> Username</label>
                                      </Col>
                                      <Col md="6">
                                        <input
                                          type="text"
                                          name="username"
                                          autoComplete="new-password"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.username}
                                          className="form-control"
                                          placeholder="User Name"
                                        />
                                        <label className="err-msg">
                                          {errors.username &&
                                            touched.username &&
                                            errors.username}
                                        </label>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="form-group form-gp">
                                    <Row>
                                      <Col md="3">
                                        <label> Password</label>
                                      </Col>
                                      <Col md="6">
                                        <input
                                          autoComplete="off"
                                          type="password"
                                          name="password"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.password}
                                          className="form-control"
                                          placeholder="password"
                                        />
                                        <label className="err-msg">
                                          {errors.password &&
                                            touched.password &&
                                            errors.password}
                                        </label>
                                      </Col>
                                    </Row>
                                  </div>
                                  {roles == 4 && (
                                    <div className="form-group form-gp">
                                      <Row>
                                        <Col md="3">
                                          <label>
                                            {" "}
                                            Parent Registration Code
                                          </label>
                                        </Col>
                                        <Col md="2">
                                          <div>
                                            {this.state.registrationCode}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  )}

                                  <div className="form-group form-gp">
                                    <Row>
                                      <Col md="3">
                                        <label> Active</label>
                                      </Col>
                                      <Col md="2">
                                        <div className="form-check">
                                          <input
                                            type="checkbox"
                                            name="isActive"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.isActive}
                                            defaultChecked={values.isActive}
                                            className="form-check-input position-static checkbox-2x c9"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>

                                  <div className="form-group form-gp mt-5">
                                    <Row>
                                      <Col md="3" className="offset-md-3">
                                        <button
                                          className="btn btn-secondary mr-2"
                                          onClick={this.hanleCancel}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          type="submit"
                                          className="btn btn-primary-fill"
                                        >
                                          Update
                                        </button>
                                      </Col>
                                    </Row>
                                  </div>
                                </form>
                              )}
                            </Formik>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}
