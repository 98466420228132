import { intersection } from 'lodash';

export function isArrayWithLength(arr) {
    return (Array.isArray(arr) && arr.length)
}

export function getAllowedRoutes(routes) {
    const roles = JSON.parse(localStorage.getItem('roleIds'));
    return routes.filter(({ permission }) => {
        if(!permission) return true;
        else if(!isArrayWithLength(permission)) return true;
        else return intersection(permission, roles).length;
    });
}

export function isLoggedIn(){
    if (localStorage.getItem('token') === null) {
        return false;
    }
    else
        return true;
}
