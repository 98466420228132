import React, { Component } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { getSchoolList } from "../../../services/SchoolServices";
import { getClientList } from "../../../services/ClientServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getClientSchoolUsersByClient } from "../../../services/ClientUserServices";
import AddEditClientUser from "./AddEditClientUser";
import { addUser, updateUser } from "../../../services/UserServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCheck, faBan } from "@fortawesome/free-solid-svg-icons";
import { getAllRoles } from "../../../services/GeneralServices";
toast.configure();
class ClientUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolList: [],
      errorMessage: "",
      isloading: false,
      clientOptions: [],
      selectedClientValue: "",
      titleAddEdit: "Add",
      schoolsOptions: [],
      schoolId: "",
      SchoolUserList: [],
      allUserList: [],
      selectedSchoolValue: "",
      initialValues: {
        firstName: "",
        middleName: "",
        lastName: "",
        primaryEmail: "",
        username: "",
        password: "",
        roleId: "",
        isActive: false,
        schoolId: "",
        clientId: "",
      },
      showClientUserForm: false,
      formLoading: false,
      userRoles: [],
      isShowRoleModal: false,
      userName: "",
      clientUserId: "",
      userNameOptions: [],
      clientUserRoles: [],
      clientAdminRoles: [],
      showClientAdmin: false,
      allClientUserRoles: [],
      schoolName: "",
      clientName: "",
      currentPage: 1,
    };
  }
  componentDidMount() {
    this.getClientList();
  }
  //this function is used for client list 
  getClientList = () => {
    this.setState({ isloading: true });
    getClientList()
      .then((responseClientList) => {
        if (responseClientList !== undefined) {
          let options = [];
          //here we are creating dropdown options
          responseClientList.rows.map((client) => {
            options.push({ label: client.clientName, value: client.id });
          });
          if (options !== []) {
            this.setState({
              selectedClientValue: options[0].value,
              clientName: options[0].label,
              clientOptions: options,
            });
            this.getUserByClient(options[0].value);
          } else {
            this.setState({ isloading: false });
          }
          return getAllRoles();
        }
      })
      .then((allRoles) => {
        if (allRoles !== undefined) {
          let clientUserRoles = [];
          let ClientRoles = allRoles.filter(
            (role) => !role.role.includes("OMO")
          );
          ClientRoles.map((role) => {
            if (role.id !== 1) {
              clientUserRoles.push({ label: role.roleDisplay, value: role.id });
            }
          });
          this.setState({
            clientUserRoles: clientUserRoles,
            allClientUserRoles: clientUserRoles,
          });
        }
      })
      .catch((error) => {
        if (error !== undefined) {
          this.setState({ errorMessage: error.message, isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  // this is used for handle change client dropdown
  handleChangeClient = (SelectedOption) => {
    this.setState({
      isloading: true,
      selectedClientValue: SelectedOption.target.value,
      clientName: SelectedOption.target.selectedOptions[0].text,
      selectedSchoolValue: "",
      schoolsOptions: [],
    });
    this.getUserByClient(SelectedOption.target.value);
  };
  // this api used to get school users 
  getUserByClient = (clientId) => {
    getClientSchoolUsersByClient(clientId)
      .then((clientUserList) => {
        if (clientUserList !== undefined) {
          let userNameOptions = [{ label: "Select Username", value: "" }];
          //here we are creating dropdown user options
          clientUserList.rows.map((schoolUser) => {
            userNameOptions.push({
              label: schoolUser.username,
              value: schoolUser.id,
            });
          });
          this.setState({
            SchoolUserList: clientUserList.rows,
            isloading: false,
            allUserList: clientUserList.rows,
            userNameOptions: userNameOptions,
          });
          return getSchoolList(clientId);
        }
      })
      .then((responseSchoolList) => {
        if (responseSchoolList !== undefined) {
          let schoolsOptions = [];
          responseSchoolList.rows.map((school) => {
            schoolsOptions.push({ label: school.schoolName, value: school.id });
          });
          this.setState({ schoolsOptions: schoolsOptions });
        }
      })
      .catch((error) => {
        if (error !== undefined) {
          this.setState({
            errorMessage: error.message,
            isloading: false,
            SchoolUserList: [],
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };

  /// this is used to hide screen
  handleModalHide = () => {
    this.setState({
      showModalSchoolUser: !this.state.showModalSchoolUser,
      initialValues: {
        clientId: "",
        boardId: "",
        schoolName: "",
        addressLine1: "",
        addressLine2: null,
        addressLine3: null,
        city: "",
        state: "",
        pin: "",
        isActive: false,
      },
      titleAddEdit: "Add",
      errorMessage: "",
    });
  };
  //this is used to show update screen
  showUpdateForm = (rowData) => {
    this.setState({
      initialValues: rowData,
      showClientUserForm: true,
      titleAddEdit: "Update",
      userRoles: rowData.userRoles,
      userName: rowData.username,
      clientUserId: rowData.id,
      showClientAdmin: false,
    });
  };
  handleChangeSchool = (event) => {
    let filterSchoolUsers = [];
    let allSchoolUsers = [...this.state.allUserList];
    let SchoolName = "";
    if (event.target.value !== "") {
      let School = this.state.schoolsOptions.find(
        (school) => school.value == event.target.value
      );
      SchoolName = School.label;
      filterSchoolUsers = allSchoolUsers.filter(
        (schoolUser) => schoolUser.schoolId == event.target.value
      );
    
    } else {
      filterSchoolUsers = [...allSchoolUsers];
    }
    let userNameOptions = [{ label: "Select Username", value: "" }];
    filterSchoolUsers.map((schoolUser) => {
      userNameOptions.push({
        label: schoolUser.username,
        value: schoolUser.id,
      });
    });

    this.setState({
      SchoolUserList: filterSchoolUsers,
      selectedSchoolValue: event.target.value,
      userNameOptions: userNameOptions,
      schoolName: SchoolName,
    });
  };
  handleChangeUsername = (event) => {
    let allSchoolUsers = [...this.state.allUserList];
    if (event.target.value !== "") {
      let userData = allSchoolUsers.find(
        (schoolUser) => schoolUser.id == event.target.value
      );
      if (userData !== undefined) {
        this.showUpdateForm(userData);
      }
    }
  };
  addClientUser = (ClientUserData, actions) => {
    this.setState({ formLoading: true });
    addUser(ClientUserData)
      .then((responseClientUserData) => {
        if (responseClientUserData !== undefined) {
          let SchoolUserList = [...this.state.SchoolUserList];
          SchoolUserList.push(responseClientUserData.user);
          this.setState({
            formLoading: false,
            showClientUserForm: false,
            initialValues: {
              firstName: "",
              middleName: "",
              lastName: "",
              primaryEmail: "",
              username: "",
              password: "",
              roleId: "",
              isActive: false,
              schoolId: "",
              clientId: "",
            },
            showClientAdmin: false,
            SchoolUserList: SchoolUserList,
            allClientUserRoles: this.state.allClientUserRoles,
          });
          toast.success(responseClientUserData.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          actions.resetForm();
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            formLoading: false,
            initialValues: ClientUserData,
          });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 5000);
      });
  };
  showUserForm = () => {
    let initialValues = { ...this.state.initialValues };
    initialValues.clientId = this.state.selectedClientValue;
    initialValues.schoolId = this.state.selectedSchoolValue;
    this.setState({
      initialValues: initialValues,
      showClientUserForm: true,
      titleAddEdit: "Add",
      showClientAdmin: false,
      clientUserRoles: this.state.allClientUserRoles,
    });
  };
  addClientAdmin = () => {
    let initialValues = { ...this.state.initialValues };
    initialValues.roleId = 1;
    initialValues.clientId = this.state.selectedClientValue;
    this.setState({
      initialValues: initialValues,
      showClientUserForm: true,
      clientUserRoles: [{ label: "Client Admin", value: 1 }],
      showClientAdmin: true,
      titleAddEdit: "Add",
      allClientUserRoles: this.state.allClientUserRoles,
    });
  };
  hideUserForm = () => {
    this.setState({
      initialValues: {
        firstName: "",
        middleName: "",
        lastName: "",
        primaryEmail: "",
        username: "",
        password: "",
        roleId: "",
        isActive: false,
        schoolId: "",
        clientId: "",
      },
      showClientUserForm: false,
      showClientAdmin: false,
    });
  };

  updateClientUser = (clientUserData, actions) => {
    this.setState({ isloading: true });
    updateUser(clientUserData.id, clientUserData)
      .then((responseClientData) => {
        if (responseClientData !== undefined) {
          let SchoolUserList = [...this.state.SchoolUserList];
          var foundIndex = SchoolUserList.findIndex(
            (clientUser) => clientUser.id == responseClientData.id
          );
          //find and replace with updated client user
          if (foundIndex >= 0) {
            SchoolUserList[foundIndex] = responseClientData;
          }
          this.setState({
            isloading: false,
            showClientUserForm: false,
            initialValues: {
              firstName: "",
              middleName: "",
              lastName: "",
              primaryEmail: "",
              username: "",
              password: "",
              roleId: "",
              isActive: false,
              schoolId: "",
              clientId: "",
            },
            SchoolUserList: SchoolUserList,
            allClientUserRoles: this.state.allClientUserRoles,
          });
          toast.success("Client User  details updated successfully", {
            autoClose: 3000,
            hideProgressBar: true,
          });
          actions.resetForm();
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };
  // this formatter is used for active in
  activeFormatter = (cell, row) => {
    return (
      <span>
        {cell === true ? (
          <FontAwesomeIcon icon={faCheck} className="activeIcon" />
        ) : (
          <FontAwesomeIcon icon={faBan} className="inActiveIcon" />
        )}
      </span>
    );
  };
  handlePageChange = (event) => {
    this.setState({ currentPage: event });
  };
  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.showUpdateForm(row);
      },
    };
    const columns = [
      {
        dataField: "firstName",
        text: "First Name",
      },
      {
        dataField: "middleName",
        text: "Middle Name",
      },
      {
        dataField: "lastName",
        text: "Last Name",
      },
      {
        dataField: "username",
        text: "Username",
      },
      {
        dataField: "primaryEmail",
        text: "Email",
      },
      {
        dataField: "isActive",
        text: "Active",
        formatter: this.activeFormatter,
        align: "center",
        headerAlign: "center",
      },
    ];
    return (
      <div className="container-fluid">
        <Row>
          <Col md={12}>
            <React.Fragment>
              {this.state.showClientUserForm ? (
                <AddEditClientUser
                  showModalOmoUser={this.state.showModalOmoUser}
                  handleModalHide={this.handleModalHide}
                  onSubmit={
                    this.state.titleAddEdit === "Update"
                      ? this.updateClientUser
                      : this.addClientUser
                  }
                  errorMessage={this.state.errorMessage}
                  initialValues={this.state.initialValues}
                  titleAddEdit={this.state.titleAddEdit}
                  clientName={this.state.clientName}
                  schoolName={this.state.schoolName}
                  roles={this.state.clientUserRoles}
                  hideUserForm={this.hideUserForm}
                  formLoading={this.state.formLoading}
                  userRoles={this.state.userRoles}
                  userName={this.state.userName}
                  clientUserId={this.state.clientUserId}
                  clientOptions={this.state.clientOptions}
                  schoolsOptions={this.state.schoolsOptions}
                  handleChangeSchool={this.handleChangeSchool}
                  handleChangeClient={this.handleChangeClient}
                  userNameOptions={this.state.userNameOptions}
                  selectedSchoolValue={this.state.selectedSchoolValue}
                  handleChangeUsername={this.handleChangeUsername}
                  showClientAdmin={this.state.showClientAdmin}
                />
              ) : (
                <Row>
                  <Col lg="12" md="12">
                    <Card className="card-box">
                      <div className="card-header">
                        <Row className="mb-4">
                          <Col md="3">
                            <div className="title">Client User</div>
                          </Col>
                          <Col md="9">
                            <button
                              className="btn btn-primary-fill float-right  ml-2 mt-3"
                              onClick={this.showUserForm}
                              disabled={
                                this.state.selectedClientValue !== "" &&
                                this.state.selectedSchoolValue !== ""
                                  ? false
                                  : true
                              }
                            >
                              {" "}
                              <FontAwesomeIcon icon={faPlus} /> &nbsp;Add User
                            </button>

                            <button
                              className="btn btn-primary-fill float-right mt-3"
                              onClick={this.addClientAdmin}
                            >
                              {" "}
                              <FontAwesomeIcon icon={faPlus} /> &nbsp;Add Client
                              Admin
                            </button>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3">
                            <select
                              className="form-control"
                              onChange={this.handleChangeClient}
                              value={this.state.selectedClientValue}
                            >
                              {this.state.clientOptions !== undefined &&
                                this.state.clientOptions.map((option) => (
                                  <option
                                    value={option.value}
                                    key={option.value}
                                    selected={
                                      option.value ===
                                      this.state.selectedClientValue
                                    }
                                  >
                                    {option.label}
                                  </option>
                                ))}
                            </select>
                          </Col>
                          <Col md="3">
                            <select
                              className="form-control"
                              onChange={this.handleChangeSchool}
                              value={this.state.selectedSchoolValue}
                            >
                              <option value="">Select School</option>
                              {this.state.schoolsOptions !== undefined &&
                                this.state.schoolsOptions.map((option) => (
                                  <option
                                    value={option.value}
                                    key={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                            </select>
                          </Col>
                        </Row>
                      </div>
                      <CardBody>
                        <div>
                          <div>
                            {this.state.isloading ? (
                              <div className="custom-loader">
                                <Spinner animation="border" />
                              </div>
                            ) : (
                              <BootstrapTable
                                keyField="id"
                                key={`clientUserListTable`}
                                data={this.state.SchoolUserList}
                                columns={columns}
                                pagination={paginationFactory({
                                  sizePerPage: 10,
                                  showTotal: true,
                                  hideSizePerPage: true,
                                })}
                                rowClasses="row-hover pointer_class"
                                rowEvents={rowEvents}
                              />
                            )}
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ClientUserList;
