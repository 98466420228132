import React, { Component } from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import {
  sendMessageToTeacher,
  getMessagesForReceiver,
  readMessagesByUser,
} from "../../services/StudentServices";
import {
  getUserSchoolSections,
  getMessageListBySubject,
  getUsersBySection,
} from "../../services/UserServices";
import SendMessage from "./SendMeaasge";
import { Accordion, Card } from "react-bootstrap";
import Moment from "react-moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Api from '../../Api'
// import axios from 'axios'
import { faPlus, faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class AskYourTeacher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      isloading: false,
      sectionsList: [],
      messageList: [],
      selectedSection: "",
      showModalMessage: false,
      messageRead: false,
      initialValues: {
        senderId: "",
        messageSubject: "",
        message: "",
        receiverId: "",
        schoolSectionId: "",
      },
      errorMessage: "",
      isDisable: false,
      allStudentsList: [],
      studentWithSectionList: [],
      previousMessageList: [],
    };
  }

  componentDidMount() {
    this.getSubjectSectionList();
  }

  getSubjectSectionList = () => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    //console.log(userData)
    getUserSchoolSections(userData.id)
      .then((resSubjectSec) => {
        if (
          resSubjectSec !== undefined &&
          resSubjectSec.length > 0 &&
          resSubjectSec[0]["userRoles"] !== undefined &&
          resSubjectSec[0]["userRoles"][0]["userRolesSchoolSections"] !==
            undefined
        ) {
          //console.log(resSubjectSec)
          var sectionList = [];
          resSubjectSec[0]["userRoles"][0]["userRolesSchoolSections"].map(
            (userSection) => {
              //console.log(userSection)
              let data = {};
              data["label"] =
                userSection.schoolSection.boardGradeSubject.grade.grade +
                " Grade " +
                userSection.schoolSection.boardGradeSubject.subject.subject +
                " " +
                userSection.schoolSection.sectionName;
              data["value"] = userSection.schoolSectionId;
              sectionList.push(data);
            }
          );
          this.setState({ sectionList: sectionList });
          if (sectionList.length > 0) {
            console.log(sectionList);
            this.setState({ selectedSection: sectionList[0].value });
            return getMessagesForReceiver(userData.id, sectionList[0].value);
          }
        } else {
          this.setState({ isloading: false, sectionList: [] });
        }
      })
      .then((responseMessageList) => {
        if (responseMessageList !== undefined) {
          console.log("get res", responseMessageList);
          this.setState({
            messageList: responseMessageList.rows,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error in section announcement list", error);
        if (error !== undefined) {
          this.setState({ isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };

  getMessageBySection = (sectionId) => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getMessagesForReceiver(userData.id, sectionId)
      .then((responseMessageList) => {
        console.log("get", responseMessageList);
        if (responseMessageList !== undefined) {
          this.setState({
            messageList: responseMessageList.rows,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error in client list", error);
        if (error !== undefined) {
          this.setState({ errorMessage: error.message, isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  handleChangeSection = (event) => {
    this.setState({ selectedSection: event.target.value });
    this.getMessageBySection(event.target.value);
  };
  readMessage = (message) => {
    console.log(message);
    if (!message.viewedByReceiver) {
      readMessagesByUser(message.id)
        .then((responseReadMessage) => {
          console.log("read resp", responseReadMessage);
          if (responseReadMessage !== undefined) {
            this.setState({ messageRead: true });
          }
        })
        .catch((error) => {
          console.log("error in read message");
        });
    }
  };
  onClickReply = (message) => {
    let initialValues = { ...this.state.initialValues };
    initialValues.senderId = message.receiverId;
    initialValues.receiverId = message.senderDetails.id;
    initialValues.messageSubject = message.messageSubject;
    initialValues.schoolSectionId = message.schoolSectionId;
    let studentOptions = [
      {
        label:
          message.senderDetails.firstName +
          " " +
          message.senderDetails.lastName,
        value: message.senderDetails.id,
      },
    ];
    getMessageListBySubject(
      message.messageSubject,
      message.receiverId,
      message.schoolSectionId
    ).then((responseMessage) => {
      this.setState({ previousMessageList: responseMessage.rows });
    });
    this.setState({
      initialValues: initialValues,
      showModalMessage: true,
      isDisable: true,
      allStudentsList: studentOptions,
    });
  };
  handleModalHide = () => {
    this.setState({
      initialValues: {
        senderId: "",
        messageSubject: "",
        message: "",
        receiverId: "",
      },
      showModalMessage: false,
      isDisable: false,
    });
  };
  handleModalShow = () => {
    let initialValues = { ...this.state.initialValues };
    let userData = JSON.parse(localStorage.getItem("user"));
    initialValues.senderId = userData.id;
    initialValues.schoolSectionId = this.state.selectedSection;
    console.log(initialValues.senderId, initialValues.schoolSectionId);
    getUsersBySection(this.state.selectedSection).then((response) => {
      console.log("response", response);
      let allTeachersOptions = [];
      response.rows
        .filter((user) => user.userRoles.some((role) => role.roleId == 3))
        .map((teacher) => {
          allTeachersOptions.push({
            label: teacher.firstName + " " + teacher.lastName,
            value: teacher.id,
          });
        });
      console.log(allTeachersOptions);
      this.setState({ allTeachersList: allTeachersOptions, isloading: false });
    });

    this.setState({
      initialValues: initialValues,
      showModalMessage: true,
      isDisable: false,
      previousMessageList: [],
    });
  };
  sendMessage = (messageData, actions) => {
    this.setState({ isloading: true });
    sendMessageToTeacher(messageData)
      .then((responseMessage) => {
        if (responseMessage !== undefined) {
          console.log("responseMessage", responseMessage);
          this.setState({ isloading: false, showModalMessage: false });
          toast.success(responseMessage.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });

          actions.resetForm();
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col md="12">
              <Card className="card-box">
                <Card.Header>
                  <div className="title">
                    <h4>Ask Your Teacher!</h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  {this.state.isloading ? (
                    <div className="custom-loader">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <div>
                      <div className="form-group form-gp">
                        <Row>
                          <Col md="2">
                            <label> Select Section</label>
                          </Col>
                          <Col md="4">
                            <select
                              className="form-control"
                              onChange={this.handleChangeSection}
                              value={this.state.selectedSection}
                            >
                              {this.state.sectionList !== undefined &&
                                this.state.sectionList.map((option) => (
                                  <option
                                    value={option.value}
                                    key={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                            </select>
                          </Col>
                          <Col md="6">
                            <button
                              className="btn btn-primary-fill float-right"
                              onClick={this.handleModalShow}
                            >
                              <FontAwesomeIcon icon={faPlus} /> &nbsp; Send a
                              Message to Teacher
                            </button>
                          </Col>
                        </Row>
                      </div>
                      <Accordion>
                        {this.state.messageList !== undefined &&
                        this.state.messageList.length > 0 ? (
                          this.state.messageList.map((message, index) => {
                            console.log("message,index", message, index);

                            return (
                              <Card key={index}>
                                <Accordion.Toggle
                                  as={Card.Header}
                                  variant="link"
                                  eventKey={message.id}
                                  md="12"
                                  className={
                                    message.viewedByReceiver ||
                                    this.state.messageRead
                                      ? "row-hover pointer_class"
                                      : "row-hover pointer_class message-bold"
                                  }
                                  onClick={() => this.readMessage(message)}
                                >
                                  <Row md="12">
                                    <Col md="4">
                                      <label>
                                        {message.senderDetails.firstName +
                                          " " +
                                          message.senderDetails.lastName}
                                      </label>
                                    </Col>
                                    <Col md="4">
                                      <label>{message.messageSubject}</label>
                                    </Col>
                                    <Col md="4">
                                      <label className="float-right">
                                        Sent:-
                                        <Moment format="MM/DD/YYYY hh:mm:A">
                                          {message.createdAt}
                                        </Moment>
                                      </label>
                                    </Col>
                                  </Row>
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey={message.id}>
                                  <Card.Body>
                                    <Row>
                                      <Col md="10">
                                        <p>{message.message}</p>
                                      </Col>
                                      <Col md="2">
                                        <label className="float-right">
                                          <FontAwesomeIcon
                                            icon={faReply}
                                            onClick={() =>
                                              this.onClickReply(message)
                                            }
                                          />
                                        </label>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            );
                          })
                        ) : (
                          <div className="text-center">
                            <p> No message available for this section</p>
                          </div>
                        )}
                      </Accordion>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <SendMessage
          showModalMessage={this.state.showModalMessage}
          initialValues={this.state.initialValues}
          handleModalHide={this.handleModalHide}
          errorMessage={this.state.errorMessage}
          allTeachersList={this.state.allTeachersList}
          isDisable={this.state.isDisable}
          onSubmit={this.sendMessage}
          previousMessageList={this.state.previousMessageList}
          readMessage={this.readMessage}
        />
      </React.Fragment>
    );
  }
}
