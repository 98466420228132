import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import trimObj from "../components/common/TrimData";
toast.configure();

export default class SendAnnouncement extends Component {
  closeModal = (resetForm) => {
    resetForm();
    this.props.handleModalHide();
  };
  render() {
    const validationSchema = Yup.object().shape({
      schoolSectionId: Yup.string().required("Please select a school section"),
      announcementSubject: Yup.string().required("Please enter subject"),
      announcement: Yup.string().required("Please enter announcement"),
      // .max(500, "announcement can not be more than 500 character")
    });
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={{
            schoolSectionId: "",
            announcementSubject: "",
            announcement: "",
          }}
          onSubmit={(values, actions) => {
            // this function used to trim string before and after
            values = trimObj(values);
            this.props.onSubmit(values, actions);
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal isOpen={this.props.showModalAnnouncement} size="md">
                <ModalHeader>
                  <span>Send An Announcement</span>
                  <div className="modal_popup_close_icon">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => this.closeModal(resetForm)}
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  {this.props.errorMessage !== "" ? (
                    <p className="alert alert-danger">
                      {this.props.errorMessage}
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="2">
                        <label> Section</label>
                      </Col>
                      <Col md="10">
                        <select
                          name="schoolSectionId"
                          className="form-control"
                          onChange={handleChange}
                          value={values.schoolSectionId}
                        >
                          <option value="">Select Section</option>
                          {this.props.userSectionsList !== undefined &&
                            this.props.userSectionsList.map((option) => (
                              <option value={option.value}>
                                {option.label}
                              </option>
                            ))}
                        </select>
                        <label className="err-msg">
                          {errors.schoolSectionId &&
                            touched.schoolSectionId &&
                            errors.schoolSectionId}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="2">
                        <label> Subject</label>
                      </Col>
                      <Col md="10">
                        <input
                          type="text"
                          name="announcementSubject"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.announcementSubject}
                          className="form-control"
                          placeholder="Enter the subject here"
                        />
                        <label className="err-msg">
                          {errors.announcementSubject &&
                            touched.announcementSubject &&
                            errors.announcementSubject}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="12">
                        <textarea
                          placeholder="Enter announcement here"
                          rows={10}
                          cols={55}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="announcement"
                        />
                        <label className="err-msg">
                          {errors.announcement &&
                            touched.announcement &&
                            errors.announcement}
                        </label>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => this.closeModal(resetForm)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="btn btn-primary-fill"
                    onClick={handleSubmit}
                  >
                    Send
                  </Button>
                </ModalFooter>
              </Modal>
            </form>
          )}
        </Formik>
      </>
    );
  }
}
