import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,

} from "reactstrap";

class StudentStartAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  componentDidMount() {
    if (this.props.location.evaluation !== undefined) {
      localStorage.setItem('evaluation', JSON.stringify(this.props.location.evaluation))
      this.setState({
        evaluation: this.props.location.evaluation
      });
    }
    else {
      let evaluation = localStorage.getItem('evaluation')
      if (evaluation) evaluation = JSON.parse(evaluation)
      this.setState({
        evaluation: evaluation
      });
    }

    // this.getEvaluationQuestions();
  }


  render() {
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col lg="12" md="12">
              <Card className="card-box">
                <CardBody>
                  <p>
                    “Lets assess your current understanding of this topic.
                    Please complete the follow 5 questions. Answer the best
                    that you can and yes this is not graded.”
                  </p>
                  <div>
                    <Col md='12'>
                      <Link to={{ pathname: 'assessment-intro', evaluation: this.state.evaluation }} className="btn btn-primary-fill float-right mt-3">Next</Link>
                    </Col>


                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default StudentStartAssessment;
