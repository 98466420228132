import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import trimObj from "../components/common/TrimData";
export default class ScheduleAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      startDate: props.startDate,
      focused: null,
      endDate: props.endDate,
      schoolSectionEvaluationId: "",
    };
  }
  componentDidMount() {
    this.setState({
      startDate: this.props.startDate,
      endDate: this.props.endDate,
    });
  }

  closeModal = () => {
    this.setState({ selectedIndex: 0, startDate: "", endDate: "" });
    this.props.hideScheduleModal();
  };
  handleStarDate = (date) => {
    this.setState({ startDate: date });
  };
  handleEndDate = (date) => {
    this.setState({ endDate: date });
  };
  onSubmit = () => {
    let data = {};
    data["schoolSectionEvaluationId"] = this.props.schoolSectionEvaluationId;
    data["startDate"] = this.state.startDate;
    data["endDate"] = this.state.endDate;
    // this function used to trim string before and after

    this.props.addDate(data);
    setTimeout(() => {
      this.setState({ startDate: "", endDate: "" });
    }, 2000);
  };
  render() {
    return (
      <>
        <Modal isOpen={this.props.showScheduleModal} size="md">
          <ModalHeader>
            <span className="schedule-title">{this.props.tittleModal}</span>
            <div className="modal_popup_close_icon">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => this.closeModal()}
              />
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="form-group form-gp">
              {this.props.errorMessage !== "" ? (
                <p className="alert alert-danger">{this.props.errorMessage}</p>
              ) : (
                ""
              )}
              <Row>
                <Col md="4">
                  <label>Start Date and Time</label>
                </Col>
                <Col md="8" className="input_full_width">
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={(date) => this.handleStarDate(date)}
                    selectsStart
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    showTimeInput
                    timeInputLabel="Time:"
                    placeholderText="Start Date"
                    maxDate={this.state.endDate}
                    dateFormat="MM-dd-yy h:mm aa"
                    className="form-control"
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group form-gp">
              <Row>
                <Col md="4">
                  <label>End Date and Time</label>
                </Col>
                <Col md="8" className="input_full_width">
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={(date) => this.handleEndDate(date)}
                    selectsEnd
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    minDate={this.state.startDate}
                    showTimeInput
                    timeInputLabel="Time:"
                    placeholderText="End Date"
                    dateFormat="MM-dd-yy h:mm aa"
                    className="form-control"
                  />
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" type="button" onClick={this.closeModal}>
              Cancel
            </Button>
            <Button
              type="submit"
              className="btn btn-primary-fill"
              onClick={this.onSubmit}
              disabled={
                this.state.startDate === null || this.state.endDate === null
                  ? true
                  : null
              }
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
