import React from 'react'
import {Switch, Route, Redirect,} from "react-router-dom";
import { getAllowedRoutes, isLoggedIn } from '../../utils';
import PrivateRoutesConfig from "../../config/PrivateRoutesConfig";

// import Breadcrumbs from "../BreadCrumbs";
export default class MainContainer extends React.Component {
    componentDidMount() {
        if (localStorage.getItem('token') === null) {

            this.props.history.push('/');
        }
    }

    render() {
        let allowedRoutes = [];

        if (isLoggedIn()) {
            allowedRoutes = getAllowedRoutes(PrivateRoutesConfig);
        } else {
            return <Redirect to="/" />;
        }
        return (
            <div className={this.props.handleMainClass ? "main-container" : "main-container full"} id="main-container">
                {/*<Breadcrumbs />*/}
                <Switch>
                    {/* <Route exact path="/main" component={Dashboard} /> */}
                    {allowedRoutes.map((route, index) => <Route exact path={route.path} component={route.component} key={index} />)}
                </Switch>
            </div>

        )
    }
}
