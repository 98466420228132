import Roles from "./Roles";
import ClientList from "../admin/clients/ClientList";
import ClientUserList from "../admin/clients/client-users/ClientUserList";
import OmoUsersList from "../admin/omo-users/OmoUsersList";
import SchoolList from "../admin/schools/SchoolList";
import TopicList from "../content-creator/Topic/TopicList";
import QuestionList from "../content-creator/questions/QuestionList";
import schoolSectionList from "../admin/schools/school-sections/SchoolSectionList";
import TeacherDashboard from "../teacher/TeacherDashboard";
import TeacherMessage from "../teacher/TeacherMessage";
import TeacherGrade from "../teacher/TeacherGrade";
import MyProfile from "../profile/MyProfile";
import MyClasses from "../teacher/MyClasses";
import TeacherAnnouncement from "../teacher/TeacherAnnouncement";
import ResetPassword from "../teacher/ResetPassword";
import TeacherCurriculum from "../teacher/TeacherCurriculum";
import ChapterDetails from "../teacher/ChapterDetails";
import TopicDetails from "../teacher/TopicDetails";
import TopicQuestionList from "../content-creator/topic_questions/TopicQuestionList";
import StudentDashboard from "../student/dashboard/StudentDashboard";
import StudentSubject from "../student/subjects/StudentSubject";
import MySections from "../student/studentSection/MySections";
import StudentChapterDetails from "../student/subjects/StudentChapterDetails";
import Announcement from "../student/announcement/Announcements";
import AskYourTeacher from "../student/askTeacher/AskYourTeacher";
import GradeCenter from "../student/grade/GradeCenter";
import CurriculumList from "../content-creator/curriculum/CurriculumList";
import TeacherRewards from "../teacher/TeacherRewards";
import TeacherGradeDetails from "../teacher/TeacherGradeDetails";
import StudentTopicDetails from "../student/subjects/StudentTopicDetails";
import StudentStepWizard from "../student/subjects/StudentStepWizard";
import PracticeList from "../student/subjects/PracticeList";
import PracticeStepWizard from "../student/subjects/PracticeStepWizard";
import QuizStepWizard from "../student/subjects/QuizStepWizard";
import StudentStartAssessment from "../student/subjects/StudentStartAssessment";
import StudentGradeDetails from "../student/grade/StudentGradeDetails";
import StudentRewards from "../student/grade/StudentRewards";
import {
  faUsers,
  faUserCog,
  faHouseUser,
  faUserFriends,
  faUserCircle,
  faUser,
  faHome,
  faBullhorn,
  faBook,
  chartPie,
  faComments,
  faPollH,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";


// TODO:
/*
 * 1. Make title optional
 * 2. Make title multi type support ie: (string, node, react element)
 * */
const PrivateRoutesConfig = [
  {
    component: ClientList,
    path: "/app/clients",
    title: "Clients",
    icon: faUser,
    exact: true,
    permission: [Roles.OMO_ADMIN],
  },
  {
    component: SchoolList,
    path: "/app/schools",
    title: "Schools",
    exact: true,
    icon: faHouseUser,
    permission: [Roles.OMO_ADMIN],
  },

  {
    component: schoolSectionList,
    path: "/app/school-sections",
    title: "School Sections",
    exact: true,
    icon: faUserFriends,
    permission: [Roles.OMO_ADMIN],
  },
  {
    component: ClientUserList,
    path: "/app/client-users",
    title: "Client Users",
    icon: faUsers,
    exact: true,
    permission: [Roles.OMO_ADMIN],
  },
  {
    component: OmoUsersList,
    path: "/app/omo-users",
    title: "OMO Users",
    icon: faUserCircle,
    permission: [Roles.OMO_ADMIN],
  },
  {
    component: TopicList,
    path: "/app/topics",
    title: "Topics",
    icon: faUserFriends,
    permission: [Roles.OMO_CONTENT_CREATOR],
  },

  {
    component: QuestionList,
    path: "/app/questions",
    title: "Question",
    icon: faHouseUser,
    permission: [Roles.OMO_CONTENT_CREATOR],
  },
  {
    component: TopicQuestionList,
    path: "/app/topic_questions",
    title: "Topic Question",
    icon: faUserCog,
    permission: [Roles.OMO_CONTENT_CREATOR],
  },
  {
    component: CurriculumList,
    path: "/app/curriculum",
    title: "Curriculum",
    icon: faUsers,
    permission: [Roles.OMO_CONTENT_CREATOR],
  },
  {
    component: StudentDashboard,
    path: "/app/student_dashboard",
    title: "Dashboard",
    icon: faHome,
    permission: [Roles.STUDENT],
  },
  {
    component: MySections,
    path: "/app/my-sections",
    title: "My Sections",
    icon: faHome,
    hidden: true,
    permission: [Roles.STUDENT],
  },
  {
    component: Announcement,
    path: "/app/announcements",
    title: "Announcements",
    icon: faBullhorn,
    permission: [Roles.STUDENT],
  },
  {
    component: StudentSubject,
    path: "/app/subjects",
    title: "Subjects",
    icon: faBook,
    exact: true,
    permission: [Roles.STUDENT],
  },
  {
    component: StudentChapterDetails,
    title: "Chapter Details",
    hidden: true,
    permission: [Roles.STUDENT],
    path: "/app/subjects/std-chapter-details",
  },
  {
    component: AskYourTeacher,
    path: "/app/ask-your-teacher",
    title: "Ask Your Teacher!",
    icon: faComments,
    permission: [Roles.STUDENT],
  },
  {
    component: GradeCenter,
    path: "/app/grade",
    title: "Grade Center",
    icon: faPollH,
    permission: [Roles.STUDENT],
  },
  {
    component: TeacherDashboard,
    path: "/app/teacher-dashboard",
    title: "Dashboard",
    icon: faHome,
    permission: [Roles.TEACHER],
  },
  {
    component: TeacherAnnouncement,
    path: "/app/announcement",
    title: "Announcement",
    icon: faBullhorn,
    permission: [Roles.TEACHER],
  },
  {
    component: TeacherCurriculum,
    path: "/app/curriculum",
    title: "Curriculum",
    icon: faBook,
    permission: [Roles.TEACHER],
  },
  {
    component: TeacherGrade,
    path: "/app/grade",
    title: "Grades",
    icon: faPollH,
    permission: [Roles.TEACHER],
  },
  {
    component: TeacherMessage,
    path: "/app/direct-message",
    title: "Messages",
    icon: faComments,
    permission: [Roles.TEACHER],
  },
  {
    component: MyProfile,
    path: "/app/my-profile",
    title: "my profile",
    icon: "icon-chart",
    hidden: true,
    permission: [
      Roles.TEACHER,
      Roles.OMO_ADMIN,
      Roles.STUDENT,
      Roles.OMO_CONTENT_CREATOR,
    ],
  },
  {
    component: MyClasses,
    path: "/app/my-classes",
    title: "MyClasses",
    icon: "icon-chart",
    hidden: true,
    permission: [Roles.TEACHER],
  },

  {
    component: ResetPassword,
    path: "/app/reset-password",
    title: "Reset Passoword",
    icon: "icon-chart",
    hidden: true,
    permission: [Roles.TEACHER],
  },
  {
    component: ChapterDetails,
    path: "/app/curriculum/chapter-details",
    title: "Curriculum",
    icon: faBook,
    hidden: true,
    permission: [Roles.TEACHER],
  },
  {
    component: TopicDetails,
    path: "/app/curriculum/topic-details",
    title: "Curriculum",
    icon: faBook,
    hidden: true,
    permission: [Roles.TEACHER],
  },
  {
    component: TeacherRewards,
    path: "/app/teacher-rewards",
    title: "Rewards",
    icon: faTrophy,
    permission: [Roles.TEACHER],
  },
  {
    component: TeacherGradeDetails,
    path: "/app/grade/grade-details/:schoolSectionId/:studentId",
    title: "Grade-details",
    hidden: true,
    icon: faTrophy,
    permission: [Roles.TEACHER],
  },
  {
    component: StudentTopicDetails,
    path: "/app/subjects/std-topic-details",
    title: "Subject",
    icon: faBook,
    hidden: true,
    permission: [Roles.STUDENT],
  },
  {
    component: StudentStartAssessment,
    path: "/app/subjects/start-assessment",
    title: "Start Assessment",
    icon: faBook,
    hidden: true,
    permission: [Roles.STUDENT],
  },
  {
    component: StudentStepWizard,
    path: "/app/subjects/assessment-intro",
    title: "Start Assessment",
    icon: faBook,
    hidden: true,
    permission: [Roles.STUDENT],
  },
  {
    component: PracticeList,
    path: "/app/subjects/start-practice",
    title: "Start Practice",
    icon: faBook,
    hidden: true,
    permission: [Roles.STUDENT],
  },
  {
    component: PracticeStepWizard,
    path: "/app/subjects/practice",
    title: "Practice",
    icon: faBook,
    hidden: true,
    permission: [Roles.STUDENT],
  },
  {
    component: QuizStepWizard,
    path: "/app/subjects/start-quiz",
    title: "Start Quiz",
    icon: faBook,
    hidden: true,
    permission: [Roles.STUDENT],
  },
  {
    component: StudentGradeDetails,
    path: "/app/grade/std-grade-details/:schoolSectionId/:studentId",
    title: "Reward Details",
    icon: faBook,
    hidden: true,
    permission: [Roles.STUDENT],
  },
   {
    component: StudentRewards,
    path: "/app/rewards",
    title: "Rewards",
    icon: faTrophy,
    permission: [Roles.STUDENT],
  },
];

export default PrivateRoutesConfig;
