import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
} from "reactstrap";
import { getSubjectSec } from "../../services/StudentServices";

class StudentSubject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectSec: [],
      sectionList: [],
      selectedSection: "",
      errorMessage: "",
      secChapter: [],
      isLoading: false,
    };
  }
  componentDidMount() {
    this.getStudentSection();
  }
  getStudentSection = () => {
    this.setState({ isLoading: true });
    let getSubData = JSON.parse(localStorage.getItem("user"));
    getSubjectSec(getSubData.id)
      .then((responseUserSections) => {
        console.log("responseUserSections", responseUserSections);
        if (
          responseUserSections !== undefined &&
          responseUserSections.length > 0 &&
          responseUserSections[0]["userRoles"] !== undefined &&
          responseUserSections[0]["userRoles"][0]["userRolesSchoolSections"] !==
          undefined
        ) {
          var sectionList = [];
          responseUserSections[0]["userRoles"][0][
            "userRolesSchoolSections"
          ].map((userSection) => {
            let data = {};
            data["schoolSectionId"] = userSection.schoolSectionId;
            data["grade"] =
              userSection.schoolSection.boardGradeSubject.grade.grade +
              " Grade ";
            data["sec"] = "Section " + userSection.schoolSection.sectionName;
            data["subject"] =
              userSection.schoolSection.boardGradeSubject.subject.subject;

            sectionList.push(data);
          });
          this.setState({ sectionList: sectionList, isLoading: false });
          if (sectionList.length > 0) {
            this.setState({ selectedSection: sectionList[0].value });
          }
        } else {
          this.setState({ isLoading: false, sectionsList: [] });
        }
      })
      .catch((error) => {
        console.log("error in student list", error);
        if (error !== undefined) {
          this.setState({ isLoading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  render() {
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col lg="12" md="12">
              <Card className="card-box">
                <div className="card-header">
                  <Row>
                    <Col md="12">
                      <div className="title">
                        <h4>Subjects</h4>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody>
                  {this.state.isLoading ? (
                    <div className="custom-loader">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <Row>
                      {this.state.sectionList !== undefined &&
                        this.state.sectionList.map((option, i) => (
                          <Col md="4" key={i}>
                           
                            <Link
                              to={{
                                pathname: "/app/subjects/std-chapter-details",
                                chapterDetails: {
                                  sectionChapterId: "",
                                  schoolSectionId: option.schoolSectionId,
                                  chapterId: "",
                                },
                              }}
                              className="subjects"
                              id={option.secId}
                            >
                              <div className="text-center card-box p-4">
                                <h5 className="text-focus">{option.grade}</h5>
                                <div>{option.sec}</div>
                                <div>{option.subject}</div>
                              </div>
                            </Link>
                          </Col>
                        ))}
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default StudentSubject;
