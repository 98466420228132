import React, { Component } from "react";
import { Row, Col, Card, Spinner, CardBody } from "reactstrap";
import {
  getChapterDetailsById,
  getCurriuculumBySection,
  getTopicDetailsById,
  getEvaluationQuestionById,
  addDateAssessment,
} from "../services/TeacherServices";
import { getUserSchoolSections } from "../services/UserServices";
import AssignmentDetails from "./AssignmentDetails";
import ScheduleAssessment from "./ScheduleAssessment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
export default class TopicDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSectionsList: [],
      errorMessage: "",
      isloading: false,
      chapterList: [],
      selectedSection: "",
      selectedChapter: "",
      topicList: [],
      selectedTopic: "",
      topicDetails: [],
      showModalAssessment: false,
      tittleModal: "",
      questionList: [],
      showScheduleModal: false,
      schoolSectionEvaluationId: "",
      errorMessage: "",
      evaluationList: ["Assessment", "Practice", "Quiz"],
      startDate: null,
      endDate: null,
    };
  }
  componentDidMount() {
    //here we are checking that topic details

    let topicDetails = {};
    if (this.props.location.topicDetails !== undefined) {
      localStorage.setItem(
        "topicDetails",
        JSON.stringify(this.props.location.topicDetails)
      );
      topicDetails = this.props.location.topicDetails;
      this.setState(
        {
          selectedSection: topicDetails.sectionId,
          selectedChapter: topicDetails.chapterId,
          selectedTopic: topicDetails.schoolSectionTopicId,
        },
        () => this.getUserSectionList()
      );
    } else {
      topicDetails = localStorage.getItem("topicDetails");
      if (topicDetails) topicDetails = JSON.parse(topicDetails);
      if (topicDetails ===null) {
		this.props.history.push('/app/curriculum')
      } 
	  else {
        this.setState(
          {
            selectedSection: topicDetails.sectionId,
            selectedChapter: topicDetails.chapterId,
            selectedTopic: topicDetails.schoolSectionTopicId,
          },
          () => this.getUserSectionList()
        );
      }
    }
  }
  //this api is used to get user sections
  getUserSectionList = () => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getUserSchoolSections(userData.id)
      .then((responseUserSections) => {
        if (
          responseUserSections !== undefined &&
          responseUserSections.length > 0 &&
          responseUserSections[0]["userRoles"] !== undefined &&
          responseUserSections[0]["userRoles"][0]["userRolesSchoolSections"] !==
            undefined
        ) {
          var userSectionList = [];
          responseUserSections[0]["userRoles"][0][
            "userRolesSchoolSections"
          ].map((userSection) => {
            let data = {};
            data["label"] =
              userSection.schoolSection.boardGradeSubject.grade.grade +
              " Grade " +
              userSection.schoolSection.boardGradeSubject.subject.subject +
              " " +
              userSection.schoolSection.sectionName;
            data["value"] = userSection.schoolSectionId;
            userSectionList.push(data);
          });
          this.setState({
            userSectionsList: userSectionList,
            isloading: false,
          });
          if (userSectionList.length > 0 ) {
			  if(this.state.selectedSection === ""){
				this.setState({ selectedSection: userSectionList[0].value });
				return getCurriuculumBySection(userSectionList[0].value);

			  } else {
				return getCurriuculumBySection(this.state.selectedSection);
			  }
          }
        } else {
          this.setState({ isloading: false, userSectionsList: [] });
        }
      })
      .then((responseCurriculum) => {
        if (responseCurriculum !== undefined) {
          let chapterList = [];
          responseCurriculum.map((chapter) => {
            chapterList.push({
              label: chapter.chapterTitle,
              value: chapter.id,
            });
          });
          this.setState({
            curriculumList: responseCurriculum,
            isloading: false,
            chapterList: chapterList,
          });
          if (chapterList.length > 0  ) {
			  if(this.state.selectedChapter === ""){
				this.setState({selectedChapter:chapterList[0].value})
				this.getChapterDetails(chapterList[0].value);
			
				  
			  }else{
				this.getChapterDetails(this.state.selectedChapter);

			  }
            
          } 
        }
      })
      .catch((error) => {
        console.log("error in section chapterList list", error);
        if (error !== undefined) {
          this.setState({ isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  //this api is callled for chapter details
  getChapterDetails = (chapterId) => {
    this.setState({ isloading: true });
    getChapterDetailsById(chapterId)
      .then((responseChapter) => {
        if (responseChapter !== undefined) {
          let topicList = [];
          responseChapter.map((Chapter) => {
            Chapter.schoolSectionTopics.map((topic) => {
              topicList.push({
                label: topic.topicDisplayTitle,
                value: topic.id,
              });
            });
          });
          this.setState({ topicList: topicList, isloading: false });
          if (topicList.length > 0  ) {
			  if(this.state.selectedTopic === ""){
				  this.setState({selectedTopic:topicList[0].value})
				this.getTopicDetails(topicList[0].value);
			  }
			  else {
				this.getTopicDetails(this.state.selectedTopic);
			  }
          } 
        }
      })
      .catch((error) => {
        console.log("error in  chapter Details", error);
      });
  };
  //this api is called for topic details
  getTopicDetails = (topicId) => {
    this.setState({ isloading: true });
    getTopicDetailsById(topicId)
      .then((responseTopic) => {
        if (responseTopic !== undefined) {
          this.setState({ topicDetails: responseTopic, isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log("error in section chapter Details", error);
      });
  };
  //this function used get evaluation question inside assignment modal
  getEvaluationQuestion = (evaluationId) => {
    this.setState({ isloading: true });
    getEvaluationQuestionById(evaluationId)
      .then((responseEvaluation) => {
        if (responseEvaluation !== undefined) {
          this.setState({
            isloading: false,
            questionList: responseEvaluation,
            showModalAssessment: true,
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log("error in evalution Details", error);
      });
  };
  //this function is used for Section dropdown change
  handleChangeSection = (event) => {
    this.setState({
      selectedSection: event.target.value,
      selectedChapter: "",
      chapterDetailList: [],
      chapterList: [],
      selectedTopic: "",
      topicDetails: [],
      topicList: [],
    });
    this.getCurriuculumBySection(event.target.value);
  };
  //this function is used for Chapter dropdown change
  handleChangeChapter = (event) => {
    this.setState({
      selectedChapter: event.target.value,
      selectedTopic: "",
      topicDetails: [],
      topicList: [],
    });
    this.getChapterDetails(event.target.value);
  };
  //this function is used for topic dropdown change
  handleChangeTopic = (event) => {
    this.setState({ selectedTopic: event.target.value });
    this.getTopicDetails(event.target.value);
  };
  //this function is used to show modal of assigment question details
  showModalAssignment = (topic, filterText) => {
    let evaluation = topic.schoolSectionEvaluations.find(
      (evaluation) => evaluation.evaluationType.evaluationType === filterText
    );
    let section = this.state.userSectionsList.find(
      (section) => section.value === topic.schoolSectionId
    );
    let chapter = this.state.chapterList.find(
      (chapter) => chapter.value === topic.schoolSectionChapterId
    );
    let tittleModal =
      section["label"] +
      "-" +
      chapter["label"] +
      "-" +
      topic.topicDisplayTitle +
      " " +
      evaluation.evaluationType.evaluationType;
    this.getEvaluationQuestion(evaluation.id);
    this.setState({
      showModalAssessment: !this.state.showModalAssessment,
      tittleModal: tittleModal,
    });
  };
  hideAssessmentModal = () => {
    this.setState({ showModalAssessment: !this.state.showModalAssessment });
  };
  // this function is used to show add date option for assessment
  // showScheduleModal = (topic, evaluation, rowData) => {
  // 	let section = this.state.userSectionsList.find(section => section.value == this.state.selectedSection)
  // 	let tittleModal = section['label'] + '-' + rowData['chapterTitle'] + '-' + topic.topicDisplayTitle + ' ' + evaluation.evaluationType.evaluationType
  // 	this.setState({ showScheduleModal: !this.state.showScheduleModal, tittleModal: tittleModal, schoolSectionEvaluationId: evaluation.id })
  // }
  showScheduleModal = (topic, evaluationType) => {
    let evaluation = topic.schoolSectionEvaluations.find(
      (evaluation) =>
        evaluation.evaluationType.evaluationType === evaluationType
    );
    let section = this.state.userSectionsList.find(
      (section) => section.value === topic.schoolSectionId
    );
    let chapter = this.state.chapterList.find(
      (chapter) => chapter.value === topic.schoolSectionChapterId
    );
    let tittleModal =
      section["label"] +
      "-" +
      chapter["label"] +
      "-" +
      topic.topicDisplayTitle +
      " " +
      evaluation.evaluationType.evaluationType;
    this.setState({
      showScheduleModal: !this.state.showScheduleModal,
      tittleModal: tittleModal,
      schoolSectionEvaluationId: evaluation.id,
      startDate:
        evaluation.startDate !== null
          ? new Date(evaluation.startDate)
          : evaluation.startDate,
      endDate:
        evaluation.endDate !== null
          ? new Date(evaluation.endDate)
          : evaluation.endDate,
    });
  };
  //this function is called on save date for assessments
  addDate = (assessmentData) => {
    this.setState({ isloading: true });
    addDateAssessment(assessmentData)
      .then((responseData) => {
        if (responseData !== undefined) {
          this.setState({ isloading: false, showScheduleModal: false });
          this.getUserSectionList();
          toast.success("Added assessment date successfully", {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };
  hideScheduleModal = () => {
    this.setState({ showScheduleModal: !this.state.showScheduleModal });
  };
  getCurriuculumBySection = (sectionId) => {
    this.setState({ isloading: true });
    getCurriuculumBySection(sectionId)
      .then((responseCurriculum) => {
        if (responseCurriculum !== undefined && responseCurriculum.length > 0) {
          let chapterList = [];
          responseCurriculum.map((chapter) => {
            chapterList.push({
              label: chapter.chapterTitle,
              value: chapter.id,
            });
          });
          this.setState({
            curriculumList: responseCurriculum,
            isloading: false,
            chapterList: chapterList,
          });
          if (chapterList.length > 0  ) {
			  if(this.state.selectedChapter === ""){
				  this.setState({selectedChapter:chapterList[0].value})
				this.getChapterDetails(chapterList[0].value);	  
			  }
			  else {
				this.getChapterDetails(this.state.selectedChapter);
			  }
           
          } 
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        console.log("error in curriculum list", error);
        if (error !== undefined) {
          this.setState({ errorMessage: error.message, isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  //this function is for creating markup from discreption
  createMarkup = (data) => {
    return { __html: data };
  };
  // this function is used to show buffer data
  bufferFromBufferString = (data) => {
    let utf8decoder = new TextDecoder();
    let u16arr = new Uint8Array(data);
    let dataPrepare = utf8decoder.decode(u16arr);
    return dataPrepare;
  };

  render() {
   
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row>
                        <Col md="12">
                          <div className="title">
                            <h4> Topic Details</h4>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md="4">
                          <select
                            className="form-control"
                            onChange={this.handleChangeSection}
                            value={this.state.selectedSection}
                          >
                            {this.state.userSectionsList !== undefined &&
                              this.state.userSectionsList.map((option) => (
                                <option value={option.value} key={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        </Col>
                        <Col md="4">
                          <select
                            className="form-control"
                            onChange={this.handleChangeChapter}
                            value={this.state.selectedChapter}
                          >
                            {this.state.chapterList !== undefined &&
                              this.state.chapterList.map((option) => (
                                <option value={option.value} key={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        </Col>
                        <Col md="4">
                          <select
                            className="form-control"
                            onChange={this.handleChangeTopic}
                            value={this.state.selectedTopic}
                          >
                            {this.state.topicList !== undefined &&
                              this.state.topicList.map((option) => (
                                <option value={option.value} key={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      <div>
                        {this.state.isloading ? (
                          <div className="custom-loader">
                            <Spinner animation="border" />
                          </div>
                        ) : (
                          <div>
                            {this.state.topicDetails.map((topic) => {
                              return (
                                <div key={topic.id}>
                                  <Row>
                                    <Col md="12">
                                      <div className="title">
                                        <h4>{topic.topicDisplayTitle}</h4>
                                      </div>
                                    </Col>
                                  </Row>
                                  {topic.schoolSectionEvaluations.map(
                                    (evaluation) => {
                                      return (
                                        <Row className="mt-2">
                                          <Col md="3">
                                            <a
                                              onClick={() =>
                                                this.showModalAssignment(
                                                  topic,
                                                  evaluation.evaluationType
                                                    .evaluationType
                                                )
                                              }
                                              className="view-title mr-2"
                                            >
                                              {"View " +
                                                evaluation.evaluationType
                                                  .evaluationType}
                                            </a>
                                          </Col>
                                          <Col md="6">
                                            {evaluation.startDate === null ? (
                                              <button
                                                className="btn btn-primary-fill  btn-sm"
                                                onClick={() =>
                                                  this.showScheduleModal(
                                                    topic,
                                                    evaluation.evaluationType
                                                      .evaluationType
                                                  )
                                                }
                                              >
                                                Schedule
                                              </button>
                                            ) : (
                                              <span>
                                                {evaluation.startDate !==
                                                null ? (
                                                  <p
                                                    className="view-title pointer_class"
                                                    onClick={() =>
                                                      this.showScheduleModal(
                                                        topic,
                                                        evaluation
                                                          .evaluationType
                                                          .evaluationType
                                                      )
                                                    }
                                                  >
                                                    <Moment format="MM/DD/YYYY hh:mm:A">
                                                      {evaluation.startDate}
                                                    </Moment>{" "}
                                                    To{" "}
                                                    <Moment format="MM/DD/YYYY hh:mm:A">
                                                      {evaluation.endDate}
                                                    </Moment>
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                              </span>
                                            )}
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  )}
                                  <Row>
                                    <Col md="6">
                                      <span
                                        className="fixed-width-span"
                                        dangerouslySetInnerHTML={this.createMarkup(
                                          this.bufferFromBufferString(
                                            topic.description.data
                                          )
                                        )}
                                      ></span>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
            <AssignmentDetails
              showModalAssessment={this.state.showModalAssessment}
              hideAssessmentModal={this.hideAssessmentModal}
              tittleModal={this.state.tittleModal}
              questionList={this.state.questionList}
            />
            {this.state.showScheduleModal && (
              <ScheduleAssessment
                showScheduleModal={this.state.showScheduleModal}
                hideScheduleModal={this.hideScheduleModal}
                tittleModal={this.state.tittleModal}
                errorMessage={this.state.errorMessage}
                addDate={this.addDate}
                schoolSectionEvaluationId={this.state.schoolSectionEvaluationId}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
