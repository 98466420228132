import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import trimObj from "../../components/common/TrimData";
toast.configure();
export default class AddEditSchool extends Component {
  closeModal = (resetForm) => {
    resetForm();
    this.props.handleModalHide();
  };
  render() {
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={this.props.initialValues}
          onSubmit={(values, actions) => {
            values.clientId = this.props.clientId;
            values.addressLine2 =
              values.addressLine2 === "" ? null : values.addressLine2;
            values.addressLine3 =
              values.addressLine3 === "" ? null : values.addressLine3;
            values = trimObj(values);
            this.props.onSubmit(values, actions);
          }}
          validationSchema={Yup.object().shape({
            schoolName: Yup.string().required("Please Enter School Name"),
            addressLine1: Yup.string().required("Please Enter Address Line 1"),
            city: Yup.string().required("Please Enter City"),
            state: Yup.string().required("Please Enter State"),
            pin: Yup.string().required("Please Enter Pin"),
          })}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal isOpen={this.props.showModalSchool} size="lg">
                <ModalHeader>
                  <span>{this.props.titleAddEdit} School</span>
                  <div className="modal_popup_close_icon">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => this.closeModal(resetForm)}
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  {this.props.errorMessage !== "" ? (
                    <p className="alert alert-danger">
                      {this.props.errorMessage}
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> Client Name</label>
                      </Col>
                      <Col md="9">
                        <label> {this.props.clientName}</label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label>School Name</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          name="schoolName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.schoolName}
                          className="form-control"
                          placeholder="School Name"
                        />
                        <label className="err-msg">
                          {errors.schoolName &&
                            touched.schoolName &&
                            errors.schoolName}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> Address Line 1</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          name="addressLine1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.addressLine1}
                          className="form-control"
                          placeholder="Address Line 1"
                        />
                        <label className="err-msg">
                          {errors.addressLine1 &&
                            touched.addressLine1 &&
                            errors.addressLine1}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> Address Line 2</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          name="addressLine2"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.addressLine2}
                          className="form-control"
                          placeholder="Address Line 2"
                        />
                        <label className="err-msg">
                          {errors.addressLine2 &&
                            touched.addressLine2 &&
                            errors.addressLine2}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> Address Line 3</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          name="addressLine3"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.addressLine3}
                          className="form-control"
                          placeholder="Address Line 3"
                        />
                        <label className="err-msg">
                          {errors.addressLine3 &&
                            touched.addressLine3 &&
                            errors.addressLine3}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> City</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          name="city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                          className="form-control"
                          placeholder="City"
                        />
                        <label className="err-msg">
                          {errors.city && touched.city && errors.city}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> State</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          name="state"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.state}
                          className="form-control"
                          placeholder="State"
                        />
                        <label className="err-msg">
                          {errors.state && touched.state && errors.state}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> Pin</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          name="pin"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.pin}
                          className="form-control"
                          placeholder="Pin"
                        />
                        <label className="err-msg">
                          {errors.pin && touched.pin && errors.pin}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> Board</label>
                      </Col>
                      <Col md="9">
                        <select
                          name="boardId"
                          className="form-control"
                          onChange={handleChange}
                          value={values.boardId}
                        >
                          {this.props.boardList !== undefined &&
                            this.props.boardList.map((option) => (
                              <option value={option.value} key={option.value}>
                                {option.label}
                              </option>
                            ))}
                        </select> 
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> Active</label>
                      </Col>
                      <Col md="2">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="isActive"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isActive}
                            defaultChecked={values.isActive}
                            class="form-check-input position-static checkbox-2x c9"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => this.closeModal(resetForm)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="btn btn-primary-fill"
                    onClick={handleSubmit}
                  >
                    {this.props.titleAddEdit}
                  </Button>
                </ModalFooter>
              </Modal>
            </form>
          )}
        </Formik>
      </>
    );
  }
}
