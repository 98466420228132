import React, { Component } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import {
  topicList,
  addTopic,
  updateTopic,
  getAllTopicLevel,
} from "../../services/TopicServices";
import { toast } from "react-toastify";
import AddTopicLevel from "./AddTopicLevel";
import EditTopicLevel from "./EditTopicLevel";
import AddTopicPrereq from "./AddTopicPrereq";
import { filter } from "lodash";
import reactDom from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";

class LevelTopic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topicList: [],
      topicLevelData: {},
      errorMessage: "",
      filterTopicList: [],
      DataPreReqs: [],
      topicName: "",
      topicLevel: "",
      isloading: false,
      showModalAddTopicLevel: false,
      showModalEditTopicLevel: false,
      showModalAddTopicPrereq: false,
      initialValues: {
        topicTitle: "",
        topicDisplayTitle: "",
        videoLink: "",
        description: "",
        level: 1,
        status: "Active",
      },
    };
  }
  componentDidMount() {
    this.getDetail();
    // this.getTopicLevel();
  }

  getDetail = () => {
    const { topicData, topicName, topicLevel } = this.props;
    const filterData = filter([...topicData], { topicTitle: topicName });
    // console.log("filterData", filterData);
    this.setState({
      topicList: topicData,
      topicName: topicName,
      topicLevel: topicLevel,
      filterTopicList: filterData,
      topicId: "",
      topicLevel: "",
    });
  };
  handleModalShow = () => {
    const lastItem =
      this.state.filterTopicList[this.state.filterTopicList.length - 1];
    console.log("lastItem", lastItem);
    this.setState({
      showModalAddTopicLevel: true,
      initialValues: {
        topicTitle: this.state.topicName,
        level: lastItem.level + 1,
        topicDisplayTitle: "",
        videoLink: "",
        description: "",
        status: "Active",
      },
    });
  };
  handleModalHide = () => {
    this.setState({
      showModalAddTopicLevel: false,
      errorMessage: "",
    });
  };

  addTopicLevel = (topicLevelData) => {
    console.log("Add Topic Level", topicLevelData);
    // return;
    this.setState({ isloading: true });
    addTopic(topicLevelData)
      .then((responseTopicLevelData) => {
        if (responseTopicLevelData !== undefined) {
          console.log("responseTopicLevelData", responseTopicLevelData);
          //   return;
          this.setState({
            isloading: false,
            showModalAddTopicLevel: false,
            filterTopicList: [
              ...this.state.filterTopicList,
              responseTopicLevelData,
            ],
            errorMessage: "",
          });
          toast.success(responseTopicLevelData.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            isloading: false,
          });
        } else {
          this.setState({
            errorMessage: error.message,
            isloading: false,
          });
        }
      });
  };

  handleEditModalHide = () => {
    this.setState({
      topicLevelData: {},
      showModalEditTopicLevel: false,
    });
  };

  editTopicLevel = (data) => {
    // console.log("Data", data);
    this.setState({
      topicLevelData: data,
      showModalEditTopicLevel: true,
    });
  };

  updateTopicLevel = (data, id, actions) => {
    console.log("Update Data", data);
    console.log("Update ID", id);
    // return;
    this.setState({ isloading: true });
    updateTopic(id, data)
      .then((responseUpdateTopic) => {
        if (responseUpdateTopic !== undefined) {
          console.log("responseUpdateTopic", responseUpdateTopic);
          const elementsIndex = this.state.filterTopicList.findIndex(
            (topic) => topic.id == responseUpdateTopic.data.id
          );
          let updateTopic = [...this.state.filterTopicList];
          updateTopic[elementsIndex] = responseUpdateTopic.data;
          this.setState({
            isloading: false,
            showModalEditTopicLevel: false,
            topicLevelData: {},
            filterTopicList: updateTopic,
          });
          toast.success("Topic detail updated successfully", {
            autoClose: 3000,
            hideProgressBar: true,
          });
          actions.resetForm();
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message, isloading: false });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };

  addTopicPrereq = (e, topicId, topicLevel) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("addTopicPrereq");
    this.setState({
      showModalAddTopicPrereq: true,
      topicId: topicId,
      topicLevel: topicLevel,
    });
  };

  hideModalAddTopicPrereq = () => {
    this.setState({
      showModalAddTopicPrereq: false,
    });
    // window.location.reload();
    this.getAllTopicLevel();
  };

  getAllTopicLevel = () => {
    // return;
    this.setState({ isloading: true });
    getAllTopicLevel(this.state.topicName)
      .then((response) => {
        if (response !== undefined) {
          console.log("response", response);
          this.setState({
            filterTopicList: response.rows,
          });
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            isloading: false,
          });
        } else {
          this.setState({
            errorMessage: error.message,
            isloading: false,
          });
        }
      });
  };

  render() {
    const { topicList, topicName, filterTopicList, topicLevel } = this.state;

    return (
      <React.Fragment>
        <Card className="card-box">
          <div className="card-header">
            <Row>
              <Col md="6">
                <div className="title">Topics</div>
              </Col>
              <Col md="6">
                <button
                  className="btn btn-primary-fill float-right mt-3"
                  onClick={this.handleModalShow}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp; Add Topic Level
                </button>
              </Col>
            </Row>
          </div>
          <CardBody>
            <div className="detail_topic_name">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a onClick={(e) => this.props.backToTopics(e)} href="#">
                      Topics
                    </a>
                  </li>
                  <li className="breadcrumb-item active">{topicName}</li>
                </ol>
              </nav>
            </div>
            <div className="react-bootstrap-table">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Level</th>
                    <th>Display Topic Title</th>
                    <th>Status</th>
                    <th>Pre-Requisite Topic Level</th>
                  </tr>
                </thead>
                <tbody>
                  {filterTopicList.map((list) => {
                    // console.log("Level List", list);
                    return (
                      <React.Fragment>
                        <tr
                          key={list.id}
                          className="row-hover pointer_class"
                          onClick={(e) => this.editTopicLevel(list)}
                        >
                          <td>{list.level}</td>
                          <td>{list.topicDisplayTitle}</td>
                          <td>{list.status}</td>
                          <td>
                            <div className="topicPreReqs_parent">
                              <div className="topicPreReqs_list">
                                {list.topicPreReqs.map((PreReqs) => {
                                  return (
                                    <React.Fragment>
                                      <p className="topicPreReqs">
                                        {
                                          PreReqs.preReqTopicDetail
                                            .topicDisplayTitle
                                        }{" "}
                                        - Level{" "}
                                        {PreReqs.preReqTopicDetail.level}
                                      </p>
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                              <button
                                className="btn-primary-fill"
                                onClick={(e) =>
                                  this.addTopicPrereq(e, list.id, list.level)
                                }
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
        {this.state.showModalAddTopicLevel ? (
          <AddTopicLevel
            showModalAddTopic={this.state.showModalAddTopicLevel}
            handleModalHide={this.handleModalHide}
            onSubmit={this.addTopicLevel}
            errorMessage={this.state.errorMessage}
            initialValues={this.state.initialValues}
            addTopicPrereq={this.addTopicPrereq}
          />
        ) : null}
        {this.state.showModalEditTopicLevel ? (
          <EditTopicLevel
            showModalEditTopic={this.state.showModalEditTopicLevel}
            handleEditModalHide={this.handleEditModalHide}
            onSubmit={this.updateTopicLevel}
            errorMessage={this.state.errorMessage}
            initialValues={this.state.topicLevelData}
            addTopicPrereq={this.addTopicPrereq}
          />
        ) : null}
        {this.state.showModalAddTopicPrereq ? (
          <AddTopicPrereq
            showModalAddTopicPrereq={this.state.showModalAddTopicPrereq}
            handleModalHide={this.hideModalAddTopicPrereq}
            topicName={topicName}
            topicId={this.state.topicId}
            topicLevel={this.state.topicLevel}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default LevelTopic;
