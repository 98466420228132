import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import {
  topicList,
  searchTopic,
  getTopicPreReq,
  deleteTopicPreReq,
  getAllTopicWithoutLevel,
  getAllLevelforTopic,
  addTopicPreReq,
} from "../../services/TopicServices";
import { Formik } from "formik";
import * as Yup from "yup";
import { some } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

toast.configure();
export default class AddTopicPrereq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      topicList: [],
      errorMessage: "",
      topicSearch: "",
      checkedList: [],
      topicPreReq: [],
      errorMessage: "",
      initialValues: {
        topicId: "",
      },
      AllTopicWithoutLevel: [],
      AllLevel: [],
      topicTitle: "",
      topicLevel: "",
      enableLevel: true,
      AddTopicPrereq: true,
    };
  }

  componentDidMount() {
    this.getTopicPrereq();
    this.getTopicList();
  }

  getTopicList = () => {
    this.setState({ isloading: true });
    getAllTopicWithoutLevel()
      .then((responseTopicList) => {
        if (responseTopicList !== undefined) {
          console.log("responseTopicList Add Pre", responseTopicList);
          this.setState({
            AllTopicWithoutLevel: responseTopicList.rows,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ errorMessage: error.message, isloading: false });
      });
  };

  getTopicPrereq = () => {
    this.setState({ isloading: true });
    getTopicPreReq(this.props.topicId)
      .then((responseTopicPreReq) => {
        if (responseTopicPreReq !== undefined) {
          console.log("responseTopicPreReq", responseTopicPreReq);
          this.setState({
            topicPreReq: responseTopicPreReq,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ errorMessage: error.message, isloading: false });
      });
  };

  handleTopicTitle = (event) => {
    const target = event.target;
    const name = target.name;
    this.setState({
      [name]: event.target.value,
    });

    getAllLevelforTopic(event.target.value)
      .then((response) => {
        if (response !== undefined) {
          console.log("Response getAllLevelforTopic", response);
          this.setState({
            AllLevel: response.rows,
            enableLevel: false,
            AddTopicPrereq: true,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ isloading: false });
      });
  };

  handleLevel = (event) => {
    const target = event.target;
    const name = target.name;
    this.setState({
      [name]: event.target.value,
      AddTopicPrereq: false,
    });
    console.log("handleLevel Data", event.target.value);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    console.log("On Submit Value");
    const data = {
      topicId: this.props.topicId,
      topicPreReqId: [
        {
          id: this.state.level,
        },
      ],
    };
    console.log("Prepare Data", data);
    let allLevel = [...this.state.AllLevel];
    let topicPreReq = [...this.state.topicPreReq];
    console.log("Getting ALl level data", allLevel);
    console.log("Getting ALl level data", topicPreReq);
    // return;
    addTopicPreReq(data)
      .then((response) => {
        if (response !== undefined) {
          console.log("Add Pre Topic Response", response);
          let obj = allLevel.find((o) => o.id == this.state.level);
          let objassign = { ...obj };
          obj.preReqTopicDetail = objassign;
          console.log("response object", obj);
          this.setState({
            topicPreReq: [...this.state.topicPreReq, obj],
          });
          toast.success(response.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ errorMessage: error.message, isloading: false });
      });
  };

  handleDelete = (e, topicPreReqId, id) => {
    e.preventDefault();
    console.log("topicPreReqId", topicPreReqId);
    console.log("topicPreReqId", id);

    this.setState({ isloading: true });
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure  you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.deletePreReq(id);
          },
        },
        {
          label: "No",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  deletePreReq = (id) => {
    console.log("deletePreReq id", id);
    deleteTopicPreReq(id)
      .then((response) => {
        if (response !== undefined) {
          console.log("Delete response TopicList Add Pre", response);
          var topicPreReq = [...this.state.topicPreReq];
          const updatetopicPreReq = topicPreReq.filter(
            (item) => item.id !== id
          );
          this.setState({
            topicPreReq: updatetopicPreReq,
            errorMessage: response.message,
          });

          toast.success(response.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ errorMessage: error.message, isloading: false });
      });
  };

  render() {
    const { topicName, topicLevel, DataPreReqs } = this.props;
    console.log("Get AllLevel", this.state.AllLevel);
    return (
      <>
        <Modal isOpen={this.props.showModalAddTopicPrereq} size="lg">
          <ModalHeader>
            <h4>Add Pre-requisites</h4>
            <div className="modal_popup_close_icon">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={this.props.handleModalHide}
              />
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="form-group form-gp">
              <Row>
                <Col md="12">
                  <label className="AddTopicPrereqTitle">
                    {/* {topicName} - Level-{topicLevel} */}
                  </label>
                </Col>
              </Row>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group form-gp">
                <Row>
                  <Col md="3">
                    <label>Topic Title</label>
                  </Col>
                  <Col md="9">
                    <select
                      name="topicTitle"
                      value={this.state.topicTitle}
                      onChange={this.handleTopicTitle}
                      className="form-control"
                    >
                      <option>Please Select Topic Title</option>
                      {this.state.AllTopicWithoutLevel.map((option) => {
                        return (
                          <>
                            <option
                              key={option.topicTitle}
                              value={option.topicTitle}
                              label={option.topicTitle}
                            />
                          </>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group form-gp">
                <Row>
                  <Col md="3">
                    <label>Level</label>
                  </Col>
                  <Col md="9">
                    <select
                      name="level"
                      value={this.state.level}
                      onChange={this.handleLevel}
                      className="form-control"
                      disabled={this.state.enableLevel}
                    >
                      <option selected="selected">Please Select Level</option>
                      {this.state.AllLevel.map((option) => {
                        return (
                          <>
                            <option
                              key={option.id}
                              value={option.id}
                              label={option.level}
                            />
                          </>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group form-gp">
                <Row>
                  <Col md="12">
                    <div className="pull-right">
                      <Button
                        type="submit"
                        className="btn btn-primary-fill"
                        color="success"
                        onClick={this.handleSubmit}
                        disabled={this.state.AddTopicPrereq}
                      >
                        Add Pre-Requisite
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
            <div className="form-group form-gp">
              <Row>
                <Col md="12">
                  <h4>Pre-requisites List</h4>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div className="react-bootstrap-table AddTopicPrereqList">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            style={{
                              width: "200px",
                            }}
                          >
                            Topic Title
                          </th>
                          <th
                            style={{
                              width: "100px",
                            }}
                          >
                            Level
                          </th>
                          <th>Display Topic Title</th>
                          <th
                            style={{
                              width: "100px",
                            }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.topicPreReq.map((list, index) => {
                          console.log("topicPreReq list", list);
                          return (
                            <React.Fragment>
                              <tr key={list.id}>
                                <td>{list.preReqTopicDetail.topicTitle}</td>
                                <td>{list.preReqTopicDetail.level}</td>
                                <td>
                                  {list.preReqTopicDetail.topicDisplayTitle}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-primary-fill"
                                    onClick={(e) =>
                                      this.handleDelete(
                                        e,
                                        list.topicPreReqId,
                                        list.id
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
