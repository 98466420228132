import Api from '../Api'

export const addUser=(userData) => {
    return  Api.post('user/register',userData).then(res=>res.data)
 }
 export const updateUser=(id,userData) => {
    return  Api.put('user/'+id,userData).then(res=>res.data)
 }
export const addUserRole=(userData) => {
   return  Api.post('user/addadditionalroles',userData).then(res=>res.data)
}
export const getUserSchoolSections=(id) => {
   return  Api.get('user/getschoolsections/'+id).then(res=>res.data)
}
export const getUsersBySection=(id) => {
   return  Api.get('user/getbyschoolsection/'+id+'?page=1&size=1000').then(res=>res.data)
}
export const getMessageListBySubject=(subject,userId,schoolSectionId) => {
   return  Api.get('directmessage/getdirectmessagelistbysubject/'+subject+'/'+userId+'/'+schoolSectionId+'?page=1&size=1000').then(res=>res.data)
}
export const getUserDetails=(id) => {
   return  Api.get('user/'+id).then(res=>res.data)
}
export const addSectionClientUser=(userData) => {
   return  Api.post('user/addschoolsection',userData).then(res=>res.data)
}
export const getUserListBySections=(id) => {
   return  Api.get('user/getbyschoolsection/'+id+'page=1&size=1000').then(res=>res.data)
}
