import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import trimObj from "../../../components/common/TrimData";
toast.configure();
export default class AddEditSchoolSection extends Component {
  closeModal = (resetForm) => {
    resetForm();
    this.props.handleModalHide();
  };
  render() {
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={this.props.initialValues}
          onSubmit={(values, actions) => {
			values = trimObj(values);
            this.props.onSubmit(values, actions);
          }}
          validationSchema={Yup.object().shape({
            sectionName: Yup.string().required(
              "Please Enter School Section Name"
            ),
            gradeId: Yup.string().required("Please Select A Grade"),
            boardGradeSubjectId: Yup.string().required(
              "Please Select Grade And Subject"
            ),
          })}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            setFieldValue,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal isOpen={this.props.showModalSchoolSection} size="md">
                <ModalHeader>
                  <span>{this.props.titleAddEdit} School Section</span>
                  <div className="modal_popup_close_icon">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => this.closeModal(resetForm)}
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  {this.props.errorMessage !== "" ? (
                    <p className="alert alert-danger">
                      {this.props.errorMessage}
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> Client Name</label>
                      </Col>
                      <Col md="9">
                        <label> {this.props.clientName}</label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> School Name</label>
                      </Col>
                      <Col md="9">
                        <label> {this.props.schoolName}</label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> Grade</label>
                      </Col>
                      <Col md="9">
                        <select
                          name="gradeId"
                          className="form-control"
                          onChange={(e) => {
                            this.props.handleChangeGrade(e);
                            setFieldValue("gradeId", e.target.value);
                          }}
                          value={values.gradeId}
                          disabled={
                            this.props.titleAddEdit === "Update" ? true : null
                          }
                        >
                          <option value="">Select Grade</option>
                          {this.props.gradeList !== undefined &&
                            this.props.gradeList.map((option) => (
                              <option value={option.value}>
                                {option.label}
                              </option>
                            ))}
                        </select>
                        <label className="err-msg">
                          {errors.gradeId && touched.gradeId && errors.gradeId}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> Subject</label>
                      </Col>
                      <Col md="9">
                        <select
                          name="boardGradeSubjectId"
                          className="form-control"
                          onChange={handleChange}
                          value={values.boardGradeSubjectId}
                          disabled={
                            this.props.titleAddEdit === "Update" ? true : null
                          }
                        >
                          <option value="">Select Subject</option>
                          {this.props.subjectList !== undefined &&
                            this.props.subjectList.map((option) => (
                              <option value={option.boardGradeSubjectId}>
                                {option.label}
                              </option>
                            ))}
                        </select>
                        <label className="err-msg">
                          {errors.boardGradeSubjectId &&
                            touched.boardGradeSubjectId &&
                            errors.boardGradeSubjectId}
                        </label>
                      </Col>
                    </Row>
                  </div>

                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> Section Name</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          name="sectionName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.sectionName}
                          className="form-control"
                          placeholder="Section Name"
                        />
                        <label className="err-msg">
                          {errors.sectionName &&
                            touched.sectionName &&
                            errors.sectionName}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> Active</label>
                      </Col>
                      <Col md="2">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="isActive"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isActive}
                            defaultChecked={values.isActive}
                            class="form-check-input position-static checkbox-2x c9"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => this.closeModal(resetForm)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="btn btn-primary-fill"
                    onClick={handleSubmit}
                  >
                    {this.props.titleAddEdit}
                  </Button>
                </ModalFooter>
              </Modal>
            </form>
          )}
        </Formik>
      </>
    );
  }
}
