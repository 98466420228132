import React from 'react';
import { BrowserRouter as Router,Route, Switch } from "react-router-dom";
import './App.css';
import Login from './auth/Login'
import Main from './components/common/Main'

function App() {
  return (
  <React.Fragment>
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/app" component={Main} />
      </Switch>
    </Router>
  </React.Fragment>
);
}

export default App;
