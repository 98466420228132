import React, { Component } from "react";
import { getAnnouncement } from "../../services/StudentServices";
import { getUserSchoolSections } from "../../services/UserServices";
import { Container, Row, Col, Spinner } from "reactstrap";
import { Accordion, Card } from "react-bootstrap";
import Moment from "react-moment";

export default class Announcements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      announcementList: [],
      sectionList: [],
      selectedSection: "",
      errorMessage: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getUserSectionList();
  }

  getUserSectionList = () => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    console.log(userData);
    getUserSchoolSections(userData.id)
      .then((responseUserSections) => {
        console.log("responseUserSections", responseUserSections);
        if (
          responseUserSections !== undefined &&
          responseUserSections.length > 0 &&
          responseUserSections[0]["userRoles"] !== undefined &&
          responseUserSections[0]["userRoles"][0]["userRolesSchoolSections"] !==
            undefined
        ) {
          var sectionList = [];
          responseUserSections[0]["userRoles"][0][
            "userRolesSchoolSections"
          ].map((userSection) => {
            let data = {};
            data["label"] =
              userSection.schoolSection.boardGradeSubject.grade.grade +
              " Grade " +
              userSection.schoolSection.boardGradeSubject.subject.subject +
              " " +
              userSection.schoolSection.sectionName;
            data["value"] = userSection.schoolSectionId;
            sectionList.push(data);
            console.log(sectionList);
          });
          this.setState({ sectionList: sectionList });
          if (sectionList.length > 0) {
            this.setState({ selectedSection: sectionList[0].value });
            return getAnnouncement(sectionList[0].value);
          }
        } else {
          this.setState({ isloading: false, userSectionsList: [] });
        }
      })
      .then((responseAnnouncement) => {
        console.log(responseAnnouncement);
        if (responseAnnouncement !== undefined) {
          //console.log('responseAnnouncement',responseAnnouncement,)
          this.setState({
            announcementList: responseAnnouncement.rows,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error in section announcement list", error);
        if (error !== undefined) {
          this.setState({ isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  getAnnouncementBySection = (sectionId) => {
    this.setState({ isloading: true });
    getAnnouncement(sectionId)
      .then((responseAnnouncement) => {
        if (responseAnnouncement !== undefined) {
          console.log("responseAnnouncement", responseAnnouncement);
          this.setState({
            announcementList: responseAnnouncement.rows,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error in student announcement list", error);
        if (error !== undefined) {
          this.setState({ errorMessage: error.message, isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  handleChangeSection = (event) => {
    this.setState({ selectedSection: event.target.value });
    this.getAnnouncementBySection(event.target.value);
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col lg="12" md="12">
              <Card className="card-box">
                <div className="card-header">
                  <Row>
                    <Col md="6">
                      <div className="title">
                        <h4>Announcement</h4>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-body">
                  {this.state.isloading ? (
                    <div className="custom-loader">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <div>
                      <div className="form-group form-gp">
                        <Row>
                          <Col md="2">
                            <label> Select Section</label>
                          </Col>
                          <Col md="4">
                            <select
                              className="form-control"
                              onChange={this.handleChangeSection}
                              value={this.state.selectedSection}
                            >
                              {this.state.sectionList !== undefined &&
                                this.state.sectionList.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                            </select>
                          </Col>
                        </Row>
                      </div>
                      <Accordion className="mt-5">
                        {this.state.announcementList !== undefined &&
                        this.state.announcementList.length > 0 ? (
                          this.state.announcementList.map(
                            (announcement, index) => {
                              return (
                                <Card key={index}>
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    variant="link"
                                    eventKey={announcement.id}
                                    md="12"
                                    className="row-hover pointer_class"
                                  >
                                    <Row md="12">
                                      <Col md="6">
                                        <label className="text-left">
                                          {announcement.announcementSubject}
                                        </label>
                                      </Col>
                                      <Col md="6">
                                        <label className="float-right">
                                          <Moment format="MM/DD/YYYY hh:mm:A">
                                            {announcement.createdAt}
                                          </Moment>
                                        </label>
                                      </Col>
                                    </Row>
                                  </Accordion.Toggle>

                                  <Accordion.Collapse
                                    eventKey={announcement.id}
                                  >
                                    <div className="card-body">
                                      {announcement.announcement}
                                    </div>
                                  </Accordion.Collapse>
                                </Card>
                              );
                            }
                          )
                        ) : (
                          <div className="text-center">
                            <p> No announcement available for this section</p>
                          </div>
                        )}
                      </Accordion>
                    </div>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
