import React, { Component } from "react";
import { Row, Col, Card, Spinner, CardBody } from "reactstrap";
import { getUserSchoolSections, } from "../../services/UserServices";
import { Link } from "react-router-dom";
import { getStudentCurriculum, getChapterDetail } from "../../services/StudentServices"
export default class StudentChapterDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSectionsList: [],
      errorMessage: "",
      isloading: false,
      chapterList: [],
      selectedSection: "",
      selectedChapter: "",
      chapterDetailList: [],
      sectionChapterId: "",
    };
  }
  componentDidMount() {
    if (this.props.location.chapterDetails !== undefined) {
      this.setState({
        selectedSection: this.props.location.chapterDetails.schoolSectionId,
        selectedChapter:this.props.location.chapterDetails.chapterId
      },()=>{
        this.getUserSectionList();
      });
    }else{
      this.getUserSectionList();
    } 
  }
  getUserSectionList = () => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getUserSchoolSections(userData.id)
      .then((responseUserSections) => {
        if (
          responseUserSections !== undefined &&
          responseUserSections.length > 0 &&
          responseUserSections[0]["userRoles"] !== undefined &&
          responseUserSections[0]["userRoles"][0]["userRolesSchoolSections"] !==
          undefined
        ) {
          var userSectionList = [];
          responseUserSections[0]["userRoles"][0][
            "userRolesSchoolSections"
          ].map((userSection) => {
            let data = {};
            data["label"] =
              userSection.schoolSection.boardGradeSubject.grade.grade +
              " Grade " +
              userSection.schoolSection.boardGradeSubject.subject.subject +
              " " +
              userSection.schoolSection.sectionName;
            data["value"] = userSection.schoolSectionId;
            userSectionList.push(data);
          });
          this.setState({ userSectionsList: userSectionList });
          if (userSectionList.length > 0 ) {
           
            if(this.state.selectedSection === ""){
              this.setState({ selectedSection: userSectionList[0].value });
              return getStudentCurriculum(userSectionList[0].value);
            }
            else {
              return getStudentCurriculum(this.state.selectedSection);
            }
            
          } 
        } else {
          this.setState({ isloading: false, userSectionsList: [] });
        }
      })
      .then((responseCurriculum) => {
        if (responseCurriculum !== undefined) {
          let chapterList = [];
          responseCurriculum.map((chapter) => {
            chapterList.push({
              label: chapter.chapterTitle,
              value: chapter.id,
            });
          });
          this.setState({
            curriculumList: responseCurriculum,
            isloading: false,
            chapterList: chapterList,
          });
          if (chapterList.length > 0) {
            if(this.state.selectedChapter===''){
              this.getChapterDetails(chapterList[0].value);
            }else{
              this.getChapterDetails(this.state.selectedChapter);
            }
            
          }
          else{
            this.setState({showNoChapter:true})
          }
        }
      })
      .catch((error) => {
        console.log("error in section chapterList list", error);
        if (error !== undefined) {
          this.setState({ isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  getChapterDetails = (chapterId) => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getChapterDetail(chapterId, userData.id)
      .then((responseChapter) => {
        if (responseChapter !== undefined) {
          this.setState({
            chapterDetailList: responseChapter,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error in section chapter Details", error);
      });
  };
  handleChangeSection = (event) => {
    this.setState({
      selectedSection: event.target.value,
      selectedChapter: "",
      chapterDetailList: [],
      chapterList: [],
    });
    this.getCurriuculumBySection(event.target.value);
  };
  handleChangeChapter = (event) => {
    this.setState({ selectedChapter: event.target.value });
    this.getChapterDetails(event.target.value);
  };
  getCurriuculumBySection = (sectionId) => {
    this.setState({ isloading: true });
    getStudentCurriculum(sectionId)
      .then((responseCurriculum) => {
        if (responseCurriculum !== undefined && responseCurriculum.length > 0) {
          let chapterList = [];
          responseCurriculum.map((chapter) => {
            chapterList.push({
              label: chapter.chapterTitle,
              value: chapter.id,
            });
          });
          this.setState({
            curriculumList: responseCurriculum,
            isloading: false,
            chapterList: chapterList,
          });
          if (chapterList.length > 0) {
            if(this.state.selectedChapter===''){
              this.getChapterDetails(chapterList[0].value);
            }else{
              this.getChapterDetails(this.state.selectedChapter);
            }
          }
          else{
            this.setState({showNoChapter:true})
          }
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        console.log("error in curriculum list", error);
        if (error !== undefined) {
          this.setState({ errorMessage: error.message, isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  // this function is used to show buffer data
  bufferFromBufferString = (data) => {
    let utf8decoder = new TextDecoder();
    let u16arr = new Uint8Array(data);
    let dataPrepare = utf8decoder.decode(u16arr);
    return dataPrepare;
  };

  createMarkup = (data) => {
    return { __html: data };
  };
  render() {
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row>
                        <Col md="12">
                          <div className="title">
                            <h4> Chapter Details</h4>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md="4">
                          <select
                            className="form-control"
                            onChange={this.handleChangeSection}
                            value={this.state.selectedSection}
                          >
                            {this.state.userSectionsList !== undefined &&
                              this.state.userSectionsList.map((option) => (
                                <option value={option.value} key={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        </Col>
                        <Col md="4">
                          <select
                            className="form-control"
                            onChange={this.handleChangeChapter}
                            value={this.state.selectedChapter}
                          >
                            {this.state.chapterList !== undefined &&
                              this.state.chapterList.map((option) => (
                                <option value={option.value} key={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      {this.state.isloading ? (
                        <div className="custom-loader">
                          <Spinner animation="border" />
                        </div>
                      ) : (
                        <div>
                          {this.state.chapterDetailList.map((chapter) => {
                            return (
                              <div>
                                <Row>
                                  <Col md="6">
                                    <div className="title">
                                      <h4>{chapter.chapterTitle}</h4>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="6">
                                    <span
                                      className='fixed-width-span'
                                      dangerouslySetInnerHTML={this.createMarkup(
                                        this.bufferFromBufferString(chapter.description.data)
                                      )}
                                    ></span>
                                  </Col>
                                </Row>
                                <div>
                                  {chapter.schoolSectionTopics.map((topic) => {
                                    return (
                                      <div key={topic.id}>
                                        <br></br>
                                        <Link
                                          to={{
                                            pathname: "/app/subjects/std-topic-details",
                                            topicDetails: {
                                              schoolSectionTopicId: topic.id,
                                              sectionId:
                                                this.state.selectedSection,
                                              chapterId:
                                                this.state.selectedChapter,
                                            },
                                          }}
                                          className="view-title"
                                        >
                                          {topic.topicDisplayTitle}
                                        </Link>
                                        <br></br>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}
