import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { QuestionType } from "../../services/QuestionServices";
import { Switch } from "react-router";

import SunEditor, { buttonList } from "suneditor-react";
import image from "suneditor/src/plugins/dialog/link";
import list from "suneditor/src/plugins/submenu/list";
import { font, video } from "suneditor/src/plugins";

import plugins from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";
import trimObj from "../../components/common/TrimData";
toast.configure();
export default class AddQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionType: null,
      editorContent: "",
      editorContentAnswer: "",
    };
  }
  closeModal = (resetForm) => {
    resetForm();
    this.props.handleModalHide();
  };

  handleQuestionType = (e, setFieldValue) => {
    let getValue = e.target.value;
    console.log("Get Value", getValue);
    if (getValue === "") {
      this.setState({
        questionType: null,
      });
    } else {
      this.setState({
        questionType: getValue,
      });
    }
  };

  handleChangeAnswer = (e, setFieldValue, values) => {
    setFieldValue(`answers.${0}.isCorrectOption`, true);
    setFieldValue(`answers.${1}.isCorrectOption`, false);
  };
  handleChangeAnswer2 = (e, setFieldValue, values) => {
    setFieldValue(`answers.${1}.isCorrectOption`, true);
    setFieldValue(`answers.${0}.isCorrectOption`, false);
  };

  handleChangeMultipleChooseOne = (e, setFieldValue, values) => {
    setFieldValue(`answers.${0}.isCorrectOption`, true);
    setFieldValue(`answers.${1}.isCorrectOption`, false);
    setFieldValue(`answers.${2}.isCorrectOption`, false);
    setFieldValue(`answers.${3}.isCorrectOption`, false);
  };

  handleChangeMultipleChooseOne1 = (e, setFieldValue, values) => {
    setFieldValue(`answers.${0}.isCorrectOption`, false);
    setFieldValue(`answers.${1}.isCorrectOption`, true);
    setFieldValue(`answers.${2}.isCorrectOption`, false);
    setFieldValue(`answers.${3}.isCorrectOption`, false);
  };

  handleChangeMultipleChooseOne2 = (e, setFieldValue, values) => {
    setFieldValue(`answers.${0}.isCorrectOption`, false);
    setFieldValue(`answers.${1}.isCorrectOption`, false);
    setFieldValue(`answers.${2}.isCorrectOption`, true);
    setFieldValue(`answers.${3}.isCorrectOption`, false);
  };

  handleChangeMultipleChooseOne3 = (e, setFieldValue, values) => {
    setFieldValue(`answers.${0}.isCorrectOption`, false);
    setFieldValue(`answers.${1}.isCorrectOption`, false);
    setFieldValue(`answers.${2}.isCorrectOption`, false);
    setFieldValue(`answers.${3}.isCorrectOption`, true);
  };

  filterFields = (value, setFieldValue) => {
    if (value == 1) {
      setFieldValue("answers", [
        { optionText: "", isCorrectOption: false },
        { optionText: "", isCorrectOption: false },
      ]);
    } else if (value == 4 || value == 5) {
      setFieldValue("answers", [{ optionText: "", isCorrectOption: false }]);
    } else {
      setFieldValue("answers", [
        { optionText: "", isCorrectOption: false },
        { optionText: "", isCorrectOption: false },
        { optionText: "", isCorrectOption: false },
        { optionText: "", isCorrectOption: false },
      ]);
    }
    setFieldValue("questionTypeId", value);
  };

  handleEditorChange = (content, setFieldValue) => {
    console.log(content);
    this.setState(
      {
        editorContent: content,
      },
      () => {
        if (content === "<p><br></p>" || content === "<p></p>") {
          setFieldValue("questionText", "");
        } else {
          setFieldValue("questionText", content);
        }
      }
    );
  };
  handleEditorChangeAnswer = (content, setFieldValue) => {
    console.log(content);
    this.setState(
      {
        editorContentAnswer: content,
      },
      () => {
        if (content === "<p><br></p>" || content === "<p></p>") {
          setFieldValue("answerExplanation", "");
        } else {
          setFieldValue("answerExplanation", content);
        }
      }
    );
  };

  render() {
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={this.props.initialValues}
          onSubmit={(values, actions) => {
            values.questionText = this.state.editorContent;
            values.answerExplanation = this.state.editorContentAnswer;
               // this function used to trim string before and after
               values = trimObj(values);
            this.props.onSubmit(values, actions);
          }}
          validationSchema={Yup.object().shape({
            questionText: Yup.string().required("Please Enter Question"),
            answerExplanation: Yup.string().required(
              "Please Enter Answer Explanation"
            ),
            questionTypeId: Yup.number().required(
              "Please Select Question Type"
            ),
            isActive: Yup.bool().oneOf([true], "Please check active"),
            answers: Yup.array().of(
              Yup.object().shape({
                optionText: Yup.string().required("Option Text is required"),
              })
            ),
          })}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            setFieldValue,
            setValues,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal isOpen={this.props.showModalAddQuestion} size="lg">
                <ModalHeader>
                  <h4>Add Question</h4>
                  <div className="modal_popup_close_icon">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => this.closeModal(resetForm)}
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  {this.props.errorMessage !== "" ? (
                    <p className="alert alert-danger">
                      {this.props.errorMessage}
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label>Question Text</label>
                      </Col>
                      <Col md="9">
                        <SunEditor
                          defaultValue={this.state.editorContent}
                          name="questionText"
                          placeholder="Please type here..."
                          setOptions={{
                            plugins: [video, image, list],
                            height: 150,
                            buttonList: [
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript",
                              ],
                              ["image", "video", "list", "table"],
                              ["paragraphStyle", "blockquote"],
                              ["fontColor", "hiliteColor", "textStyle"],
                              "/", // Line break
                              ["outdent", "indent"],
                              ["align", "horizontalRule", "list", "lineHeight"],
                              ["font", "fontSize", "formatBlock"],
                            ],
                          }}
                          onChange={(data) =>
                            this.handleEditorChange(data, setFieldValue)
                          }
                        />
                        <label className="err-msg">
                          {errors.questionText &&
                            touched.questionText &&
                            errors.questionText}
                        </label>
                      </Col>
                    </Row>
                  </div>

                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label>Answer Explanation</label>
                      </Col>
                      <Col md="9">
                        <SunEditor
                          defaultValue={this.state.editorContentAnswer}
                          name="answerExplanation"
                          placeholder="Please type here..."
                          setOptions={{
                            plugins: [video, image, list],
                            height: 150,
                            buttonList: [
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript",
                              ],
                              ["image", "video", "list", "table"],
                              ["paragraphStyle", "blockquote"],
                              ["fontColor", "hiliteColor", "textStyle"],
                              "/", // Line break
                              ["outdent", "indent"],
                              ["align", "horizontalRule", "list", "lineHeight"],
                              ["font", "fontSize", "formatBlock"],
                            ],
                          }}
                          onChange={(data) =>
                            this.handleEditorChangeAnswer(data, setFieldValue)
                          }
                        />
                        <label className="err-msg">
                          {errors.answerExplanation &&
                            touched.answerExplanation &&
                            errors.answerExplanation}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> Active</label>
                      </Col>
                      <Col md="6">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="isActive"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isActive}
                            defaultChecked={values.isActive}
                            className="form-check-input position-static checkbox-2x c9"
                          />
                          <label className="err-msg">
                            {errors.isActive &&
                              touched.isActive &&
                              errors.isActive}
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label> Question Type</label>
                      </Col>
                      <Col md="9">
                        <select
                          name="questionTypeId"
                          className="form-control"
                          onChange={(e) => {
                            this.handleQuestionType(e, setFieldValue);
                            const getValue = e.target.value;
                            this.filterFields(getValue, setFieldValue);
                          }}
                          value={values.questionTypeId}
                        >
                          <option value="">Select Question Type</option>
                          {this.props.questionType.map((type) => {
                            return (
                              <option value={type.id}>
                                {type.questionType}
                              </option>
                            );
                          })}
                        </select>
                        <label className="err-msg">
                          {errors.questionTypeId &&
                            touched.questionTypeId &&
                            errors.questionTypeId}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  {this.state.questionType != null ? (
                    <div className="form-group form-gp">
                      <Row>
                        <Col md="12">
                          <label>Answer Options</label>
                        </Col>
                      </Row>
                    </div>
                  ) : null}

                  {this.state.questionType === "1" ? (
                    <FieldArray name="answers">
                      <div className="answerWrapper" id="anilsharma">
                        <div className="form-group form-gp">
                          <Row>
                            <Col md="3">
                              <label>Option 1</label>
                            </Col>
                            <Col md="7">
                              <input
                                type="text"
                                name={`answers.${0}.optionText`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.answers[0].optionText}
                                className="form-control"
                                placeholder="Option Text"
                              />
                              {errors &&
                                errors.answers &&
                                errors.answers[0] &&
                                errors.answers[0].optionText &&
                                touched &&
                                touched.answers &&
                                touched.answers[0] &&
                                touched.answers[0].optionText && (
                                  <div className="err-msg">
                                    {errors.answers[0].optionText}
                                  </div>
                                )}
                            </Col>
                            <Col md="2">
                              <input
                                type="radio"
                                name="truefalse"
                                // onChange={handleChange}
                                onChange={(e) => {
                                  this.handleChangeAnswer(
                                    e,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                value={false}
                                className="form-control CheckRadio"
                              />
                              <input
                                type="hidden"
                                name={`answers.${0}.isCorrectOption`}
                                value={values.answers[0].isCorrectOption}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group form-gp">
                          <Row>
                            <Col md="3">
                              <label>Option 2</label>
                            </Col>
                            <Col md="7">
                              <input
                                type="text"
                                name={`answers.${1}.optionText`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.answers[1].optionText}
                                className="form-control"
                                placeholder="Option Text"
                              />
                              {errors &&
                                errors.answers &&
                                errors.answers[1] &&
                                errors.answers[1].optionText &&
                                touched &&
                                touched.answers &&
                                touched.answers[1] &&
                                touched.answers[1].optionText && (
                                  <div className="err-msg">
                                    {errors.answers[1].optionText}
                                  </div>
                                )}
                            </Col>
                            <Col md="2">
                              <input
                                type="radio"
                                name="truefalse"
                                onChange={(e) => {
                                  this.handleChangeAnswer2(
                                    e,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                value={false}
                                className="form-control CheckRadio"
                              />
                              <input
                                type="hidden"
                                name={`answers.${1}.isCorrectOption`}
                                value={values.answers[1].isCorrectOption}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </FieldArray>
                  ) : null}

                  {this.state.questionType === "2" ? (
                    <FieldArray name="answers">
                      <div className="answerWrapper">
                        <div className="form-group form-gp">
                          <Row>
                            <Col md="3">
                              <label>Option 1</label>
                            </Col>
                            <Col md="7">
                              <input
                                type="text"
                                name={`answers.${0}.optionText`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.answers[0].optionText}
                                className="form-control"
                                placeholder="Option Text"
                              />
                              {errors &&
                                errors.answers &&
                                errors.answers[0] &&
                                errors.answers[0].optionText &&
                                touched &&
                                touched.answers &&
                                touched.answers[0] &&
                                touched.answers[0].optionText && (
                                  <div className="err-msg">
                                    {errors.answers[0].optionText}
                                  </div>
                                )}
                            </Col>
                            <Col md="2">
                              <input
                                type="radio"
                                name="multiplechooseone"
                                onChange={(e) => {
                                  this.handleChangeMultipleChooseOne(
                                    e,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                value={false}
                                className="form-control CheckRadio"
                              />
                              <input
                                type="hidden"
                                name={`answers.${0}.isCorrectOption`}
                                value={values.answers[0].isCorrectOption}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group form-gp">
                          <Row>
                            <Col md="3">
                              <label>Option 2</label>
                            </Col>
                            <Col md="7">
                              <input
                                type="text"
                                name={`answers.${1}.optionText`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.answers[1].optionText}
                                className="form-control"
                                placeholder="Option Text"
                              />
                              {errors &&
                                errors.answers &&
                                errors.answers[1] &&
                                errors.answers[1].optionText &&
                                touched &&
                                touched.answers &&
                                touched.answers[1] &&
                                touched.answers[1].optionText && (
                                  <div className="err-msg">
                                    {errors.answers[1].optionText}
                                  </div>
                                )}
                            </Col>
                            <Col md="2">
                              <input
                                type="radio"
                                name="multiplechooseone"
                                onChange={(e) => {
                                  this.handleChangeMultipleChooseOne1(
                                    e,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                value={false}
                                className="form-control CheckRadio"
                              />
                              <input
                                type="hidden"
                                name={`answers.${1}.isCorrectOption`}
                                value={values.answers[1].isCorrectOption}
                              />
                            </Col>
                          </Row>
                        </div>

                        <div className="form-group form-gp">
                          <Row>
                            <Col md="3">
                              <label>Option 3</label>
                            </Col>
                            <Col md="7">
                              <input
                                type="text"
                                name={`answers.${2}.optionText`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.answers[2].optionText}
                                className="form-control"
                                placeholder="Option Text"
                              />
                              {errors &&
                                errors.answers &&
                                errors.answers[2] &&
                                errors.answers[2].optionText &&
                                touched &&
                                touched.answers &&
                                touched.answers[2] &&
                                touched.answers[2].optionText && (
                                  <div className="err-msg">
                                    {errors.answers[2].optionText}
                                  </div>
                                )}
                            </Col>
                            <Col md="2">
                              <input
                                type="radio"
                                name="multiplechooseone"
                                onChange={(e) => {
                                  this.handleChangeMultipleChooseOne2(
                                    e,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                value={false}
                                className="form-control CheckRadio"
                              />
                              <input
                                type="hidden"
                                name={`answers.${2}.isCorrectOption`}
                                value={values.answers[2].isCorrectOption}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group form-gp">
                          <Row>
                            <Col md="3">
                              <label>Option 4</label>
                            </Col>
                            <Col md="7">
                              <input
                                type="text"
                                name={`answers.${3}.optionText`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.answers[3].optionText}
                                className="form-control"
                                placeholder="Option Text"
                              />
                              {errors &&
                                errors.answers &&
                                errors.answers[3] &&
                                errors.answers[3].optionText &&
                                touched &&
                                touched.answers &&
                                touched.answers[3] &&
                                touched.answers[3].optionText && (
                                  <div className="err-msg">
                                    {errors.answers[3].optionText}
                                  </div>
                                )}
                            </Col>
                            <Col md="2">
                              <input
                                type="radio"
                                name="multiplechooseone"
                                onChange={(e) => {
                                  this.handleChangeMultipleChooseOne3(
                                    e,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                value={false}
                                className="form-control CheckRadio"
                              />
                              <input
                                type="hidden"
                                name={`answers.${3}.isCorrectOption`}
                                value={values.answers[3].isCorrectOption}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </FieldArray>
                  ) : null}

                  {this.state.questionType === "3" ? (
                    <FieldArray name="answers">
                      <div className="answerWrapper">
                        <div className="form-group form-gp">
                          <Row>
                            <Col md="3">
                              <label>Option 1</label>
                            </Col>
                            <Col md="7">
                              <input
                                type="text"
                                name={`answers.${0}.optionText`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.answers[0].optionText}
                                className="form-control"
                                placeholder="Option Text"
                              />
                              {errors &&
                                errors.answers &&
                                errors.answers[0] &&
                                errors.answers[0].optionText &&
                                touched &&
                                touched.answers &&
                                touched.answers[0] &&
                                touched.answers[0].optionText && (
                                  <div className="err-msg">
                                    {errors.answers[0].optionText}
                                  </div>
                                )}
                            </Col>
                            <Col md="2">
                              <input
                                type="checkbox"
                                name={`answers.${0}.isCorrectOption`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group form-gp">
                          <Row>
                            <Col md="3">
                              <label>Option 2</label>
                            </Col>
                            <Col md="7">
                              <input
                                type="text"
                                name={`answers.${1}.optionText`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.answers[1].optionText}
                                className="form-control"
                                placeholder="Option Text"
                              />
                              {errors &&
                                errors.answers &&
                                errors.answers[1] &&
                                errors.answers[1].optionText &&
                                touched &&
                                touched.answers &&
                                touched.answers[1] &&
                                touched.answers[1].optionText && (
                                  <div className="err-msg">
                                    {errors.answers[1].optionText}
                                  </div>
                                )}
                            </Col>
                            <Col md="2">
                              <input
                                type="checkbox"
                                name={`answers.${1}.isCorrectOption`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                            </Col>
                          </Row>
                        </div>

                        <div className="form-group form-gp">
                          <Row>
                            <Col md="3">
                              <label>Option 3</label>
                            </Col>
                            <Col md="7">
                              <input
                                type="text"
                                name={`answers.${2}.optionText`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.answers[2].optionText}
                                className="form-control"
                                placeholder="Option Text"
                              />
                              {errors &&
                                errors.answers &&
                                errors.answers[2] &&
                                errors.answers[2].optionText &&
                                touched &&
                                touched.answers &&
                                touched.answers[2] &&
                                touched.answers[2].optionText && (
                                  <div className="err-msg">
                                    {errors.answers[2].optionText}
                                  </div>
                                )}
                            </Col>
                            <Col md="2">
                              <input
                                type="checkbox"
                                name={`answers.${2}.isCorrectOption`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="form-group form-gp">
                          <Row>
                            <Col md="3">
                              <label>Option 4</label>
                            </Col>
                            <Col md="7">
                              <input
                                type="text"
                                name={`answers.${3}.optionText`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.answers[3].optionText}
                                className="form-control"
                                placeholder="Option Text"
                              />
                              {errors &&
                                errors.answers &&
                                errors.answers[3] &&
                                errors.answers[3].optionText &&
                                touched &&
                                touched.answers &&
                                touched.answers[3] &&
                                touched.answers[3].optionText && (
                                  <div className="err-msg">
                                    {errors.answers[3].optionText}
                                  </div>
                                )}
                            </Col>
                            <Col md="2">
                              <input
                                type="checkbox"
                                name={`answers.${3}.isCorrectOption`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </FieldArray>
                  ) : null}

                  {this.state.questionType === "4" ? (
                    <FieldArray name="answers">
                      <div className="answerWrapper">
                        <div className="form-group form-gp">
                          <Row>
                            <Col md="3">
                              <label>Option 1</label>
                            </Col>
                            <Col md="7">
                              <input
                                type="text"
                                name={`answers.${0}.optionText`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.answers[0].optionText}
                                className="form-control"
                                placeholder="Option Text"
                              />
                              {errors &&
                                errors.answers &&
                                errors.answers[0] &&
                                errors.answers[0].optionText &&
                                touched &&
                                touched.answers &&
                                touched.answers[0] &&
                                touched.answers[0].optionText && (
                                  <div className="err-msg">
                                    {errors.answers[0].optionText}
                                  </div>
                                )}
                            </Col>
                            <Col md="2">
                              <input
                                type="checkbox"
                                name={`answers.${0}.isCorrectOption`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </FieldArray>
                  ) : null}
                  {this.state.questionType === "5" ? (
                    <FieldArray name="answers">
                      <div className="answerWrapper">
                        <div className="form-group form-gp">
                          <Row>
                            <Col md="3">
                              <label>Option 1</label>
                            </Col>
                            <Col md="7">
                              <input
                                type="text"
                                name={`answers.${0}.optionText`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.answers[0].optionText}
                                className="form-control"
                                placeholder="Option Text"
                              />
                              {errors &&
                                errors.answers &&
                                errors.answers[0] &&
                                errors.answers[0].optionText &&
                                touched &&
                                touched.answers &&
                                touched.answers[0] &&
                                touched.answers[0].optionText && (
                                  <div className="err-msg">
                                    {errors.answers[0].optionText}
                                  </div>
                                )}
                            </Col>
                            <Col md="2">
                              <input
                                type="checkbox"
                                name={`answers.${0}.isCorrectOption`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </FieldArray>
                  ) : null}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => this.closeModal(resetForm)}
                  >
                    Close
                  </Button>
                  <Button
                    className="btn btn-primary-fill"
                    type="submit"
                    color="success"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </Modal>
            </form>
          )}
        </Formik>
      </>
    );
  }
}
