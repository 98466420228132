import React, { Component } from "react";
import { Row, Col, Card, Spinner, CardBody } from "reactstrap";

import {
  getEvaluationQuestionById,
} from "../../services/TeacherServices";
import { getUserSchoolSections } from "../../services/UserServices";
import "react-toastify/dist/ReactToastify.css";
import {
  getStudentCurriculum,
  getStudentTopicList,
  getChapterDetail
} from "../../services/StudentServices";
import { toast } from 'react-toastify';
import moment from 'moment'
toast.configure()
export default class StudentTopicDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSectionsList: [],
      errorMessage: "",
      isloading: false,
      chapterList: [],
      selectedSection: "",
      selectedChapter: "",
      topicList: [],
      selectedTopic: "",
      topicDetails: [],
      showModalAssessment: false,
      tittleModal: "",
      questionList: [],
      showScheduleModal: false,
      schoolSectionEvaluationId: "",
      errorMessage: "",
      evaluationList: ["Assessment", "Practice", "Quiz"],
    };
  }
  componentDidMount() {
    //here we are checking topic detail
    if (this.props.location.topicDetails !== undefined) {
      localStorage.setItem('studentTopicDetails', JSON.stringify(this.props.location.topicDetails))
      this.setState({
        selectedSection: this.props.location.topicDetails.sectionId,
        selectedChapter: this.props.location.topicDetails.chapterId,
        selectedTopic: this.props.location.topicDetails.schoolSectionTopicId,
      });
    }
    else {
      let topicDetails = localStorage.getItem('studentTopicDetails')
      if (topicDetails) topicDetails = JSON.parse(topicDetails)
      if(topicDetails===null){
        this.props.history.push('/app/subjects')
      }else{
        this.setState({
          selectedSection: topicDetails.sectionId,
          selectedChapter: topicDetails.chapterId,
          selectedTopic: topicDetails.schoolSectionTopicId,
        });

      }
     
    }
    this.getUserSectionList();

  }
  //this api is used to get user sections
  getUserSectionList = () => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getUserSchoolSections(userData.id)
      .then((responseUserSections) => {
        if (
          responseUserSections !== undefined &&
          responseUserSections.length > 0 &&
          responseUserSections[0]["userRoles"] !== undefined &&
          responseUserSections[0]["userRoles"][0]["userRolesSchoolSections"] !==
          undefined
        ) {
          var userSectionList = [];
          responseUserSections[0]["userRoles"][0][
            "userRolesSchoolSections"
          ].map((userSection) => {
            let data = {};
            data["label"] =
              userSection.schoolSection.boardGradeSubject.grade.grade +
              " Grade " +
              userSection.schoolSection.boardGradeSubject.subject.subject +
              " " +
              userSection.schoolSection.sectionName;
            data["value"] = userSection.schoolSectionId;
            userSectionList.push(data);
          });
          this.setState({
            userSectionsList: userSectionList,
            isloading: false,
          });
          if (userSectionList.length > 0 ) {
            if(this.state.selectedSection === ""){
              this.setState({ selectedSection: userSectionList[0].value });
              return getStudentCurriculum(userSectionList[0].value);       
            }
            else {
              return getStudentCurriculum(this.state.selectedSection);
            }
          } 
        } else {
          this.setState({ isloading: false, userSectionsList: [] });
        }
      })
      .then((responseCurriculum) => {
        if (responseCurriculum !== undefined) {
          let chapterList = [];
          responseCurriculum.map((chapter) => {
            chapterList.push({
              label: chapter.chapterTitle,
              value: chapter.id,
            });
          });
          this.setState({
            curriculumList: responseCurriculum,
            isloading: false,
            chapterList: chapterList,
          });
          if (chapterList.length > 0 ) {
            if(this.state.selectedChapter === ""){
              this.setState({selectedChapter:chapterList[0].value})
              this.getChapterDetails(chapterList[0].value);
            }
            else {
                this.getChapterDetails(this.state.selectedChapter);
            }
            
          }
        }
      })
      .catch((error) => {
        console.log("error in section chapterList list", error);
        if (error !== undefined) {
          this.setState({ isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  //this api is callled for chapter details
  getChapterDetails = (chapterId) => {
    let userData = JSON.parse(localStorage.getItem("user"));
    this.setState({ isloading: true });
    getChapterDetail(chapterId, userData.id)
      .then((responseChapter) => {
        if (responseChapter !== undefined) {
          let topicList = [];
          responseChapter.map((Chapter) => {
            Chapter.schoolSectionTopics.map((topic) => {
              topicList.push({
                label: topic.topicDisplayTitle,
                value: topic.id,
              });
            });
          });
          this.setState({ topicList: topicList, isloading: false });
          if (topicList.length > 0  ) {
            if(this.state.selectedTopic === ""){
              this.setState({selectedTopic:topicList[0].value})
              this.getTopicDetails(topicList[0].value);
            }
            else {
              this.getTopicDetails(this.state.selectedTopic);
            }
            
          } 
        }
      })
      .catch((error) => {
        console.log("error in  chapter Details", error);
      });
  };
  //this api is called for topic details
  getTopicDetails = (topicId) => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getStudentTopicList(topicId, userData.id)
      .then((responseTopic) => {
        if (responseTopic !== undefined) {
          this.setState({ topicDetails: responseTopic, isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log("error in section chapter Details", error);
      });
  };
  //this function used get evaluation question inside assignment modal
  getEvaluationQuestion = (evaluationId) => {
    this.setState({ isloading: true });
    getEvaluationQuestionById(evaluationId)
      .then((responseEvaluation) => {
        if (responseEvaluation !== undefined) {
          this.setState({
            isloading: false,
            questionList: responseEvaluation,
            showModalAssessment: true,
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log("error in evalution Details", error);
      });
  };
  //this function is used for Section dropdown change
  handleChangeSection = (event) => {
    this.setState({
      selectedSection: event.target.value,
      selectedChapter: "",
      chapterDetailList: [],
      chapterList: [],
      selectedTopic: "",
      topicDetails: [],
      topicList: [],
    });
    let toDetails = {
      pathname: "/app/subjects/std-chapter-details",
      chapterDetails:{ schoolSectionId:event.target.value ,chapterId:''},
    };
    this.props.history.push(toDetails);
  };
  //this function is used for Chapter dropdown change
  handleChangeChapter = (event) => {
    this.setState({
      selectedChapter: event.target.value,
      selectedTopic: "",
      topicDetails: [],
      topicList: [],
    });
    let toDetails = {
      pathname: "/app/subjects/std-chapter-details",
      chapterDetails:{ schoolSectionId:this.state.selectedSection ,chapterId:event.target.value},
    };
    this.props.history.push(toDetails);
    
  };
  //this function is used for topic dropdown change
  handleChangeTopic = (event) => {
    this.setState({ selectedTopic: event.target.value });
    this.getTopicDetails(event.target.value);
  };


  getStudentCurriculum = (sectionId) => {
    this.setState({ isloading: true });
    getStudentCurriculum(sectionId)
      .then((responseCurriculum) => {
        if (responseCurriculum !== undefined && responseCurriculum.length > 0) {
          let chapterList = [];
          responseCurriculum.map((chapter) => {
            chapterList.push({
              label: chapter.chapterTitle,
              value: chapter.id,
            });
          });
          this.setState({
            curriculumList: responseCurriculum,
            isloading: false,
            chapterList: chapterList,
          });
          if (chapterList.length > 0 ) {
            if(this.state.selectedChapter === ""){
              this.setState({selectedChapter:chapterList[0].value})
              this.getChapterDetails(chapterList[0].value);

            }else {
              this.getChapterDetails(this.state.selectedChapter);
            }
          } 
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        console.log("error in curriculum list", error);
        if (error !== undefined) {
          this.setState({ errorMessage: error.message, isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  // this function is used to show buffer data
  bufferFromBufferString = (data) => {
    let utf8decoder = new TextDecoder();
    let u16arr = new Uint8Array(data);
    let dataPrepare = utf8decoder.decode(u16arr);
    return dataPrepare;
  };
  onClickStart = (evaluation) => {
    let toDetails = {
      pathname:
        "start-" + evaluation.evaluationType.evaluationType.toLowerCase(),
      evaluation: evaluation,
    };
    this.props.history.push(toDetails);

  };
  createMarkup = (data) => {
    return { __html: data };
  };
  render() {
    const currentDate = new Date()
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row>
                        <Col md="12">
                          <div className="title">
                            <h4> Topic Details</h4>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md="4">
                          <select
                            className="form-control"
                            onChange={this.handleChangeSection}
                            value={this.state.selectedSection}
                          >
                            {this.state.userSectionsList !== undefined &&
                              this.state.userSectionsList.map((option) => (
                                <option value={option.value} key={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        </Col>
                        <Col md="4">
                          <select
                            className="form-control"
                            onChange={this.handleChangeChapter}
                            value={this.state.selectedChapter}
                          >
                            {this.state.chapterList !== undefined &&
                              this.state.chapterList.map((option) => (
                                <option value={option.value} key={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        </Col>
                        <Col md="4">
                          <select
                            className="form-control"
                            onChange={this.handleChangeTopic}
                            value={this.state.selectedTopic}
                          >
                            {this.state.topicList !== undefined &&
                              this.state.topicList.map((option) => (
                                <option value={option.value} key={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      <div>
                        {this.state.isloading ? (
                          <div className="custom-loader">
                            <Spinner animation="border" />
                          </div>
                        ) : (
                          <div>
                            {this.state.topicDetails.map((topic) => {
                              
                              return (
                                <div key={topic.id}>
                                  <Row className="mb-3">
                                    <Col md="12">
                                      {topic.schoolSectionEvaluations.map(
                                        (evaluation) => {

                                          return (
                                            <button
                                              className="btn btn-primary-fill  btn-sm mr-3"
                                              onClick={() =>
                                                this.onClickStart(evaluation)
                                              }
                                              key={evaluation.id}

                                              disabled={
                                                evaluation.schoolSectionStudentEvaluations.length > 0 && evaluation.schoolSectionStudentEvaluations[0].status === 'COMPLETED' || !moment(currentDate).isBetween(evaluation.startDate, evaluation.endDate) ? true : false}
                                            >
                                              {"Start " +
                                                evaluation.evaluationType
                                                  .evaluationType}
                                            </button>
                                          );
                                        }
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <div className="title">
                                        <h4>{topic.topicDisplayTitle}</h4>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                  
                                    <Col md="12">
                                      {topic.videoLink.includes("iframe")
                                      ?
                                      <span
                                        className='fixed-width-span'
                                        dangerouslySetInnerHTML={this.createMarkup(
                                          topic.videoLink
                                        )}
                                      ></span>
                                      :
                                      <a target="_blank" href={topic.videoLink}>
                                        {topic.videoLink}{" "}
                                      </a>
                                  }
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="8">
                                      <span
                                        className='fixed-width-span'
                                        dangerouslySetInnerHTML={this.createMarkup(
                                          this.bufferFromBufferString(topic.description.data)
                                        )}
                                      ></span>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}
