import Api from '../Api'
 export const getClientSchoolUsers=(id) => {
    return  Api.get('/user/getbyschool/'+id+'?page=1&size=1000').then(res=>res.data)
 }
 export const updateUser = (id) => {
    return Api.put('user/'+id).then(res=>res.data)
 }

 export const getClientSchoolUsersByClient=(id) => {
   return  Api.get('/user/getbyclient/'+id+'?page=1&size=1000').then(res=>res.data)
}
export const addSectionClientUser=(userData) => {
   return  Api.post('user/addschoolsection',userData).then(res=>res.data)
}
export const getUserSchoolSections=(id) => {
   return  Api.get('user/getschoolsections/'+id).then(res=>res.data)
}