import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCheckSquare,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";

export default class StudentEvaluationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      isDisable: false,
      questionIds: ["1", "2", "3"],
    };
  }
  closeModal = () => {
    this.setState({ selectedIndex: 0, isDisable: false });
    this.props.hideEvaluationModal();
  };

  // this function is used to show buffer data
  bufferFromBufferString = (data) => {
    let utf8decoder = new TextDecoder();
    let u16arr = new Uint8Array(data);
    let dataPrepare = utf8decoder.decode(u16arr);
    return dataPrepare;
  };

  //this function is for creating markup from discreption
  createMarkup = (data) => {
    return { __html: data };
  };
  render() {
    return (
      <>
        <Modal isOpen={this.props.showModalEvaluation} size="lg">
          <ModalHeader>
            <span>{this.props.tittleModal}</span>
            <div className="modal_popup_close_icon">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => this.closeModal()}
              />
            </div>
          </ModalHeader>
          <ModalBody>
            {this.props.questionList.map((question, index) => {
              return (
                <div key={index} className="mt-2">
                  <div>
                    {question !== undefined && (
                      <dl>
                        <dt>Question:{index + 1}</dt>
                        <dd>
                          <span
                            className="fixed-width-span"
                            dangerouslySetInnerHTML={this.createMarkup(
                              this.bufferFromBufferString(
                                question.schoolSectionQuestionText.data
                              )
                            )}
                          ></span>
                        </dd>
                      </dl>
                    )}
                  </div>
                  <dl>
                    <dt> Student Answer :</dt>
                    {question !== undefined &&
                      question["schoolSectionStudentEvaluationAnswers"] !==
                        undefined &&
                      question["schoolSectionStudentEvaluationAnswers"]
                        .filter(
                          (ansOptions) => ansOptions.submittedAnswer == true
                        )
                        .map((ansOptions) => {
                          return (
                            <dd key={ansOptions.id}>
                                    {this.state.questionIds.some(
                                (questionId) =>
                                  questionId ==
                                  question.schoolSectionQuestionTypeId
                              ) ? (
                                <div>
                                  {ansOptions.submittedAnswer ? (
                                    <FontAwesomeIcon
                                      icon={faCheckSquare}
                                      className="activeIcon"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faSquare}
                                      className="uncheck-square"
                                    />
                                  )}
                                  <span className="ml-1">
                                    <span
                                      className="fixed-width-span"
                                      dangerouslySetInnerHTML={this.createMarkup(
                                        this.bufferFromBufferString(
                                          ansOptions.optionText.data
                                        )
                                      )}
                                    ></span>
                                  </span>
                                </div>
                              ) : (
                                <span className="ml-1">
                                  <span
                                    className="fixed-width-span"
                                    dangerouslySetInnerHTML={this.createMarkup(
                                      this.bufferFromBufferString(
                                        ansOptions.optionText.data
                                      )
                                    )}
                                  ></span>
                                </span>
                              )}
                              <br></br>
                            </dd>
                          );
                        })}
                    <dt> Correct Answer :</dt>
                    {question !== undefined &&
                      question["schoolSectionStudentEvaluationAnswers"] !==
                        undefined &&
                      question["schoolSectionStudentEvaluationAnswers"].map(
                        (ansOptions) => {
                          return (
                            <dd key={ansOptions.id}>
                              {this.state.questionIds.some(
                                (questionId) =>
                                  questionId ==
                                  question.schoolSectionQuestionTypeId
                              ) ? (
                                <div>
                                  {ansOptions.isCorrectOption ? (
                                    <FontAwesomeIcon
                                      icon={faCheckSquare}
                                      className="activeIcon"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faSquare}
                                      className="uncheck-square"
                                    />
                                  )}
                                  <span className="ml-1">
                                    <span
                                      className="fixed-width-span"
                                      dangerouslySetInnerHTML={this.createMarkup(
                                        this.bufferFromBufferString(
                                          ansOptions.optionText.data
                                        )
                                      )}
                                    ></span>
                                  </span>
                                </div>
                              ) : (
                                <span className="ml-1">
                                  <span
                                    className="fixed-width-span"
                                    dangerouslySetInnerHTML={this.createMarkup(
                                      this.bufferFromBufferString(
                                        ansOptions.optionText.data
                                      )
                                    )}
                                  ></span>
                                </span>
                              )}

                              <br></br>
                            </dd>
                          );
                        }
                      )}
                    <dt>Answer Explanation:</dt>
                    {question !== undefined && (
                      <dd>
                        <span
                          className="fixed-width-span"
                          dangerouslySetInnerHTML={this.createMarkup(
                            this.bufferFromBufferString(
                              question.schoolSectionAnswerExplanation.data
                            )
                          )}
                        ></span>
                      </dd>
                    )}
                  </dl>
                </div>
              );
            })}
          </ModalBody>
        </Modal>
      </>
    );
  }
}
