import Roles from "../config/Roles";

export function getAuthRedirectUrl() {
    let role=JSON.parse(localStorage.getItem('defaultRole'));
        switch (role[0]){
            case Roles.OMO_ADMIN:
                return '/app/clients';
            case Roles.OMO_CONTENT_CREATOR:
                return '/app/topics';
            case Roles.CLIENT_ADMIN:
                return '/app/schools';
            case Roles.TEACHER:
                return '/app/teacher-dashboard';
            case Roles.STUDENT:
                return '/app/student_dashboard';
            default :
                return '/'
        }
}