import Api from "../Api";

// DASHBOARD
export const getStudentId = (studId) => {
  return Api.get("schoolsection/getstudentdashboard/" + studId).then(
    (res) => res.data
  );
};
// ANNOUNCEMENT
export const getAnnouncement = (id) => {
  return Api.get("announcement/getannouncementbyschoolsection/" + id).then(
    (res) => res.data
  );
};
// CURRICULUM
export const getParentRegCode = (Id) => {
  return Api.get("studentInformation/get/" + Id).then((res) => res.data);
};
// SUBJECT
export const getSubjectSec = (subId) => {
  return Api.get("user/getschoolsections/" + subId).then((res) => res.data);
};
export const getStudentCurriculum = (Id) => {
  //console.log(Id)
  return Api.get(
    "schoolsection/getstudentcurriculum/" + Id + "?page=1&size=1000"
  ).then((res) => res.data);
};
export const getChapterDetail = (Id, studentId) => {
  return Api.get("/schoolsection/getchapterdetailsforstudent/" + Id + '/' + studentId).then(
    (res) => res.data
  );
};
//ASK YOUR TEACHER
export const sendMessageToTeacher = (userData) => {
  return Api.post("directmessage/adddirectmessage", userData).then(
    (res) => res.data
  );
};
export const getMessagesForReceiver = (studentId, sectionId) => {
  return Api.get(
    "directmessage/getdirectmessagelistbyreceiver/" + studentId + "/" + sectionId + "/?page=1&size=1000"
  ).then((res) => res.data);
};
export const readMessagesByUser = (messageId) => {
  return Api.put("directmessage/readdirectmessagebyreceiver/" + messageId).then((res) => res.data);
};

export const startEvaluation = (data) => {
  return Api.post("/ssevaluation/startevaluation", data).then((res) => res.data);
};

export const getStudentRewardSummary = (id) => {
  return Api.get("/grades/getstudentrewardssummary/" + id).then((res) => res.data);
};

export const getEvaluationQuestions = (id) => {
  return Api.get("/ssevaluation/getstudentevaluationquestions/" + id).then(
    (res) => res.data
  );
};

export const recordAnswer = (data) => {
  return Api.post("/ssevaluation/recordanswer", data).then((res) => res.data);
};

export const completeEvaluation = (data) => {
  return Api.post("/ssevaluation/completeevaluation", data).then(
    (res) => res.data
  );
};
export const getPracticeList = (studentid, schoolsectiontopicid) => {
  return Api.get("/ssevaluation/getstudentpracticelist/" + studentid + '/' + schoolsectiontopicid).then((res) => res.data);
};
export const getStudentTopicList = (id, studentid) => {
  return Api.get("schoolsection/gettopicdetailsforstudent/" + id + '/' + studentid).then((res) => res.data);
}
export const getStudentGradeSummary = (id) => {
  return Api.get("/grades/getstudentgradesSummary/" + id).then((res) => res.data);
};
export const continueEvaluation = (data) => {
  return Api.post("/ssevaluation/continueevaluation", data).then((res) => res.data);
};
