import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { searchQuestion } from "../../services/QuestionServices";
import { Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";

toast.configure();
export default class AssignTopicQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionList: [],
      questionId: "",
      questionIdError: false,
      searchText:''
    };
  }

  componentDidMount() {
    this.setState({
      questionList: this.props.questionList,
    });
  }
  closeModal = (resetForm) => {
    resetForm();
    this.props.handleModalHide();
  };

  // this function is used to show buffer data
  bufferFromBufferString = (data) => {
    let utf8decoder = new TextDecoder();
    let u16arr = new Uint8Array(data);
    let dataPrepare = utf8decoder.decode(u16arr);
    return dataPrepare;
  };

  removeTag = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  createMarkup = (data) => {
    return { __html: data };
  };

  handleSearch = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      this.setState({
        questionList: this.props.questionList,
      });
    } 
    
    this.setState({searchText:e.target.value})
  };

  handleOnChange = (e, setFieldValue, list) => {
    console.log("list", list.id);
    this.setState({
      questionId: list.id,
      questionIdError: false,
    });
    setFieldValue("questionId", true);
  };
  onClickSearch=()=>{
    this.setState({ isloading: true });
    searchQuestion(this.state.searchText)
    .then((response) => {
      if (response !== undefined) {
        
        this.setState({
          questionList: response.rows,
          isloading:false
        });
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
  }
  onClickSearch=()=>{
    this.setState({ isloading: true });
    searchQuestion(this.state.searchText)
    .then((response) => {
      if (response !== undefined) {
        
        this.setState({
          questionList: response.rows,
          isloading:false
        });
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
  }

  render() {
    console.log("this.props.questionList", this.props.questionList);
    return (
      <>
        <Modal isOpen={this.props.modalAssignTopic} size="lg">
          <Formik
            enableReinitialize
            initialValues={this.props.initialValues}
            onSubmit={(values, actions) => {
              values.questionId = this.state.questionId;
              console.log("Form Values", values);
              // return;
              // this.props.onSubmit(values, actions);
              if (values.questionId !== "") {
                this.props.onSubmit(values, actions);
              } else {
                this.setState({
                  questionIdError: true,
                });
              }
            }}
            validationSchema={Yup.object().shape({
              topicId: Yup.string().required("Please Select Topic"),
              // questionId: Yup.string().required("Please Select Question"),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              resetForm,
              setFieldValue,
              setValues,
            }) => (
              <form onSubmit={handleSubmit}>
                <ModalHeader>
                  <h4>Assign a Question to Topic</h4>
                  <div className="modal_popup_close_icon">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => this.closeModal(resetForm)}
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  {this.props.errorMessage !== "" ? (
                    <p className="alert alert-danger">
                      {this.props.errorMessage}
                    </p>
                  ) : (
                      ""
                    )}
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label>Topic</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          className="form-control"
                          value={this.props.topicName}
                          disabled
                        />
                        <input
                          type="hidden"
                          name="topicId"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.topicId}
                          disabled
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="3">
                        <label>Topic Level</label>
                      </Col>
                      <Col md="9">
                        <input
                          type="text"
                          className="form-control"
                          value={"Level " + this.props.assignLevel}
                          disabled
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="clearfix question_listing_assign_modal">
                    <div className="search_question">
                      <div className="input-group">
                        <input
                          type="text"
                          name="questionSearch"
                          className="form-control"
                          placeholder="Search for Question here"
                          onChange={this.handleSearch}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary-fill btn btn-success" type='button' onClick={this.onClickSearch}>
                            <FontAwesomeIcon icon={faSearch} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="list_question">
                      <div className="react-bootstrap-table">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Question Text</th>
                              <th>Question Type</th>
                              {/* <th>Topic</th>
                              <th>Level</th> */}
                              <th
                                style={{
                                  width: "70px",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.questionList.map((list) => {
                              // console.log("Assign Modal Quetion list", list);
                              const questionText = this.bufferFromBufferString(
                                list.questionText.data
                              );
                              return (
                                <tr key={list.id}>
                                  <td>
                                    <span
                                      dangerouslySetInnerHTML={this.createMarkup(
                                        questionText
                                      )}
                                    ></span>
                                  </td>
                                  <td>{list.questionType.questionType}</td>

                                  {/* {list.topics.map((topic) => {
                                    console.log("Topics", topic);
                                    return <td>{topic.topicDisplayTitle}</td>;
                                  })} */}
                                  {/* <td>Topics</td>
                                  <td>Level</td> */}

                                  {/* {
                                  list.topics.map((topic) => {
                                    return <td>{topic.level}</td>;
                                  }
                                  )
                                  } */}

                                  <td>
                                    <div className="form-group form-gp">
                                      <div className="form-check">
                                        <input
                                          type="radio"
                                          name="questionId"
                                          onChange={(e) =>
                                            this.handleOnChange(
                                              e,
                                              setFieldValue,
                                              list
                                            )
                                          }
                                          // checked={values.questionId}
                                          value={values.questionId}
                                          className="form-check-input position-static checkbox-2x c9"
                                        />
                                        <label className="err-msg">
                                          {errors.questionId &&
                                            touched.questionId &&
                                            errors.questionId}
                                        </label>
                                      </div>
                                    </div>
                                  </td>
                                  {/* <td>
                                    {list.topics.map((topics) => {
                                      return (
                                        <div>{topics.topicDisplayTitle}</div>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    {list.topics.map((topics) => {
                                      return <div>{topics.level}</div>;
                                    })}
                                  </td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {this.state.questionIdError ? (
                      <label className="err-msg">Please select question</label>
                    ) : null}
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => this.closeModal(resetForm)}
                  >
                    Close
                  </Button>
                  <Button
                    className="btn btn-primary-fill"
                    type="submit"
                    color="success"
                    onClick={handleSubmit}
                  >
                    Assign
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Formik>
          {/* <h1>Hello World</h1> */}
        </Modal>
      </>
    );
  }
}
