import React, { Component } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getOmoUserList } from "../../services/OmoAdminServices";
import { addUser, updateUser } from "../../services/UserServices";
import AddEditOmoUser from "./AddEditOmoUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCheck, faBan } from "@fortawesome/free-solid-svg-icons";
import { getAllRoles } from "../../services/GeneralServices";

toast.configure();
class OmoUsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      omoUsersList: [],
      errorMessage: "",
      isloading: false,
      initialValues: {
        firstName: "",
        middleName: "",
        lastName: "",
        primaryEmail: "",
        username: "",
        password: "",
        roleId: "",
        isActive: false,
      },
      showModalOmoUser: false,
      titleAddEdit: "Add",
      userRoles: { 11: "OMO Admin", 12: "Content Creator" },
      OmoUserRoles: [],
      currentPage: 1,
    };
  }
  componentDidMount() {
    this.getOmoUsers();
    let allRoles = JSON.parse(localStorage.getItem("allRoles"));
    if (allRoles !== null) {
    }
  }
  getOmoUsers = () => {
    this.setState({ isloading: true });
    getOmoUserList()
      .then((responseOmoUserList) => {
        if (responseOmoUserList !== undefined) {
          let filterUser = [];
          responseOmoUserList.rows.map((omouser) => {
            let result = omouser.userRoles.some(
              (user) => user.roleId === 11 || user.roleId === 12
            );
            if (result) {
              filterUser.push(omouser);
            }
          });
          this.setState({ omoUsersList: filterUser });
          return getAllRoles();
        }
      })
      .then((allRoles) => {
        if (allRoles !== undefined) {
          let OmoUserRoles = [];
          let OmoRoles = allRoles.filter((role) => role.role.includes("OMO"));
          OmoRoles.map((role) => {
            OmoUserRoles.push({ label: role.roleDisplay, value: role.id });
          });
          this.setState({ OmoUserRoles: OmoUserRoles, isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message, isloading: false });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };

  handleModalHide = () => {
    this.setState({
      showModalOmoUser: !this.state.showModalOmoUser,
      initialValues: {
        firstName: "",
        middleName: "",
        lastName: "",
        primaryEmail: "",
        username: "",
        password: "",
        roleId: "",
        isActive: false,
      },
      titleAddEdit: "Add",
      errorMessage: "",
    });
  };
  //this function is for update user
  showModalUpdateUser = (rowData) => {
    let updateData = rowData;
    updateData.roleId = rowData.userRoles[0].roleId;
    this.setState({
      initialValues: updateData,
      showModalOmoUser: true,
      titleAddEdit: "Update",
    });
  };
  showModalOmoUser = (rowData) => {
    this.setState({ showModalOmoUser: true });
  };
  addOmoUser = (userOmoData, actions) => {
    this.setState({ isloading: true });
    addUser(userOmoData)
      .then((responseOMoData) => {
        if (responseOMoData !== undefined) {
          this.getOmoUsers();
          this.setState({ isloading: false, showModalOmoUser: false });
          toast.success(responseOMoData.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          actions.resetForm();
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 5000);
      });
  };
  rolesFormatter = (cell, row) => {
    return (
      <div>
        {cell.map((userRoles) => {
          return (
            <span key={userRoles.role.id}>
              {this.state.userRoles[userRoles.role.id]}{" "}
              {cell.length > 1 ? "," : ""}
            </span>
          );
        })}
      </div>
    );
  };
  updateOmoUser = (OmoUserData, actions) => {
    this.setState({ isloading: true });
    updateUser(OmoUserData.id, OmoUserData)
      .then((responseOmoData) => {
        if (responseOmoData !== undefined) {
          let allOmoUserList = [...this.state.omoUsersList];
          this.setState({
            isloading: false,
            showModalOmoUser: !this.state.showModalOmoUser,
          });
          toast.success("OMO User  details updated successfully", {
            autoClose: 3000,
            hideProgressBar: true,
          });
          actions.resetForm();

          let foundIndex = allOmoUserList.findIndex(
            (user) => user.id == responseOmoData.id
          );
          //if  we replace user with new details
          if (foundIndex >= 0) {
            allOmoUserList[foundIndex] = responseOmoData;
          }
          this.getOmoUsers();
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };
  activeFormatter = (cell, row) => {
    return (
      <span>
        {cell === true ? (
          <FontAwesomeIcon icon={faCheck} className="activeIcon" />
        ) : (
          <FontAwesomeIcon icon={faBan} className="inActiveIcon" />
        )}
      </span>
    );
  };
  handlePageChange = (event) => {
    this.setState({ currentPage: event });
  };
  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.showModalUpdateUser(row);
      },
    };
    const roles = [
      { label: "OMO Admin", value: 11 },
      { label: "Content Creator", value: 12 },
    ];
    const columns = [
      {
        dataField: "firstName",
        text: "First Name",
      },
      {
        dataField: "middleName",
        text: "Middle Name",
      },
      {
        dataField: "lastName",
        text: "Last Name",
      },
      {
        dataField: "username",
        text: "Username",
      },
      {
        dataField: "userRoles",
        text: "Role",
        formatter: this.rolesFormatter,
      },
      {
        dataField: "primaryEmail",
        text: "Email",
      },
      {
        dataField: "isActive",
        text: "Active",
        formatter: this.activeFormatter,
        align: "center",
        headerAlign: "center",
      },
    ];
    return (
      <div className="container-fluid">
        <Row>
          <Col md={12}>
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row>
                        <Col md="4">
                          <div className="title">Omo Users</div>
                        </Col>
                        <Col md="8">
                          <button
                            className="btn btn-primary-fill   float-right mt-3"
                            onClick={this.showModalOmoUser}
                          >
                            {" "}
                            <FontAwesomeIcon icon={faPlus} /> &nbsp;Add OMO User
                          </button>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      <div>
                        <div>
                          {this.state.isloading ? (
                            <div className="custom-loader">
                              <Spinner animation="border" />
                            </div>
                          ) : (
                            <BootstrapTable
                              keyField="id"
                              key={`omoUserList`}
                              data={this.state.omoUsersList}
                              columns={columns}
                              pagination={paginationFactory({
                                sizePerPage: 10,
                                showTotal: true,
                                hideSizePerPage: true,
                                page: this.state.currentPage,
                                onPageChange: (event) =>
                                  this.handlePageChange(event),
                              })}
                              rowClasses="row-hover pointer_class"
                              rowEvents={rowEvents}
                            />
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <AddEditOmoUser
                showModalOmoUser={this.state.showModalOmoUser}
                handleModalHide={this.handleModalHide}
                onSubmit={
                  this.state.titleAddEdit === "Update"
                    ? this.updateOmoUser
                    : this.addOmoUser
                }
                errorMessage={this.state.errorMessage}
                initialValues={this.state.initialValues}
                titleAddEdit={this.state.titleAddEdit}
                clientName={this.state.clientName}
                gradeList={this.state.gradeList}
                subjectList={this.state.subjectList}
                schoolName={this.state.schoolName}
                roles={this.state.OmoUserRoles}
              />
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}

export default OmoUsersList;
