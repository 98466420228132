import React, { Component } from "react";
import { Row, Col, Spinner } from "reactstrap";
import { getUserSchoolSections } from "../services/UserServices";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  getAnnouncementList,
  addAnnouncement,
} from "../services/TeacherServices";
import { Accordion, Button, Card } from "react-bootstrap";
import Moment from "react-moment";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SendAnnouncement from "./SendAnnouncement";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
export default class TeacherAnnouncement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DashboardList: [],
      errorMessage: "",
      isloading: false,
      userSectionsList: [],
      selectedSection: "",
      announcementList: [],
      showModalAnnouncement: false,
      errorMessage: "",
    };
  }
  componentDidMount() {
    this.getUserSectionList();
  }
  getUserSectionList = () => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getUserSchoolSections(userData.id)
      .then((responseUserSections) => {
        if (
          responseUserSections !== undefined &&
          responseUserSections.length > 0 &&
          responseUserSections[0]["userRoles"] !== undefined &&
          responseUserSections[0]["userRoles"][0]["userRolesSchoolSections"] !==
            undefined
        ) {
          var userSectionList = [];
          responseUserSections[0]["userRoles"][0][
            "userRolesSchoolSections"
          ].map((userSection) => {
            let data = {};
            data["label"] =
              userSection.schoolSection.boardGradeSubject.grade.grade +
              " Grade " +
              userSection.schoolSection.boardGradeSubject.subject.subject +
              " " +
              userSection.schoolSection.sectionName;
            data["value"] = userSection.schoolSectionId;
            userSectionList.push(data);
          });
          this.setState({ userSectionsList: userSectionList });
          if (userSectionList.length > 0) {
            this.setState({ selectedSection: userSectionList[0].value });
            return getAnnouncementList(userSectionList[0].value);
          }
        } else {
          this.setState({ isloading: false, userSectionsList: [] });
        }
      })
      .then((responseAnnouncement) => {
        if (responseAnnouncement !== undefined) {
          console.log("responseAnnouncement", responseAnnouncement);
          this.setState({
            announcementList: responseAnnouncement.rows,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error in section announcement list", error);
        if (error !== undefined) {
          this.setState({ isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  getAnnouncementBySection = (sectionId) => {
    this.setState({ isloading: true });
    getAnnouncementList(sectionId)
      .then((responseAnnouncement) => {
        if (responseAnnouncement !== undefined) {
          console.log("responseAnnouncement", responseAnnouncement);
          this.setState({
            announcementList: responseAnnouncement.rows,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error in client list", error);
        if (error !== undefined) {
          this.setState({ errorMessage: error.message, isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  handleChangeSection = (event) => {
    this.setState({ selectedSection: event.target.value });
    this.getAnnouncementBySection(event.target.value);
  };
  handleModalShow = () => {
    this.setState({ showModalAnnouncement: !this.state.showModalAnnouncement });
  };
  handleModalHide = () => {
    this.setState({ showModalAnnouncement: !this.state.showModalAnnouncement });
  };
  addNewAnnouncement = (announcementData, actions) => {
    let userData = JSON.parse(localStorage.getItem("user"));
    announcementData["teacherId"] = userData.id;
    this.setState({ isloading: true });
    addAnnouncement(announcementData)
      .then((responseAnnouncementData) => {
        if (responseAnnouncementData !== undefined) {
          console.log("responseAnnouncementData", responseAnnouncementData);
          this.setState({ isloading: false, showModalAnnouncement: false });
          toast.success(responseAnnouncementData.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          this.getAnnouncementBySection(announcementData.schoolSectionId);
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        }
      });
  };

  render() {
    let clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row>
                        <Col md="6">
                          <div className="title">
                            <h4>Announcement</h4>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <Card.Body>
                      <div>
                        {this.state.isloading ? (
                          <div className="custom-loader">
                            <Spinner animation="border" />
                          </div>
                        ) : (
                          <div>
                            <div className="form-group form-gp">
                              <Row>
                                <Col md="3">
                                  <label> Select Section</label>
                                </Col>
                                <Col md="6">
                                  <select
                                    className="form-control"
                                    onChange={this.handleChangeSection}
                                    value={this.state.selectedSection}
                                  >
                                    {this.state.userSectionsList !==
                                      undefined &&
                                      this.state.userSectionsList.map(
                                        (option) => (
                                          <option
                                            value={option.value}
                                            key={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        )
                                      )}
                                  </select>
                                </Col>
                                <Col md="3">
                                  <button
                                    className="btn btn-primary-fill float-right"
                                    onClick={this.handleModalShow}
                                  >
                                    <FontAwesomeIcon icon={faPlus} /> &nbsp;Send
                                    Announcement{" "}
                                  </button>
                                </Col>
                              </Row>
                            </div>
                            <Accordion>
                              {this.state.announcementList !== undefined &&
                              this.state.announcementList.length > 0 ? (
                                this.state.announcementList.map(
                                  (announcement, index) => {
                                    return (
                                      <Card key={index}>
                                        <Accordion.Toggle
                                          as={Card.Header}
                                          variant="link"
                                          eventKey={announcement.id}
                                          md="12"
                                          className="row-hover pointer_class"
                                        >
                                          <Row md="12">
                                            <Col md="6">
                                              <label>
                                                {
                                                  announcement.announcementSubject
                                                }
                                              </label>
                                            </Col>
                                            <Col md="6">
                                              <label className="float-right">
                                                <Moment format="MM/DD/YYYY hh:mm:A">
                                                  {announcement.createdAt}
                                                </Moment>
                                              </label>
                                            </Col>
                                          </Row>
                                        </Accordion.Toggle>

                                        <Accordion.Collapse
                                          eventKey={announcement.id}
                                        >
                                          <Card.Body>
                                            {announcement.announcement}
                                          </Card.Body>
                                        </Accordion.Collapse>
                                      </Card>
                                    );
                                  }
                                )
                              ) : (
                                <div className="text-center">
                                  <p>
                                    {" "}
                                    No announcement available for this section
                                  </p>
                                </div>
                              )}
                            </Accordion>
                          </div>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
            <SendAnnouncement
              showModalAnnouncement={this.state.showModalAnnouncement}
              handleModalShow={this.handleModalShow}
              handleModalHide={this.handleModalHide}
              errorMessage={this.state.errorMessage}
              userSectionsList={this.state.userSectionsList}
              onSubmit={this.addNewAnnouncement}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
