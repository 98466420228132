import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Spinner,
} from "reactstrap";
import { getDashboardList } from "../services/TeacherServices";
export default class TeacherDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardList: [],
      errorMessage: "",
      isloading: false,
    };
  }
  componentDidMount() {
    this.getDashboardList();
  }
  getDashboardList = () => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getDashboardList(userData.id)
      .then((responsedashboardList) => {
        if (responsedashboardList !== undefined) {
          this.setState({
            dashboardList: responsedashboardList,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        if (error !== undefined) {
          this.setState({ errorMessage: error.message, isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  handleClick = (path) => {
    let lowerPath = path.toLowerCase();
    if (lowerPath === "direct-message") {
      this.props.history.push(lowerPath);
    } else {
      this.props.history.push("grade");
    }
  };
  render() {
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row>
                        <Col md="6">
                          <div className="title">
                            <h4>Dashboard</h4>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      <div>
                        {this.state.isloading ? (
                          <div className="custom-loader">
                            <Spinner animation="border" />
                          </div>
                        ) : (
                          <Row>
                            {this.state.dashboardList !== undefined &&
                              this.state.dashboardList.length > 0 &&
                              this.state.dashboardList.map(
                                (dasBoardItem, index) => {
                                  return (
                                    <Col md="3" key={index}>
                                      <Card
                                        onClick={() =>
                                          this.handleClick(
                                            Object.keys(
                                              dasBoardItem
                                            )[0].replace(" ", "-")
                                          )
                                        }
                                        style={{ height: "10rem" }}
                                        className="pointer_class clickable-card"
                                      >
                                        <CardBody>
                                          <CardTitle className="text-center">
                                            <h4 className="card-title">
                                              {Object.keys(dasBoardItem)[0]}
                                            </h4>
                                          </CardTitle>
                                          <CardText className="text-center">
                                            {
                                              dasBoardItem[
                                                Object.keys(dasBoardItem)[0]
                                              ]
                                            }
                                          </CardText>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  );
                                }
                              )}
                          </Row>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}
