import React, { Component } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { topicList, addTopic, updateTopic, searchTopic } from "../../services/TopicServices";
import AddTopic from "./AddTopic";
import LevelTopic from "./LevelTopic";
import { toast } from "react-toastify";
import { chunk, uniqBy, filter } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";

class TopicList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topicList: [],
      topicSearch: "",
      errorMessage: "",
      isloading: false,
      showModalAddTopic: false,
      levelTopic: false,
      topicName: "",
      topicLevel: "",
      initialValues: {
        topicTitle: "",
        topicDisplayTitle: "",
        videoLink: "",
        description: "",
        level: 1,
        status: "Active",
      },
      searchText:''
    };
  }
  componentDidMount() {
    this.getTopicList();
  }

  getTopicList = () => {
    this.setState({ isloading: true });
    topicList()
      .then((responseTopicList) => {
        if (responseTopicList !== undefined) {
       
          this.setState({
            topicList: responseTopicList.rows,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ errorMessage: error.message, isloading: false });
      });
  };

  handleModalShow = () => {
    this.setState({
      showModalAddTopic: true,
    });
  };
  handleModalHide = () => {
    this.setState({
      showModalAddTopic: false,
      errorMessage : ""
    });
  };

  addTopic = (topicData) => {
  
    // return;
    this.setState({ isloading: true });
    addTopic(topicData)
      .then((responseTopicData) => {
        if (responseTopicData !== undefined) {
        
          this.setState({
            isloading: false,
            showModalAddTopic: false,
            topicList: [...this.state.topicList, responseTopicData],
            errorMessage: "",
          });
          toast.success(responseTopicData.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            isloading: false,
          });
        } else {
          this.setState({
            errorMessage: error.message,
            isloading: false,
          });
        }
      });
  };

  updateTopic = (data, id, actions) => {
    // console.log("Update Data", data);
    // console.log("Update ID", id);
    this.setState({ isloading: true });
    updateTopic(id, data)
      .then((responseUpdateTopic) => {
        if (responseUpdateTopic !== undefined) {
          // console.log("responseUpdateTopic", responseUpdateTopic.data);
          const elementsIndex = this.state.topicList.findIndex(
            (topic) => topic.id == responseUpdateTopic.data.id
          );
          let updateTopic = [...this.state.topicList];
          updateTopic[elementsIndex] = responseUpdateTopic.data;
          this.setState({
            isloading: false,
            showModalEditTopic: false,
            topicData: {},
            topicList: updateTopic,
          });
          toast.success("Topic detail updated successfully", {
            autoClose: 3000,
            hideProgressBar: true,
          });
          actions.resetForm();
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message, isloading: false });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };

  detailTopic = (name, level) => {
    this.setState({
      levelTopic: true,
      topicName: name,
      topicLevel: level,
    });
  };

  backToTopics = (e) => {
    e.preventDefault();
    this.setState({
      levelTopic: false,
    });
    this.getTopicList();
  };

  handleOnChange = (e) => {
    e.preventDefault();
    if(e.target.value === ""){
      this.getTopicList();
    } else{
      const getValue = e.target.value;
     
    }
    this.setState({searchText:e.target.value})
  };

  onClickSearch=()=>{
    this.setState({ isloading: true });
    searchTopic(this.state.searchText)
    .then((response) => {
      if (response !== undefined) {
        
        this.setState({
          topicList: response.rows,
          isloading:false
        });
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
  }
  render() {
    const uniqRows = uniqBy([...this.state.topicList], "topicTitle");
    // console.log("uniqRows", uniqRows);
    const rows =
      this.state.topicList !== undefined ? chunk([...uniqRows], 10) : [];
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  {!this.state.levelTopic ? (
                    <Card className="card-box">
                      <div className="card-header">
                        <Row>
                          <Col md="4">
                            <div className="title">Topics</div>
                          </Col>
                          <Col md="8">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="searchTopicList mt-3">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="topicSearch"
                                    className="form-control"
                                    placeholder="Search for Topic here"
                                    onChange={this.handleOnChange}
                                    // onKeyUp={this.searchHandle}
                                  />
                                  <div className="input-group-append">
                                    <button className="btn btn-primary-fill btn btn-success" onClick={this.onClickSearch}>
                                      <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="addTopic">
                                <button
                                  className="btn btn-primary-fill float-right mt-3"
                                  onClick={this.handleModalShow}
                                >
                                  <FontAwesomeIcon icon={faPlus} />&nbsp;
                                  Add Topic
                                </button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <CardBody>
                        {this.state.isloading ? (
                          <div className="custom-loader">
                            <Spinner animation="border" />
                          </div>
                        ) : (
                          
                          <Row>
                            {rows.length>0
                            
                            ?
                            rows.map((row, index) => {
                          
                              return (
                                <div className="col-md-3 mb-20">
                                  <div className="card topic_card">
                                    <div className="card-header">
                                      Topic Name
                                    </div>
                                    <ul className="list-group list-group-flush">
                                      {row.map((col) => {
                                        // console.log("col Custom", col);
                                        return (
                                          <React.Fragment>
                                            <div
                                              className="list-group-item pointer_class"
                                              key={col.id}
                                              onClick={(e) =>
                                                this.detailTopic(
                                                  col.topicTitle,
                                                  col.level
                                                )
                                              }
                                            >
                                              {col.topicTitle}
                                            </div>
                                          </React.Fragment>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              );
                            })
                            :<p className="no-data text-center col-md-12"> No Topic Available</p>
                          }
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  ) : (
                    <LevelTopic
                      topicData={this.state.topicList}
                      topicName={this.state.topicName}
                      topicLevel={this.state.topicLevel}
                      backToTopics={this.backToTopics}
                    />
                  )}
                </Col>
              </Row>

              {this.state.showModalAddTopic ? (
                <AddTopic
                  showModalAddTopic={this.state.showModalAddTopic}
                  handleModalHide={this.handleModalHide}
                  onSubmit={this.addTopic}
                  errorMessage={this.state.errorMessage}
                  initialValues={this.state.initialValues}
                />
              ) : null}
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}

export default TopicList;
