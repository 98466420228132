import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Spinner } from "reactstrap";
import {
  startEvaluation,
  getEvaluationQuestions,
  recordAnswer,
  completeEvaluation,
  continueEvaluation,
} from "../../services/StudentServices";
import PracticeForm from "./PracticeForm";
import "react-toastify/dist/ReactToastify.css";
import { faSmile } from "@fortawesome/free-regular-svg-icons";
toast.configure();
export default class PracticeStepWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionList: [],
      showQuestionList: false,
      questionIndex: 0,
      showQuestion: false,
      lastQuestion: false,
      calculation: 0,
      schoolSectionEvaluationId: "",
      schoolSectionTopicId: "",
      evaluationTypeId: "",
      practice: {},
      isloading: false,
      CorrectAnswer: false,
      showAnswer: false,
      attemptNo: 1,
      inCorrectAnswerMessage: false,
      totalQuestionCount: 0,
      questionsAnswered: 0,
      nextQuestionIndex: 0,
      initialValues: {},
    };
  }
  componentDidMount() {
    let practice = {};
    //this will checked  props data which is send from practice list
    if (this.props.location.practice !== undefined) {
      practice = this.props.location.practice;
      localStorage.setItem(
        "practice",
        JSON.stringify(this.props.location.practice)
      );
      if (practice.status === "STARTED") {
        this.setState(
          {
            schoolSectionStudentEvaluationId: practice.id,
            schoolSectionTopicId: practice.schoolSectionTopicId,
            evaluationTypeId: practice.evaluationTypeId,
            practice: practice,
            schoolSectionEvaluationId: practice.schoolSectionEvaluationId,
          },
          () => {
            this.continueEvaluation();
          }
        );
      }
      //this condition check if evaluation is started or not
      else {
        this.setState(
          {
            schoolSectionEvaluationId: practice.schoolSectionEvaluationId,
            practice: practice,
            schoolSectionTopicId: practice.schoolSectionTopicId,
            evaluationTypeId: practice.evaluationTypeId,
          },
          () => {
            this.startEvaluation();
          }
        );
      }
    } else {
      //else we will check from localstorage
      practice = localStorage.getItem("practice");
      if (practice) practice = JSON.parse(practice);
      if (practice !== undefined) {
        if (practice.status === "STARTED") {
          this.setState(
            {
              schoolSectionStudentEvaluationId: practice.id,
              schoolSectionTopicId: practice.schoolSectionTopicId,
              evaluationTypeId: practice.evaluationTypeId,
              practice: practice,
              schoolSectionEvaluationId: practice.schoolSectionEvaluationId,
            },
            () => {
              this.continueEvaluation();
            }
          );
        } else {
          this.setState(
            {
              schoolSectionEvaluationId: practice.schoolSectionEvaluationId,
              schoolSectionTopicId: practice.schoolSectionTopicId,
              evaluationTypeId: practice.evaluationTypeId,
              practice: practice,
            },
            () => {
              this.startEvaluation();
            }
          );
        }
      }
    }
  }
  startEvaluation = () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    const studentId = userData.id;
    this.setState({ isloading: true });
    let prepareData = {
      studentId: studentId,
      schoolSectionEvaluationId: this.state.schoolSectionEvaluationId,
      schoolSectionTopicId: this.state.schoolSectionTopicId,
      evaluationTypeId: this.state.evaluationTypeId,
      status: "STARTED",
    };

    // return;
    startEvaluation(prepareData)
      .then((res) => {
        if (res !== undefined) {
          this.setState({
            schoolSectionStudentEvaluationId:
              res.schoolSectionStudentEvaluation.id,
            isloading: false,
          });
          this.getEvaluationQuestions(res.schoolSectionStudentEvaluation.id);
        } else {
          console.log("res ssEvaluation else", res);
          toast.error(res, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log("res ssEvaluation error", error);
      });
  };
  // this function is used to show buffer data
  bufferFromBufferString = (data) => {
    let utf8decoder = new TextDecoder();
    let u16arr = new Uint8Array(data);
    let dataPrepare = utf8decoder.decode(u16arr);
    return dataPrepare;
  };

  nextquestion = (data, actions) => {
    delete data["schoolSectionStudentEvaluationAnswers"];
    recordAnswer(data)
      .then((res) => {
        if (res !== undefined) {
          if (res.message !== "Correct.") {
            let attemptNo = this.state.attemptNo + 1;

            this.setState({
              attemptNo: attemptNo,
              inCorrectAnswerMessage: true,
            });
            if (attemptNo > 2) {
              toast.success(res.message, {
                autoClose: 3000,
                hideProgressBar: true,
              });
              this.setState({ showAnswer: true });
            }
            setTimeout(() => {
              this.setState({ inCorrectAnswerMessage: false });
            }, 3000);
          } else {
            const getCount = this.state.questionList.length - 2;
            if (getCount === this.state.questionIndex) {
              this.setState({
                lastQuestion: true,
              });
            }
            toast.success(res.message, {
              autoClose: 3000,
              hideProgressBar: true,
            });
            const nextQuestion = this.state.questionIndex + 1;
            const calculate =
              Math.floor(100 / this.state.questionList.length) * nextQuestion;
            this.setState({
              attemptNo: 1,
              inCorrectAnswerMessage: false,
              showAnswer: false,
            });
            if (nextQuestion < this.state.questionList.length) {
              let questionList = [...this.state.questionList];
              let questionIndex = this.state.questionIndex + 1;

              let schoolSectionQuestionObject = questionList[questionIndex];
              let initialValues = {
                schoolSectionStudentEvaluationId:
                  this.state.schoolSectionStudentEvaluationId,
                evaluationTypeId: this.state.evaluationTypeId,
                schoolSectionStudentEvaluationQuestionId:
                  schoolSectionQuestionObject.id,
                questionTypeId:
                  schoolSectionQuestionObject.schoolSectionQuestionTypeId,

                attemptNo: this.state.attemptNo,
                submittedAnswer: [
                  {
                    answerId: "",
                    answerText: "",
                  },
                ],
              };
              // for getting submitted answer from question list
              let submittedAnswerList =
                schoolSectionQuestionObject.schoolSectionStudentEvaluationAnswers.filter(
                  (question) => question.submittedAnswer === true
                );
              let submittedAnswer = [];

              if (submittedAnswerList.length > 0) {
                submittedAnswerList.map((answer) => {
                  submittedAnswer.push({
                    answerId: answer.id,
                    answerText: this.bufferFromBufferString(
                      answer.submittedAnswerText.data
                    ),
                  });
                });
                initialValues["submittedAnswer"] = submittedAnswer;
              }
              this.setState({
                questionIndex: this.state.questionIndex + 1,
                calculate: calculate,
                initialValues: initialValues,
              });
              // Call the API /ssevaluation/recordanswer
            } else {
              // Call the API /ssevaluation/completeevaluation

              let userData = JSON.parse(localStorage.getItem("user"));
              const studentId = userData.id;
              const prepareData = {
                studentId: studentId,
                schoolSectionStudentEvaluationId:
                  data.schoolSectionStudentEvaluationId,
                schoolSectionTopicId: this.state.schoolSectionTopicId,
                evaluationTypeId: this.state.evaluationTypeId,
                status: "COMPLETED",
              };

              //   return;
              completeEvaluation(prepareData)
                .then((res) => {
                  if (res !== undefined) {
                    toast.success("Practice  is completed.", {
                      autoClose: 3000,
                      hideProgressBar: true,
                    });
                    this.props.history.push("/app/subjects/std-topic-details");
                  } else {
                    console.log("res complete Evaluation else", res);
                  }
                })
                .catch((error) => {
                  console.log("res complete Evaluation error", error);
                });
            }
          }
        }
      })
      .catch((error) => {
        console.log("res record Answer error", error);
      });
  };

  onPrevquestion = () => {
    const prevQuestion = this.state.questionIndex - 1;
    const calculate =
      Math.floor(100 / this.state.questionList.length) * prevQuestion;
    if (this.state.questionIndex > 0) {
      this.setState({
        questionIndex: this.state.questionIndex - 1,
        lastQuestion: false,
        calculate: calculate,
        showAnswer: false,
      });

      this.getEvaluationQuestions(this.state.schoolSectionStudentEvaluationId);
    } else {
      console.log("Disabled Prev button");
    }
  };
  // this funcition is used for questions list
  getEvaluationQuestions = (schoolSectionEvaluationId) => {
    this.setState({ isloading: true });
    getEvaluationQuestions(schoolSectionEvaluationId)
      .then((res) => {
        if (res !== undefined) {
          let questionIndex = this.state.questionIndex;
          let schoolSectionQuestionObject = res[questionIndex];
          let initialValues = {
            schoolSectionStudentEvaluationId:
              this.state.schoolSectionStudentEvaluationId,
            evaluationTypeId: this.state.evaluationTypeId,
            schoolSectionStudentEvaluationQuestionId:
              schoolSectionQuestionObject.id,
            questionTypeId:
              schoolSectionQuestionObject.schoolSectionQuestionTypeId,

            attemptNo: this.state.attemptNo,
            submittedAnswer: [
              {
                answerId: "",
                answerText: "",
              },
            ],
          };
          // for getting submitted answer from question list
          let submittedAnswerList =
            schoolSectionQuestionObject.schoolSectionStudentEvaluationAnswers.filter(
              (question) => question.submittedAnswer === true
            );

          let submittedAnswer = [];

          if (submittedAnswerList.length > 0) {
            submittedAnswerList.map((answer) => {
              submittedAnswer.push({
                answerId: answer.id,
                answerText: this.bufferFromBufferString(
                  answer.submittedAnswerText.data
                ),
              });
            });
            initialValues["submittedAnswer"] = submittedAnswer;
          }
          this.setState({
            questionList: res,
            showQuestionList: true,
            initialValues: initialValues,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("res Question error", error);
      });
  };
  //this function is used to continue evaluation if student leave the evaluation then
  continueEvaluation = () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    const studentId = userData.id;
    this.setState({ isloading: true });
    let prepareData = {
      studentId: studentId,
      schoolSectionTopicId: this.state.schoolSectionTopicId,
      evaluationTypeId: this.state.evaluationTypeId,
      schoolSectionStudentEvaluationId:
        this.state.schoolSectionStudentEvaluationId,
    };
    // return;
    continueEvaluation(prepareData)
      .then((response) => {
        if (response !== undefined) {
          let questionIndex = 0;
          if (response.totalQuestionCount === response.questionsAnswered) {
            questionIndex = response.questionsAnswered - 1;
          } else {
            questionIndex = response.nextQuestionIndex;
          }
          const calculate =
            Math.floor(100 / response.totalQuestionCount) * questionIndex;
          const getCount = questionIndex + 1;
          let lastQuestion = false;
          if (getCount === response.totalQuestionCount) {
            lastQuestion = true;
          }
          this.setState({
            isloading: false,
            totalQuestionCount: response.totalQuestionCount,
            questionsAnswered: response.questionsAnswered,
            questionIndex: questionIndex,
            calculate: calculate,
            lastQuestion: lastQuestion,
          });

          this.getEvaluationQuestions(
            this.state.schoolSectionStudentEvaluationId
          );
        } else {
          console.log("res ssEvaluation else", response);
          toast.error(response, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log("res ssEvaluation error", error);
      });
  };

  render() {
    const {
      questionList,
      questionIndex,
      showQuestion,
      lastQuestion,
      calculate,
      showQuestionList,
    } = this.state;

    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col lg="12" md="12">
              {this.state.isloading ? (
                <div className="custom-loader">
                  <Spinner animation="border" />
                </div>
              ) : (
                <div className="card-box1">
                  {showQuestionList ? (
                    <>
                      <div>
                        <div>
                          <>
                            {questionList.length > 0 &&
                              questionList !== undefined && (
                                <PracticeForm
                                  question={this.bufferFromBufferString(
                                    questionList[questionIndex]
                                      .schoolSectionQuestionText.data
                                  )}
                                  schoolSectionQuestionTypeId={
                                    questionList[questionIndex]
                                      .schoolSectionQuestionTypeId
                                  }
                                  schoolSectionQuestionObject={
                                    questionList[questionIndex]
                                  }
                                  schoolSectionStudentEvaluationAnswers={
                                    questionList[questionIndex]
                                      .schoolSectionStudentEvaluationAnswers
                                  }
                                  evaluation={this.state.practice}
                                  questionIndex={questionIndex}
                                  onNextquestion={this.nextquestion}
                                  onPrevquestion={this.onPrevquestion}
                                  lastQuestion={lastQuestion}
                                  calculate={calculate}
                                  schoolSectionStudentEvaluationId={
                                    this.state.schoolSectionStudentEvaluationId
                                  }
                                  initialValues={this.state.initialValues}
                                  showAnswer={this.state.showAnswer}
                                  attemptNo={this.state.attemptNo}
                                  schoolSectionAnswerExplanation={
                                    questionList[questionIndex]
                                      .schoolSectionAnswerExplanation
                                  }
                                  inCorrectAnswerMessage={
                                    this.state.inCorrectAnswerMessage
                                  }
                                />
                              )}
                          </>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Col md="12">
                      <span className="no-data">
                        {" "}
                        No Questions are available here.
                      </span>
                    </Col>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
