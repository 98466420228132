import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import { Accordion, Card } from "react-bootstrap";
import trimObj from "../../components/common/TrimData";
toast.configure();
export default class SendMessage extends Component {
  closeModal = (resetForm) => {
    resetForm();
    this.props.handleModalHide();
  };
  render() {
    const validationSchema = Yup.object().shape({
      receiverId: Yup.string().required("Please select a teacher"),
      messageSubject: Yup.string().required("Please enter subject"),
      message: Yup.string().required("Please enter message"),
      // .max(500, "announcement can not be more than 500 character")
    });
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={this.props.initialValues}
          onSubmit={(values, actions) => {
            // this function used to trim string before and after
            values = trimObj(values);
            this.props.onSubmit(values, actions);
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal isOpen={this.props.showModalMessage} size="md">
                <ModalHeader>
                  <span>Send A Message</span>
                  <div className="modal_popup_close_icon">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => this.closeModal(resetForm)}
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  {this.props.errorMessage !== "" ? (
                    <p className="alert alert-danger">
                      {this.props.errorMessage}
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="2">
                        <label> Select Teacher</label>
                      </Col>
                      <Col md="10">
                        <select
                          name="receiverId"
                          className="form-control"
                          onChange={handleChange}
                          value={values.receiverId}
                          disabled={this.props.isDisable ? true : null}
                        >
                          <option value="">Select Teacher</option>
                          {this.props.allTeachersList !== undefined &&
                            this.props.allTeachersList.map((option) => (
                              <option value={option.value} key={option.value}>
                                {option.label}
                              </option>
                            ))}
                        </select>
                        <label className="err-msg">
                          {errors.receiverId && errors.receiverId}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="2">
                        <label> Subject</label>
                      </Col>
                      <Col md="10">
                        <input
                          type="text"
                          name="messageSubject"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.messageSubject}
                          className="form-control"
                          placeholder="Enter the subject here"
                          disabled={this.props.isDisable ? true : null}
                        />
                        <label className="err-msg">
                          {errors.messageSubject &&
                            touched.messageSubject &&
                            errors.messageSubject}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="12">
                        <textarea
                          placeholder="Enter message here"
                          rows={5}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="message"
                          values={values.message}
                          className="form-control"
                        />
                        <label className="err-msg">
                          {errors.message && touched.message && errors.message}
                        </label>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => this.closeModal(resetForm)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="btn btn-primary-fill"
                    onClick={handleSubmit}
                  >
                    Send
                  </Button>
                  <Row>
                    <Accordion>
                      {this.props.previousMessageList !== undefined &&
                      this.props.previousMessageList.length > 0 ? (
                        this.props.previousMessageList.map((message, index) => {
                          return (
                            <Card key={index}>
                              <Accordion.Toggle
                                as={Card.Header}
                                variant="link"
                                eventKey={message.id}
                                md="12"
                                className={"row-hover pointer_class"}
                              >
                                <Row md="12">
                                  <Col md="4">
                                    <label>
                                      {message.senderDetails.firstName +
                                        " " +
                                        message.senderDetails.lastName}
                                    </label>
                                  </Col>
                                  <Col md="4">
                                    <label>
                                      sent to:-
                                      {message.receiverDetails.firstName +
                                        " " +
                                        message.receiverDetails.lastName}
                                    </label>
                                  </Col>
                                  <Col md="4">
                                    <label className="float-right">
                                      Sent:-
                                      <Moment format="MM/DD/YYYY hh:mm:A">
                                        {message.createdAt}
                                      </Moment>
                                    </label>
                                  </Col>
                                </Row>
                              </Accordion.Toggle>

                              <Accordion.Collapse eventKey={message.id}>
                                <Card.Body>
                                  <Row>
                                    <Col md="10">
                                      <p>{message.message}</p>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          );
                        })
                      ) : (
                        <div></div>
                      )}
                    </Accordion>
                  </Row>
                </ModalFooter>
              </Modal>
            </form>
          )}
        </Formik>
      </>
    );
  }
}
