import React, { Component } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
//import { chunk, uniqBy, filter } from "lodash";
import { uniqBy } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  questionList,
  QuestionType,
  searchQuestion,
  addQuestion,
  updateQuestions,
  addToTopic,
} from "../../services/QuestionServices";
import { topicListNoDesc } from "../../services/TopicServices";
import AddQuestion from "./AddQuestion";
import EditQuestion from "./EditQuestion";
import AssignTopic from "./AssignTopic";


class QuestionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      QuestionList: [],
      isloading: false,
      showModalAddQuestion: false,
      showModalEditQuestion: false,
      errorMessage: "",
      questionType: [],
      totalSize: 0,
      page: 1,
      sizePerPage: 10,
      initialValues: {
        questionText: "",
        answerExplanation: "",
        isActive: false,
        questionTypeId: "",
        answers: [
          { optionText: "", isCorrectOption: false },
          { optionText: "", isCorrectOption: false },
        ],
      },
      modalAssignTopic: false,
      initialValuesAssignTopic: {
        topicId: "",
        questionId: "",
      },
      topicList: [],
      searchText:''
    };
    this.getQuestionList = this.getQuestionList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    
  }
  componentDidMount() {
    this.getQuestionList();
    this.questionType();
    this.getTopicList();
  }

  getQuestionList = (page = this.state.page, sizePerPage = this.state.sizePerPage) => {
    this.setState({ isloading: true });
    questionList(page,sizePerPage)
      .then((response) => {
        if (response !== undefined) {
          // console.log("QuestionList", response.count);
          this.setState({
            QuestionList: response.rows,
            totalSize: response.count,
            page,
            sizePerPage,
            isloading: false,
          });
          //console.log("totalSize", this.state.totalSize);
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ isloading: false });
      });
  };

  handlePageChange(page, sizePerPage) {
    if (this.state.searchText === ""){
      this.getQuestionList(page, sizePerPage);
    }
    else{
      this.setState({ isloading: true });
      searchQuestion(this.state.searchText, page, sizePerPage)
      .then((response) => {
        if (response !== undefined) {
          this.setState({
            QuestionList: response.rows,
            totalSize: response.count,
            searchText: this.state.searchText,
            page,
            sizePerPage,
            isloading:false
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    }
    
  }

 // handleSizePerPageChange(sizePerPage) {
    // When changing the size per page always navigating to the first page
 //   this.getQuestionList(1, sizePerPage);
 // }

  questionType = () => {
    this.setState({ isloading: true });
    QuestionType()
      .then((response) => {
        if (response !== undefined) {
          // console.log("question Type", response);
          this.setState({
            questionType: response,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ isloading: false });
      });
  };

  // this function is used to show buffer data
  bufferFromBufferString = (data) => {
    let utf8decoder = new TextDecoder();
    let u16arr = new Uint8Array(data);
    let dataPrepare = utf8decoder.decode(u16arr);
    return dataPrepare;
  };

  showModalAddQuestion = (e) => {
    e.preventDefault();
    this.setState({
      showModalAddQuestion: true,
    });
  };
  handleModalHide = () => {
    this.setState({
      showModalAddQuestion: false,
    });
  };

  addQuestion = (data) => {
    this.setState({ isloading: true });
    addQuestion(data)
      .then((response) => {
        if (response !== undefined) {
      
          this.setState({
            isloading: false,
            showModalAddQuestion: false,
            QuestionList: [...this.state.QuestionList, response],
            errorMessage: "",
          });
          toast.success("Question added successfully.", {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            isloading: false,
          });
        } else {
          this.setState({
            errorMessage: error.message,
            isloading: false,
          });
        }
      });
  };

  updateQuestion = (data, id, actions) => {

    // return;
    this.setState({ isloading: true });
    updateQuestions(id, data)
      .then((response) => {
        if (response !== undefined) {
      
          const elementsIndex = this.state.QuestionList.findIndex(
            (question) => question.id == response.data.id
          );
          let updateQuestionList = [...this.state.QuestionList];
          updateQuestionList[elementsIndex] = response.data;
          this.setState({
            isloading: false,
            showModalEditQuestion: false,
            initialValues: {
              questionText: "",
              answerExplanation: "",
              isActive: false,
              questionTypeId: "",
              answers: [
                { optionText: "", isCorrectOption: false },
                { optionText: "", isCorrectOption: false },
              ],
            },
            QuestionList: updateQuestionList,
          });
          // this.getQuestionList();
          toast.success("Question updated successfully", {
            autoClose: 3000,
            hideProgressBar: true,
          });
          actions.resetForm();
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            isloading: false,
          });
        } else {
          this.setState({
            errorMessage: error.message,
            isloading: false,
          });
        }
      });
  };

  handleSearch = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      this.getQuestionList(this.state.page, this.state.sizePerPage);
    } 
    //this.setState({searchText:e.target.value, page: this.state.page, sizePerPage: this.state.sizePerPage})
    this.setState({searchText:e.target.value})
  };

  onClickSearch=()=>{
    this.setState({ isloading: true });
    searchQuestion(this.state.searchText, 1, 10)
    .then((response) => {
      if (response !== undefined) {
        this.setState({
          QuestionList: response.rows,
          totalSize: response.count,
          searchText: this.state.searchText,
          page: 1,
          isloading:false
        });
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
  }

  showModalUPdateQuestion = (e, row, rowIndex) => {
   
    this.setState({
      showModalEditQuestion: true,
      initialValues: row,
    });
  };

  handleEditModalHide = () => {
    this.setState({
      showModalEditQuestion: false,
    });
  };

  showModalAssignTopic = () => {
    this.setState({
      modalAssignTopic: true,
    });
  };
  hideModalAssignTopic = () => {
    this.setState({
      modalAssignTopic: false,
      errorMessage: "",
    });
  };

  getTopicList = () => {
    topicListNoDesc()
      .then((response) => {
        if (response !== undefined) {
      
          const uniqRows = uniqBy([...response.rows], "topicTitle");
         
          this.setState({
            topicList: uniqRows,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  assignTopic = (data) => {
  
    this.setState({ isloading: true });
    // return;
    addToTopic(data)
      .then((response) => {
        if (response !== undefined) {
     
          this.setState({
            isloading: false,
            errorMessage: "",
            modalAssignTopic: false,
          });
          toast.success("Question added successfully.", {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            isloading: false,
          });
        } else {
          this.setState({
            errorMessage: error.message,
            isloading: false,
          });
        }
      });
  };

  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.showModalUPdateQuestion(e, row, rowIndex);
      },
    };

    const columns = [
      {
        dataField: "id",
        text: "ID"
      },
      {
        dataField: "questionText",
        text: "Question Text",
        formatter: (cell, row) => {
          // return this.bufferFromBufferString(cell.data);
          return React.createElement("div", {
            dangerouslySetInnerHTML: { __html: this.bufferFromBufferString(cell.data) },
          });
        },
      },
      {
        dataField: "questionType.questionType",
        text: "Question Type",
      },
      {
        dataField: "answerExplanation",
        text: "Answer Explanation",
        formatter: (cell, row) => {
          return React.createElement("div", {
            dangerouslySetInnerHTML: { __html: this.bufferFromBufferString(cell.data) },
          });
        },
      },
    ];
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row>
                        <Col md="2">
                          <div className="title">Question</div>
                        </Col>
                        <Col md="10">
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="searchTopicList mt-3">
                              <div className="input-group">
                                <input
                                  type="text"
                                  name="questionSearch"
                                  className="form-control"
                                  placeholder="Search for Question here"
                                  onChange={this.handleSearch}
                                />
                                <div className="input-group-append">
                                  <button className="btn btn-primary-fill btn btn-success" onClick={this.onClickSearch}>
                                    <FontAwesomeIcon icon={faSearch} />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="addQuestion">
                              <button
                                className="btn btn-primary-fill float-right mt-3"
                                onClick={this.showModalAddQuestion}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                                &nbsp; Add Question
                              </button>
                              <button
                                className="btn btn-primary-fill float-right mt-3 mr-2"
                                onClick={this.showModalAssignTopic}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                                &nbsp; Assign Question to Topic
                              </button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      {this.state.isloading ? (
                        <div className="custom-loader">
                          <Spinner animation="border" />
                        </div>
                      ) : (this.state.QuestionList.length>0
                          ?
                          <BootstrapTable
                            remote
                            keyField="id"
                            key={`questionListTable`}
                            data={this.state.QuestionList}
                            fetchInfo = {{dataTotalSize: this.state.totalSize}}
                            columns={columns}
                            pagination={paginationFactory({
                              onPageChange : this.handlePageChange,
                              onTableChange: this.handlePageChange,
                              searchText: this.state.searchText,
                              page: this.state.page,
                              sizePerPage: this.state.sizePerPage,
                              totalSize: this.state.totalSize,
                              showTotal: true,
                              hideSizePerPage: true,
                            })}
                            rowEvents={rowEvents}
                            rowClasses="row-hover"
                          />
                          :<p className="no-data text-center col-md-12"> No Question Available</p>
                        )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {this.state.showModalAddQuestion ? (
                <AddQuestion
                  showModalAddQuestion={this.state.showModalAddQuestion}
                  handleModalHide={this.handleModalHide}
                  onSubmit={this.addQuestion}
                  errorMessage={this.state.errorMessage}
                  initialValues={this.state.initialValues}
                  questionType={this.state.questionType}
                />
              ) : null}
              {this.state.showModalEditQuestion ? (
                <EditQuestion
                  showModalEditQuestion={this.state.showModalEditQuestion}
                  handleModalHide={this.handleEditModalHide}
                  onSubmit={this.updateQuestion}
                  errorMessage={this.state.errorMessage}
                  initialValues={this.state.initialValues}
                  questionType={this.state.questionType}
                />
              ) : null}
              {this.state.modalAssignTopic ? (
                <AssignTopic
                  modalAssignTopic={this.state.modalAssignTopic}
                  handleModalHide={this.hideModalAssignTopic}
                  onSubmit={this.assignTopic}
                  errorMessage={this.state.errorMessage}
                  initialValues={this.state.initialValuesAssignTopic}
                  topicList={this.state.topicList}
                  questionList={this.state.QuestionList}
                />
              ) : null}
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}

export default QuestionList;
