import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import trimObj from "../../components/common/TrimData";
toast.configure();

export default class AddEditClient extends Component {
  closeModal = (resetForm) => {
    resetForm();
    this.props.handleModalHide();
  };
  render() {
    const ClientValidationSchema = Yup.object().shape({
      clientName: Yup.string().required("Please Enter Client Name"),
      addressLine1: Yup.string().required("Please Enter Address Line 1"),
      addressLine2: Yup.string().nullable(),
      addressLine3: Yup.string().nullable(),
      city: Yup.string().required("Please Enter City"),
      state: Yup.string().required("Please Enter State"),
      pin: Yup.string().required("Please Enter Pin"),
    });
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={this.props.initialValues}
          onSubmit={(values, actions) => {
            values.addressLine2 =
              values.addressLine2 === "" ? null : values.addressLine2;
            values.addressLine3 =
              values.addressLine3 === "" ? null : values.addressLine3;
            // this function used to trim string before and after
            values = trimObj(values);
            this.props.onSubmit(values, actions);
            //	let castedValue = ClientValidationSchema.cast(values)

            //this.addClient(values);
            //resetForm(values.clientName)
          }}
          validationSchema={ClientValidationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal isOpen={this.props.showModalClient} size="md">
                <ModalHeader>
                  <span>{this.props.titleAddEdit} Client</span>
                  <div className="modal_popup_close_icon">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => this.closeModal(resetForm)}
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  {this.props.errorMessage !== "" ? (
                    <p className="alert alert-danger">
                      {this.props.errorMessage}
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="4">
                        <label> Client Name</label>
                      </Col>
                      <Col md="8">
                        <input
                          type="text"
                          name="clientName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.clientName}
                          className="form-control"
                          placeholder="Client Name"
                        />
                        <label className="err-msg">
                          {errors.clientName &&
                            touched.clientName &&
                            errors.clientName}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="4">
                        <label> Address Line 1</label>
                      </Col>
                      <Col md="8">
                        <input
                          type="text"
                          name="addressLine1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.addressLine1}
                          className="form-control"
                          placeholder="Address Line 1"
                        />
                        <label className="err-msg">
                          {errors.addressLine1 &&
                            touched.addressLine1 &&
                            errors.addressLine1}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="4">
                        <label> Address Line 2</label>
                      </Col>
                      <Col md="8">
                        <input
                          type="text"
                          name="addressLine2"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.addressLine2}
                          className="form-control"
                          placeholder="Address Line 2"
                        />
                        <label className="err-msg">
                          {errors.addressLine2 &&
                            touched.addressLine2 &&
                            errors.addressLine2}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="4">
                        <label> Address Line 3</label>
                      </Col>
                      <Col md="8">
                        <input
                          type="text"
                          name="addressLine3"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.addressLine3}
                          className="form-control"
                          placeholder="Address Line 3"
                        />
                        <label className="err-msg">
                          {errors.addressLine3 &&
                            touched.addressLine3 &&
                            errors.addressLine3}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="4">
                        <label> City</label>
                      </Col>
                      <Col md="8">
                        <input
                          type="text"
                          name="city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                          className="form-control"
                          placeholder="City"
                        />
                        <label className="err-msg">
                          {errors.city && touched.city && errors.city}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="4">
                        <label> State</label>
                      </Col>
                      <Col md="8">
                        <input
                          type="text"
                          name="state"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.state}
                          className="form-control"
                          placeholder="State"
                        />
                        <label className="err-msg">
                          {errors.state && touched.state && errors.state}
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="4">
                        <label> Pin</label>
                      </Col>
                      <Col md="8">
                        <input
                          type="text"
                          name="pin"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.pin}
                          className="form-control"
                          placeholder="Pin"
                        />
                        <label className="err-msg">
                          {errors.pin && touched.pin && errors.pin}
                        </label>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => this.closeModal(resetForm)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="btn btn-primary-fill"
                    onClick={handleSubmit}
                  >
                    {this.props.titleAddEdit}
                  </Button>
                </ModalFooter>
              </Modal>
            </form>
          )}
        </Formik>
      </>
    );
  }
}
