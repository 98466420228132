import Api from '../Api'
 export const getAllSchoolSectionList=() => {
    return  Api.get('/schoolsection/getschoolsection').then(res=>res.data)
 }
 
 export const getBoardGradeSubject=(id) => {
   return  Api.get('/meta/boardgradesubject/getboardgradesubject/getbyboard/'+id).then(res=>res.data)
}
export const addSchoolSection=(schoolData) => {
   return  Api.post('schoolsection/addschoolsection',schoolData).then(res=>res.data)
}
export const getSchoolSectionList=(id) => {
   return  Api.get('/schoolsection/getschoolsection/getbyschool/'+id+'?page=1&size=1000').then(res=>res.data)
}

export const updateSchoolSection = (sectionId, sectionDetails) => {
   return Api.put('schoolsection/updateschoolsection/'+sectionId, sectionDetails);
}