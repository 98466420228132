import React, { Component } from "react";
import { Row, Col, Spinner } from "reactstrap";
import {
  getUserSchoolSections,
  getMessageListBySubject,
} from "../services/UserServices";
import {
  getMessagesForUser,
  readMessagesByUser,
  sendMessageToStudent,
  getUsersBySection,
} from "../services/TeacherServices";
import { faPlus, faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, Card } from "react-bootstrap";
import Moment from "react-moment";
import SendMessage from "./SendMessage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
export default class TeacherMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardList: [],
      errorMessage: "",
      isloading: false,
      userSectionsList: [],
      messageList: [],
      selectedSection: "",
      showModalMessage: false,
      messageRead: false,
      initialValues: {
        senderId: "",
        messageSubject: "",
        message: "",
        receiverId: "",
        schoolSectionId: "",
      },
      errorMessage: "",
      isDisable: false,
      allStudentsList: [],
      studentWithSectionList: [],
      previousMessageList: [],
    };
  }
  componentDidMount() {
    this.getUserSectionList();
  }
  getUserSectionList = () => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getUserSchoolSections(userData.id)
      .then((responseUserSections) => {
        if (
          responseUserSections !== undefined &&
          responseUserSections.length > 0 &&
          responseUserSections[0]["userRoles"] !== undefined &&
          responseUserSections[0]["userRoles"][0]["userRolesSchoolSections"] !==
            undefined
        ) {
          var userSectionList = [];
          responseUserSections[0]["userRoles"][0][
            "userRolesSchoolSections"
          ].map((userSection) => {
            let data = {};
            data["label"] =
              userSection.schoolSection.boardGradeSubject.grade.grade +
              " Grade " +
              userSection.schoolSection.boardGradeSubject.subject.subject +
              " " +
              userSection.schoolSection.sectionName;
            data["value"] = userSection.schoolSectionId;
            userSectionList.push(data);
          });
          this.setState({ userSectionsList: userSectionList });
          if (userSectionList.length > 0) {
            let initialValues = { ...this.state.initialValues };
            initialValues.schoolSectionId = userSectionList[0].value;
            this.setState({
              selectedSection: userSectionList[0].value,
              initialValues: initialValues,
            });
            return getMessagesForUser(userData.id, userSectionList[0].value);
          }
        } else {
          this.setState({ isloading: false, userSectionsList: [] });
        }
      })
      .then((responseMessageList) => {
        if (responseMessageList !== undefined) {
          this.setState({
            messageList: responseMessageList.rows,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error in message list", error);
        if (error !== undefined) {
          this.setState({ isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  getMessageBySection = (sectionId) => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getMessagesForUser(userData.id, sectionId)
      .then((responseMessageList) => {
        if (responseMessageList !== undefined) {
          this.setState({
            messageList: responseMessageList.rows,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error in client list", error);
        if (error !== undefined) {
          this.setState({ errorMessage: error.message, isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  handleChangeSection = (event) => {
    this.setState({ selectedSection: event.target.value });
    this.getMessageBySection(event.target.value);
  };
  readMessage = (message) => {
    if (!message.viewedByReceiver) {
      readMessagesByUser(message.id)
        .then((responseReadMessage) => {
          if (responseReadMessage !== undefined) {
            this.setState({ messageRead: true });
          }
        })
        .catch((error) => {
          console.log("error in read message");
        });
    }
  };
  onClickReply = (message) => {
    let initialValues = { ...this.state.initialValues };
    initialValues.senderId = message.receiverId;
    initialValues.receiverId = message.senderDetails.id;
    initialValues.messageSubject = message.messageSubject;
    initialValues.schoolSectionId = message.schoolSectionId;
    let studentOptions = [
      {
        label:
          message.senderDetails.firstName +
          " " +
          message.senderDetails.lastName,
        value: message.senderDetails.id,
      },
    ];
    getMessageListBySubject(
      message.messageSubject,
      message.receiverId,
      message.schoolSectionId
    ).then((responseMessage) => {
      this.setState({ previousMessageList: responseMessage.rows });
    });
    this.setState({
      initialValues: initialValues,
      showModalMessage: true,
      isDisable: true,
      allStudentsList: studentOptions,
    });
  };
  handleModalHide = () => {
    this.setState({
      initialValues: {
        senderId: "",
        messageSubject: "",
        message: "",
        receiverId: "",
      },
      showModalMessage: false,
      isDisable: false,
    });
  };
  handleModalShow = () => {
    let initialValues = { ...this.state.initialValues };
    let userData = JSON.parse(localStorage.getItem("user"));
    initialValues.senderId = userData.id;
    initialValues.schoolSectionId = this.state.selectedSection;
    getUsersBySection(this.state.selectedSection).then((response) => {
      let allStudentsOptions = [];
      response.rows
        .filter((user) => user.userRoles.some((role) => role.roleId == 4))
        .map((student) => {
          allStudentsOptions.push({
            label: student.firstName + " " + student.lastName,
            value: student.id,
          });
        });

      this.setState({ allStudentsList: allStudentsOptions, isloading: false });
    });

    this.setState({
      initialValues: initialValues,
      showModalMessage: true,
      isDisable: false,
      previousMessageList: [],
    });
  };
  sendMessage = (messageData, actions) => {
    this.setState({ isloading: true });
    sendMessageToStudent(messageData)
      .then((responseMessage) => {
        if (responseMessage !== undefined) {
          console.log("responseMessage", responseMessage);
          this.setState({ isloading: false, showModalMessage: false });
          toast.success(responseMessage.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });

          actions.resetForm();
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };
  render() {
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row>
                        <Col md="6">
                          <div className="title">
                            <h4>Messages</h4>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <Card.Body>
                      <div>
                        {this.state.isloading ? (
                          <div className="custom-loader">
                            <Spinner animation="border" />
                          </div>
                        ) : (
                          <div>
                            <div className="form-group form-gp">
                              <Row>
                                <Col md="3">
                                  <label> Select Section</label>
                                </Col>
                                <Col md="6">
                                  <select
                                    className="form-control"
                                    onChange={this.handleChangeSection}
                                    value={this.state.selectedSection}
                                  >
                                    {this.state.userSectionsList !==
                                      undefined &&
                                      this.state.userSectionsList.map(
                                        (option) => (
                                          <option
                                            value={option.value}
                                            key={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        )
                                      )}
                                  </select>
                                </Col>
                                <Col md="3">
                                  <button
                                    className="btn btn-primary-fill float-right"
                                    onClick={this.handleModalShow}
                                  >
                                    <FontAwesomeIcon icon={faPlus} /> &nbsp;Send
                                    Message{" "}
                                  </button>
                                </Col>
                              </Row>
                            </div>
                            <Accordion>
                              {this.state.messageList !== undefined &&
                              this.state.messageList.length > 0 ? (
                                this.state.messageList.map((message, index) => {
                                  return (
                                    <Card key={index}>
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        variant="link"
                                        eventKey={message.id}
                                        md="12"
                                        className={
                                          message.viewedByReceiver ||
                                          this.state.messageRead
                                            ? "row-hover pointer_class"
                                            : "row-hover pointer_class message-bold"
                                        }
                                        onClick={() =>
                                          this.readMessage(message)
                                        }
                                      >
                                        <Row md="12">
                                          <Col md="4">
                                            <label>
                                              {message.senderDetails.firstName +
                                                " " +
                                                message.senderDetails.lastName}
                                            </label>
                                          </Col>
                                          <Col md="4">
                                            <label>
                                              {message.messageSubject}
                                            </label>
                                          </Col>
                                          <Col md="4">
                                            <label className="float-right">
                                              Sent:-
                                              <Moment format="MM/DD/YYYY hh:mm:A">
                                                {message.createdAt}
                                              </Moment>
                                            </label>
                                          </Col>
                                        </Row>
                                      </Accordion.Toggle>

                                      <Accordion.Collapse eventKey={message.id}>
                                        <Card.Body>
                                          <Row>
                                            <Col md="10">
                                              <p>{message.message}</p>
                                            </Col>
                                            <Col md="2">
                                              <label className="float-right">
                                                <FontAwesomeIcon
                                                  icon={faReply}
                                                  onClick={() =>
                                                    this.onClickReply(message)
                                                  }
                                                />
                                              </label>
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  );
                                })
                              ) : (
                                <div className="text-center">
                                  <p> No message available for this section</p>
                                </div>
                              )}
                            </Accordion>
                          </div>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
            <SendMessage
              showModalMessage={this.state.showModalMessage}
              initialValues={this.state.initialValues}
              handleModalHide={this.handleModalHide}
              errorMessage={this.state.errorMessage}
              allStudentsList={this.state.allStudentsList}
              isDisable={this.state.isDisable}
              onSubmit={this.sendMessage}
              previousMessageList={this.state.previousMessageList}
              readMessage={this.readMessage}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
