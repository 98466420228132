import Api from '../Api'

export const getClientList=(clientPage=1,size=1000) => {
    return  Api.get('client/getclient?page='+clientPage+'&size='+size).then(res=>res.data)
 }
export const addClient = (ClientData) => {
    return Api.post('client/addclient', ClientData);
}

export const updateClient = (clientId, ClientDetails) => {
    return Api.put('client/updateclient/'+clientId, ClientDetails);
}
