import React, { Component } from "react";
import { Row, Col, Card, Spinner, CardBody } from "reactstrap";
import { getUserSchoolSections } from "../services/UserServices";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  getCurriuculumBySection,
  getEvaluationQuestionById,
  addDateAssessment,
} from "../services/TeacherServices";
import { Link } from "react-router-dom";
import AssignmentDetails from "./AssignmentDetails";
import Moment from "react-moment";
import ScheduleAssessment from "./ScheduleAssessment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default class TeacherCurriculum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curriculumList: [],
      errorMessage: "",
      isloading: false,
      selectedSection: "",
      userSectionList: [],
      showModalAssignment: false,
      questionList: [],
      showScheduleModal: false,
      startDate: null,
      endDate: null,
    };
  }
  componentDidMount() {
    this.getUserSectionList();
  }
  getUserSectionList = () => {
    this.setState({ isloading: true });
    let userData = JSON.parse(localStorage.getItem("user"));
    getUserSchoolSections(userData.id)
      .then((responseUserSections) => {
        if (
          responseUserSections !== undefined &&
          responseUserSections.length > 0 &&
          responseUserSections[0]["userRoles"] !== undefined &&
          responseUserSections[0]["userRoles"][0]["userRolesSchoolSections"] !==
            undefined
        ) {
          var userSectionList = [];
          responseUserSections[0]["userRoles"][0][
            "userRolesSchoolSections"
          ].map((userSection) => {
            let data = {};
            data["label"] =
              userSection.schoolSection.boardGradeSubject.grade.grade +
              " Grade " +
              userSection.schoolSection.boardGradeSubject.subject.subject +
              " " +
              userSection.schoolSection.sectionName;
            data["value"] = userSection.schoolSectionId;
            userSectionList.push(data);
          });
          this.setState({ userSectionsList: userSectionList });
          if (userSectionList.length > 0) {
            this.setState({ selectedSection: userSectionList[0].value });
            return getCurriuculumBySection(userSectionList[0].value);
          }
        } else {
          this.setState({ isloading: false, userSectionsList: [] });
        }
      })
      .then((responseCurriculum) => {
        if (responseCurriculum !== undefined) {
          this.setState({
            curriculumList: responseCurriculum,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error in section announcement list", error);
        if (error !== undefined) {
          this.setState({ isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  handleChangeSection = (event) => {
    this.setState({ selectedSection: event.target.value });
    this.getCurriuculumBySection(event.target.value);
  };
  getCurriuculumBySection = (sectionId) => {
    this.setState({ isloading: true });
    getCurriuculumBySection(sectionId)
      .then((responseCurriculum) => {
        if (responseCurriculum !== undefined && responseCurriculum.length > 0) {
          this.setState({
            curriculumList: responseCurriculum,
            isloading: false,
          });
        } else {
          this.setState({ isloading: false, curriculumList: [] });
        }
      })
      .catch((error) => {
        if (error !== undefined) {
          this.setState({ errorMessage: error.message, isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  navigateChapterDetails = (cell) => {
    let toDetails = {
      pathname: "/app/curriculum/chapter-details",
      state: { chapterDetails: cell },
    };
    this.props.history.push(toDetails);
  };
  chapterFormatter = (cell, rows) => {
    return (
      <Link
        to={{
          pathname: "/app/curriculum/chapter-details",
          chapterDetails: {
            sectionChapterId: rows.id,
            sectionId: this.state.selectedSection,
            chapterId: rows.chapterId,
          },
        }}
        className="view-title"
      >
        {cell}
      </Link>
    );
  };
  topicFormatter = (cell, rows) => {
    return cell.map((topic) => {
      return (
        <div className="column-assignment" key={topic.id}>
          <span>
            <Link
              to={{
                pathname: "/app/curriculum/topic-details",
                topicDetails: {
                  schoolSectionTopicId: topic.id,
                  sectionId: this.state.selectedSection,
                  chapterId: rows.id,
                },
              }}
              className="view-title"
            >
              {topic.topicDisplayTitle}{" "}
            </Link>
          </span>
        </div>
      );
    });
  };
  assignmentFormatter = (cell, rows) => {
    return cell.map((topic,topicIndex) => {
      return (
        <div className="column-assignment" key={topicIndex}>
          {topic.schoolSectionEvaluations.map((evaluation,index) => {
            return (
              <div key={evaluation.id}>
                <span
                  onClick={() =>
                    this.showModalAssignment(topic, evaluation, rows)
                  }
                  className="view-title pointer_class"
                >
                  {evaluation.evaluationType.evaluationType}{" "}
                </span>
              </div>
            );
          })}
        </div>
      );
    });
  };
  //date formatter to show date in specific format
  startDateFormatter = (cell, rows) => {
    return cell.map((topic,topicIndex) => {
      return (
        <div className="column-assignment" key={topicIndex}>
          {topic.schoolSectionEvaluations.map((evaluation,index) => {
           
            return (
              <div   key={evaluation.id}>
                {evaluation.startDate === null ? (
                  <span
                    onClick={() =>
                      this.showScheduleModal(topic, evaluation, rows)
                    }
                    className="view-title pointer_class"
                  >
                    Add to Calender
                  </span>
                ) : (
                  <span
                    onClick={() =>
                      this.showScheduleModal(topic, evaluation, rows)
                    }
                    className="view-title pointer_class"
                  >
                    <Moment format="MM/DD/YYYY hh:mm:A">
                      {evaluation.startDate}
                    </Moment>
                  </span>
                )}
              </div>
            );
          })}
        </div>
      );
    });
  };
  //date formatter to show date in specific format
  endDateFormatter = (cell, rows) => {
    return cell.map((topic,topicIndex) => {
      return (
        <div className="column-assignment" key={topicIndex}>
          {topic.schoolSectionEvaluations.map((evaluation,index) => {
             
            return (
              <div  key={evaluation.id}>
                {evaluation.endDate === null ? (
                  <span   className="view-title pointer_class">&nbsp;</span>
                ) : (
                
                  <span
                    onClick={() =>
                      this.showScheduleModal(topic, evaluation, rows)
                    }
                    className="view-title pointer_class"
                  >
                     
                    <Moment format="MM/DD/YYYY hh:mm:A">
                      {evaluation.endDate}
                    </Moment>
                  </span>
                )}
              </div>
            );
          })}
        </div>
      );
    });
  };
  // this function is used for evaluation modal question list
  getEvaluationQuestion = (evaluationId) => {
    this.setState({ isloading: true });
    getEvaluationQuestionById(evaluationId)
      .then((responseEvaluation) => {
        if (responseEvaluation !== undefined) {
          this.setState({
            isloading: false,
            questionList: responseEvaluation,
            showModalAssessment: true,
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log("error in evalution Details", error);
      });
  };
  //this function is used to show modal of assignment question details
  showModalAssignment = (topic, evaluation, rowData) => {
    let section = this.state.userSectionsList.find(
      (section) => section.value == this.state.selectedSection
    );
    let tittleModal =
      section["label"] +
      "-" +
      rowData["chapterTitle"] +
      "-" +
      topic.topicDisplayTitle +
      " " +
      evaluation.evaluationType.evaluationType;
    this.getEvaluationQuestion(evaluation.id);
    this.setState({ showModalAssignment: true, tittleModal: tittleModal });
  };
  hideAssignmentModal = () => {
    this.setState({ showModalAssignment: !this.state.showModalAssessment });
  };
  // this function is used to show add date option for assessment
  showScheduleModal = (topic, evaluation, rowData) => {
    let section = this.state.userSectionsList.find(
      (section) => section.value == this.state.selectedSection
    );
    let tittleModal =
      section["label"] +
      "-" +
      rowData["chapterTitle"] +
      "-" +
      topic.topicDisplayTitle +
      " " +
      evaluation.evaluationType.evaluationType;
    this.setState({
      showScheduleModal: !this.state.showScheduleModal,
      tittleModal: tittleModal,
      schoolSectionEvaluationId: evaluation.id,
      startDate:
        evaluation.startDate !== null
          ? new Date(evaluation.startDate)
          : evaluation.startDate,
      endDate:
        evaluation.endDate !== null
          ? new Date(evaluation.endDate)
          : evaluation.endDate,
    });
  };
  //this function is called on save date for assessments
  addDate = (assessmentData) => {
    this.setState({ isloading: true });
    addDateAssessment(assessmentData)
      .then((responseData) => {
        if (responseData !== undefined) {
          this.setState({ isloading: false, showScheduleModal: false });
          toast.success("Added assessment date successfully", {
            autoClose: 3000,
            hideProgressBar: true,
          });
          this.getCurriuculumBySection(this.state.selectedSection);
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({ errorMessage: error.data.message, isloading: false });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      });
  };
  hideScheduleModal = () => {
    this.setState({ showScheduleModal: !this.state.showScheduleModal });
  };
  render() {
    const columns = [
      {
        dataField: "chapterTitle",
        text: "Chapter Name",
        sort: true,
        formatter: this.chapterFormatter,
      },
      {
        dataField: "schoolSectionTopics",
        text: "Topic Name",
        formatter: this.topicFormatter,
      },
      {
        dataField: "schoolSectionTopics",
        text: "Assignment",
        formatter: this.assignmentFormatter,
      },
      {
        dataField: "schoolSectionTopics",
        text: "Start Date",
        formatter: this.startDateFormatter,
        headerClasses: "start-date-column",
      },
      {
        dataField: "schoolSectionTopics",
        text: "End Date",
        formatter: this.endDateFormatter,
        headerClasses: "start-date-column",
      },
    ];
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row>
                        <Col md="6">
                          <div className="title">
                            <h4>Curriculum</h4>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col md="3">
                          <label> Select Section</label>
                        </Col>
                        <Col md="6">
                          <select
                            className="form-control"
                            onChange={this.handleChangeSection}
                            value={this.state.selectedSection}
                          >
                            {this.state.userSectionsList !== undefined &&
                              this.state.userSectionsList.map((option) => (
                                <option
                                  value={option.value}
                                  key={option.value}
                                
                                >
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      <div className="curriculum-table">
                        {this.state.isloading ? (
                          <div className="custom-loader">
                            <Spinner animation="border" />
                          </div>
                        ) : (
                          <div>
                            <BootstrapTable
                              keyField="id"
                              key={`curriculumListTable`}
                              data={this.state.curriculumList}
                              columns={columns}
                              pagination={paginationFactory({
                                sizePerPage: 10,
                                showTotal: true,
                                hideSizePerPage: true,
                              })}
                              rowClasses="row-hover "
                              classes="custom-table-border"
                            />
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
            <AssignmentDetails
              showModalAssessment={this.state.showModalAssignment}
              hideAssessmentModal={this.hideAssignmentModal}
              tittleModal={this.state.tittleModal}
              questionList={this.state.questionList}
            />
            {this.state.showScheduleModal && (
              <ScheduleAssessment
                showScheduleModal={this.state.showScheduleModal}
                hideScheduleModal={this.hideScheduleModal}
                tittleModal={this.state.tittleModal}
                errorMessage={this.state.errorMessage}
                addDate={this.addDate}
                schoolSectionEvaluationId={this.state.schoolSectionEvaluationId}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
