import React, { Component } from "react";
import { Row, Col, Card, Spinner, CardBody } from "reactstrap";
import {
  getUserSchoolSections,
  getUserListBySections,
  updateUser,
} from "../services/UserServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import trimObj from "../components/common/TrimData";
toast.configure();
export default class ResetPassoword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSectionsList: [],
      errorMessage: "",
      isloading: false,
      sectionUsers: [],
      StudentOptions: [],
      selectedSection: "",
      selectedStudent: "",
      password: "",
    };
  }
  componentDidMount() {
    this.getUserSectionList();
  }

  getUserSectionList = () => {
    // this.setState({isloading:true})
    let userData = JSON.parse(localStorage.getItem("user"));
    getUserSchoolSections(userData.id)
      .then((responseUserSections) => {
        if (
          responseUserSections !== undefined &&
          responseUserSections.length > 0 &&
          responseUserSections[0]["userRoles"] !== undefined &&
          responseUserSections[0]["userRoles"][0]["userRolesSchoolSections"] !==
            undefined
        ) {
          let userSectionList = [{ label: "Select Section", value: "" }];
          responseUserSections[0]["userRoles"][0][
            "userRolesSchoolSections"
          ].map((userSection) => {
            let data = {};
            data["label"] =
              userSection.schoolSection.boardGradeSubject.grade.grade +
              " grade " +
              userSection.schoolSection.boardGradeSubject.subject.subject +
              " " +
              userSection.schoolSection.sectionName;
            data["value"] = userSection.schoolSectionId;
            userSectionList.push(data);
          });
          this.setState({ userSectionsList: userSectionList });
        } else {
          this.setState({ isloading: false, userSectionsList: [] });
        }
      })
      .catch((error) => {
        console.log("error in section reset password list", error);
        if (error !== undefined) {
          this.setState({ isloading: false });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        }
      });
  };
  handleChangeSection = (event) => {
    this.setState({ isloading: true, selectedSection: event.target.value });
    getUserListBySections(event.target.value)
      .then((responseUserList) => {
        if (responseUserList !== undefined) {
          let studentOptions = [{ label: "Select Student", value: "" }];
          responseUserList.rows
            .filter((user) => {
              console.log(
                "user",
                user.userRoles.some((userRole) => userRole.roleId === 4)
              );
              return user.userRoles.some((userRole) => userRole.roleId == 4);
            })
            .map((Studentuser) => {
              studentOptions.push({
                label: Studentuser.firstName + " " + Studentuser.lastName,
                value: Studentuser.id,
              });
            });
          this.setState({
            isloading: false,
            studentOptions: studentOptions,
            selectedStudent: "",
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
      });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  updateUserDetails = (userData, actions) => {
    if (this.state.password.length < 6) {
      this.setState({
        errorMessage: "Password length should be greater than 6 character",
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 3000);
    } else {
      let data = { password: this.state.password };

      this.setState({ isloading: true });
	   // this function used to trim string before and after
	   data = trimObj(data);
      updateUser(this.state.selectedStudent, data)
        .then((responseUserData) => {
          if (responseUserData !== undefined) {
            this.setState({ isloading: false });
            toast.success("Student password reset successfully", {
              autoClose: 3000,
              hideProgressBar: true,
            });
            //window.location.reload();
          }
        })
        .catch((error) => {
          console.log("error", error);
          if (error.data !== undefined) {
            this.setState({
              errorMessage: error.data.message,
              isloading: false,
            });
          }
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 3000);
        });
    }
  };
  render() {
    return (
      <div className="container-fluid">
        <Row className="row">
          <Col md="12">
            <React.Fragment>
              <Row>
                <Col lg="12" md="12">
                  <Card className="card-box">
                    <div className="card-header">
                      <Row>
                        <Col md="6">
                          <div className="title">
                            <h4>Reset Student Password</h4>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody>
                      <div>
                        {this.state.isloading ? (
                          <div className="custom-loader">
                            <Spinner animation="border" />
                          </div>
                        ) : (
                          <div>
                            <div className="form-group form-gp">
                              <Row>
                                <Col md="6" className="offset-md-3">
                                  {this.state.errorMessage !== "" ? (
                                    <p className="alert alert-danger">
                                      {this.state.errorMessage}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col md="3">
                                  <label> Select Section</label>
                                </Col>
                                <Col md="6">
                                  <select
                                    className="form-control"
                                    onChange={this.handleChangeSection}
                                    value={this.state.selectedSection}
                                  >
                                    {this.state.userSectionsList !==
                                      undefined &&
                                      this.state.userSectionsList.map(
                                        (option) => (
                                          <option
                                            value={option.value}
                                            key={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        )
                                      )}
                                  </select>
                                </Col>
                              </Row>
                            </div>
                            <div className="form-group form-gp">
                              <Row>
                                <Col md="3">
                                  <label> Select Student</label>
                                </Col>
                                <Col md="6">
                                  <select
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.selectedStudent}
                                    name="selectedStudent"
                                  >
                                    {this.state.studentOptions !== undefined &&
                                      this.state.studentOptions.map(
                                        (option) => (
                                          <option
                                            value={option.value}
                                            key={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        )
                                      )}
                                  </select>
                                </Col>
                              </Row>
                            </div>
                            <div className="form-group form-gp">
                              <Row>
                                <Col md="3">
                                  <label> Password</label>
                                </Col>
                                <Col md="6">
                                  <input
                                    autoComplete="off"
                                    type="password"
                                    name="password"
                                    onChange={this.handleChange}
                                    // onBlur={handleBlur}
                                    value={this.state.password}
                                    className="form-control"
                                    placeholder="password"
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="form-group form-gp">
                              <Row>
                                <Col md="3" className="offset-md-3">
                                  <button
                                    type="submit"
                                    className="btn btn-primary-fill"
                                    onClick={this.updateUserDetails}
                                    disabled={
                                      this.state.selectedSection === "" ||
                                      this.state.selectedStudent === ""
                                        ? true
                                        : false
                                    }
                                  >
                                    Reset
                                  </button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Col>
        </Row>
      </div>
    );
  }
}
